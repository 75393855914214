import '../api/api_automation.scss';
import "/gh_elements/trigger/gh_trigger_switch.scss";
angular.module('triggerElementsTableModule', [])

  .directive('triggerElementsTable', ['GhDialog', 'cnfg', function (GhDialog, cnfg) {
    return {
      restrict: 'E',
      scope: {
        triggerList: '='
      },

      controller: ['$scope', '$element', async function ($scope, $element) {

        $scope.triggerListWithApp = [];
        for(let i = 0; i < $scope.triggerList.length; i++) {
          const app = await gudhub.getAppInfo($scope.triggerList[i].app_id);
          $scope.triggerListWithApp.push($scope.triggerList[i]);
          $scope.triggerListWithApp[i].app = app;
          $scope.$digest();
        }

        $scope.updateTrigger = async (trigger) => {
          try {
          const triggerClone = JSON.parse(angular.toJson( trigger ));//-- here we removing $$hashKey properties from json;
          delete triggerClone.app;
            await fetch( cnfg.node_services_api_path + '/trigger/add-update', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json;charset=utf-8'
              },
              body: JSON.stringify(triggerClone)
            });

          } catch(e) {
            console.log(e);
          }

        };

        $scope.openDeleteDialogPopup = function (trigger) {
          GhDialog.show({
            position: 'center',
            toolbar: false,
            template: {
              toolbar: '',
              resizable: false,
              content: `
              <div class="delete_group-popup">
                <div class="dialog-share-group">
                  <span gh-icon="rubbish FF0D0D 65px"></span>
                  <p class="dialog-share-group__title">Deleting Trigger!</p>
                </div>
                <div class="dialog-share-group__content">
                  <p class="info">Do you really want to delete trigger? You will not be able to restore it.</p>
                  <div class="dialog-share-group__content__buttons">
                    <button class="dialog-share-group__content__buttons__add btn btn-blue-reverse" ng-click="deleteTrigger()">Delete</button>
                    <button class="dialog-share-group__content__buttons__cancel btn btn-blue" ng-click="cancel()">Cancel</button>
                  </div>
                </div>
              </div>`,
            },
            locals: {
              trigger,
              triggers: $scope.triggerListWithApp,
              triggerScope: $scope
            },
              controller: ['$scope', 'triggers', 'triggerScope', function ($scope, triggers, triggerScope) {

                $scope.deleteTrigger = async function() {
                  try {
                    await fetch(`${cnfg.node_services_api_path}/trigger/delete`, {
                      method: 'POST',
                      headers: {
                        'Content-Type': 'application/json',
                      },
                      body: JSON.stringify({app_id: trigger.app_id, item_id: trigger.item_id, field_id: trigger.field_id })
                    });
                    triggers.splice(triggers.indexOf(trigger), 1)
                    triggerScope.$apply();
                    $scope.cancel();
                  } catch(error) {
                    console.log(error);
                  }
                }
              }]
              
          })
        };
        
        $scope.showAutomationDialog = (trigger) => {

          GhDialog.show({
            position: 'bottom',
            toolbar: true,
            template: {
              toolbar: `
                <div ng-click="apply()" class="gh-automation-apply-button action gh-btn inline btn-blue gh-btn-bg"><p>Apply</p></div>
                <gh-input class="gh-automation-target-app-dropdown" ng-model="trigger.target_app_id" gh-dropdown autocomplete="appsList" />
                <div class="trigger-name-input">
                  <gh-input ng-model="trigger.name" placeholder="Type value" />
                </div>
                <gh-automation-mock debug-info="debugInfo" options="options" trigger="trigger" init="init">,
              `,
              resizable: false,
              content: `
                <div class="gh-element-automation">
                
                  <gh-automation debug-info="debugInfo" gh-model="trigger.model" nodes-model="settings" trigger="trigger" init="init"/>
                
                </div>
              `,
            },

            locals: {
              trigger,
              $parentScope: $scope
            },

            controller: ['$scope', 'trigger', '$parentScope', function ($scope, trigger, $parentScope) {

              $scope.trigger = trigger;

              $scope.debugInfo = {
                debuggers: {
  
                },
                currentNodes: []
              }

              $scope.options = [
                {
                  name: "Created Items",
                  value: "/items/add",
                },
                {
                  name: 'Deleted Items',
                  value: "/items/delete"
                },
                {
                  name: "Updated Items",
                  value: '/items/update',
                },
                {
                  name: "Updated File",
                  value: "/file/update",
                },
                {
                  name: "Updated Value",
                  value: "/values/update",
                },
              ];
              $scope.settings = {
                  include_nodes: gudhub.storage.getModulesList('automation', false, 'Trigger'),
              };

              $scope.apply = async function() {
                await $parentScope.updateTrigger($scope.trigger);
                $scope.hide();
              }

            }]
          })
        };
      }],

      template: /*html*/
      
      `
      <div class="automation_table api_table">
      <div class="listing_table">
        <div class="listing_table_head_wrapper">
          <div class="listing_table_column bottom_line w-35">
              <div class="tab">Name</div>
          </div>
          <div class="listing_table_column bottom_line w-35">
            <div class="tab">Application</div>
          </div>
          <div class="listing_table_column w-15">
            <div class="tab">Status</div>
          </div>
          <div class="listing_table_column w-15">
          </div>
        </div>
        <div class="listing_table_body_wrapper">
        
          <div class="listing_table_row" ng-repeat="trigger in triggerListWithApp">
          <div class="wrapper">
            <div class="listing_table_column w-35 app_name">
              <div class="name">{{trigger.name}}</div>
            </div>
            <div class="listing_table_column w-35 app_name">
              <span class="app_icon" gh-icon="{{trigger.app.icon.icon_id}} {{trigger.app.icon.icon_color}} 40px normal {{trigger.app.icon.gradient_up}} {{trigger.app.icon.gradient_down}}"></span>
              <div class="name">{{trigger.app.app_name}}</div>
            </div>
            <div class="listing_table_column w-15">
              <div class="status">
                <gh-boolean class="gh-api-btn-switch" ng-click="updateTrigger(trigger)" gh-model="trigger.active" gh-mode="switch" editable="true"></gh-boolean>
              </div>
            </div>
            <div class="listing_table_column w-15">
                <span ng-click="showAutomationDialog(trigger)" class="edit_icon" gh-icon="pencil 0d99ff 20px"></span>
            </div>
            <div class="listing_table_column w-5">
              <span class="delete" gh-icon="rubbish 0d99ff 20px" ng-click="openDeleteDialogPopup(trigger)">Delete</span>
            </div>
        </div>
        
      </div>
      </div>`
      
    }
  }]);
