angular.module("ghNotFoundModule", [])

// ghNotFound used to create new item if it not exist, by default it returns text "Not found"


.directive("ghNotFound", ['$compile', 'itemsDataProcessing', 'PipeService', function ($compile, itemsDataProcessing, PipeService){
  return {
    scope: {
      typeList: "=?",
      ghField: "=",
      searchText: "=",
      proxyModel: "=",
      setNgModel: "=",
      addOption: "="
    }
   ,
   link: function (scope, element, attrs){
     scope.show = false;

     function getValue(address){
         return new Promise(resolve => {
             PipeService.on('gh_value_get', address, function valuePipe(event, field_value) {
                 resolve(field_value)
                 PipeService.destroy('gh_value_get', address, valuePipe);
                 resolve()
             }, scope).emit('gh_value_get', {}, address);
         })
     }

     scope.showHandler = function (){
      const refs = scope.ghField.data_model.refs || [];
      if(refs.length === 1){
        scope.addItem(refs[0])
      } else scope.show = true
    };
     scope.addItem = async function (ref){

      scope.show = false;
      const itemsArray = [
        {
          item_id: 0,
          fields: [
            {
              field_id: ref.field_id,
              field_value: scope.searchText
            }
          ]
        }
      ];
      if(ref.settings && ref.settings.reference_field_id){
          itemsArray[0].fields.push({
              field_id:ref.settings.reference_field_id,
              field_value: `${scope.ghField.appId}.${scope.ghField.itemId}`
          })
      }
      if(ref.settings && ref.settings.refs){
          const address = {
              app_id: scope.ghField.appId,
              item_id: scope.ghField.itemId
          }
          for(const fieldRef of ref.settings.refs){
              const field = {
                  field_id: fieldRef.field_id,
              }
              if(fieldRef.show_input){
                  field.field_value = fieldRef.user_field_value;
              }else {
                  address.field_id = fieldRef.source_field_id
                  field.field_value = await getValue(address)
              }
              itemsArray[0].fields.push(field)
          }
      }

      itemsDataProcessing.addNewItems(ref.app_id, itemsArray).then(function(items) {
        const fieldLink = `${ref.app_id}.${items[0].item_id}`
        scope.setNgModel(fieldLink);
        scope.addOption({name: scope.searchText, value: fieldLink});
      });
     };

    let template = "<span>Not found</span>";

    if(scope.ghField && scope.ghField.data_model.express_add){
      switch (scope.typeList) {
        case "itemList":
        template = `<div class="gh-not-found-add-item" ng-click="showHandler()">
                      <span class="gh-not-found-add-item-icon" gh-icon="plus bbbcc5 22px normal"></span>
                      <span>Add item</span>
                    </div>
                    <ul ng-if="show" class="gh-not-found-add-item-popup">
                      <li ng-repeat="ref in ghField.data_model.refs" ng-click="addItem(ref)">
                        <gh-element 
                          decorator="{ data_type: 'app', data_model: { interpretation:[{src: \'form\', id: \'with_text\', settings: {show_field_name:false}}]}}"
                          value="ref.app_id">
                        </gh-element>
                      </li>
                    </ul>`;
          break;

        default:
          break;
      }
    }

      const elem = angular.element(template);
      const compiled = $compile(elem)
      element.empty()
      element.append(elem);
      compiled(scope)
   }
  }
}]);
