angular.module('automationActivatorModule', [])

  .directive('automationActivator', [function () {
    return {
      restrict: 'E',
      scope: {
        currentApp: '=',
        currentField: '='
      },

      controller: ['$scope', async function ($scope) {

        $scope.$watch('currentField.data_model.automation.active', (newVal, oldVal) => {
          if(newVal != oldVal) {
            $scope.fieldUpdate($scope.currentApp.app_id, $scope.currentField);
          }
        })

        $scope.fieldUpdate = async (appId, field) => {
          await gudhub.updateField(appId, field);
        }
      }],

      template: /*html*/
      
      `
        <gh-boolean class="field_automation_switch" gh-model="currentField.data_model.automation.active" gh-mode="switch" editable="true"></gh-boolean>
      `
      
    }
  }]);
