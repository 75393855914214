import './automations.scss';
import '../automationActivator.directive.js';
angular.module('automationElementsModule', [])

  .directive('automationElementsTable', ['GhDialog', function (GhDialog) {
    return {
      restrict: 'E',
      scope: {
        apps: '='
      },

      controller: ['$scope', '$element', async function ($scope, $element) {

        $scope.appsWithFields = {};

        // Component get apps asynchronous that's why Timeout used
        setTimeout(() => {
          $scope.apps.forEach(app => {
            app.opened = false;
          });
        }, 0)
        
        $scope.showFields = async function (app) {

          // If app recently get then we return it from cache
          if($scope.appsWithFields[app.app_id]) {
            app.opened = !app.opened;
            return;
          }

          // Show loader
          app.loading = true;

          const application = await gudhub.getApp(app.app_id);
          $scope.currentFieldList = application.field_list;
          
          for(let i = 0; i < $scope.currentFieldList.length; i++) {

            if(!$scope.currentFieldList[i].data_model.automation) continue;

            const instance = await gudhub.ghconstructor.getInstance($scope.currentFieldList[i].data_type);
            const icon = instance.getTemplate().icon;
            const constructorType = instance.getTemplate().constructor;

            $scope.currentFieldList[i].icon = icon;
            $scope.currentFieldList[i].constructor = constructorType;
            $scope.$digest();
          }
          
          app.loading = false;
          app.opened = !app.opened;

          $scope.currentFieldList = $scope.currentFieldList.filter(field => field.data_model.automation && field.constructor == 'field');
          $scope.appsWithFields[app.app_id] = $scope.currentFieldList;
          $scope.$digest();
        }

        $scope.fieldUpdate = async (app, field) => {
          await gudhub.updateField(app.app_id, field);
        }
        
        $scope.showAutomationDialog = (app, field) => {

          GhDialog.show({
            position: 'bottom',
            toolbar: true,
            template: {
              toolbar: `
                <div ng-click="apply()" class="gh-automation-apply-button action gh-btn inline btn-blue gh-btn-bg"><p>Apply</p></div>
              `,
              resizable: false,
              content: `
                <div class="gh-element-automation">
                  <gh-automation app-id="appId" debug-info="debugInfo" element-id="field.element_id" trigger="field.data_model.automation.trigger" gh-model="field.data_model.automation.model" nodes-model="nodes"></gh-automation>
                  <gh-element-automation-debugger debug-info="debugInfo" app-id="appId" element-id="field.element_id" model="field"></gh-element-automation-debugger>
                </div>
              `,
            },

            locals: {
              app,
              field,
              $parentScope: $scope
            },

            controller: ['$scope', 'app', 'field', '$parentScope', function ($scope, app, field, $parentScope) {

              $scope.appId = app.app_id;
              $scope.field = field;

              if(typeof $scope.field.data_model.trigger === 'undefined') {
                $scope.field.data_model.trigger = {
                  debug: false
                }
              }

              $scope.debugInfo = {
                debuggers: {
  
                },
                currentNodes: []
              }

              $scope.apply = async function() {
                await $parentScope.fieldUpdate(app, field);
                $scope.hide();
              }

              $scope.nodes = {
                include_nodes: gudhub.storage.getModulesList('automation', false, 'GhElement')
              }

            }]
          })
        }
      }],

      template: /*html*/
      
      `
      <div class="automation_table">
      <div class="listing_table">
        <div class="listing_table_head_wrapper">
          <div class="listing_table_column bottom_line w-35">
            <div class="tab">Applications</div>
          </div>
        </div>
        <div class="listing_table_body_wrapper">
        
          <div class="listing_table_row" ng-class="{opened: app.opened}" ng-repeat="app in apps">
          <div class="wrapper" ng-click="showFields(app)">
            <div class="listing_table_column w-50">
              <div class="show_fields" ng-show="!app.loading" ng-class="{opened: app.opened}"></div>
              <gh-loader ng-if="app.loading" type="dual-ring" color="blue"></gh-loader>
              <span class="app_icon" gh-icon="{{app.icon.icon_id}} {{app.icon.icon_color}} 40px normal {{app.icon.gradient_up}} {{app.icon.gradient_down}}"></span>
              <div class="name">{{app.app_name}}</div>
            </div>
            
          </div>


            <div class="automation_fields">
              <p ng-show="appsWithFields[app.app_id].length < 1" class="no_automations">No Automations Found</p>
              <div class="automation_field" ng-repeat="field in appsWithFields[app.app_id]">
                <div class="listing_table_column field_info w-25">
                  <div class="field__icon" gh-icon="{{field.icon}} 0d99ff 40px normal"></div>
                  <div class="field__name">{{field.field_name}}</div>
                </div>
                <div class="listing_table_column w-15">
                  <automation-activator current-app="app" current-field="field"></automation-activator>
                </div>
                <div class="listing_table_column w-5">
                  <span ng-click="showAutomationDialog(app, field)" class="edit_icon" gh-icon="pencil 0d99ff 20px"></span>
                </div>
              </div>
            </div>
        </div>
        
      </div>
      </div>`
      
    }
  }]);
