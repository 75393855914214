require('./../config.js');

angular.module('itemDataProcessor', [
'mainStorage',
'config',
'appDataProcessor',
'ghConstructor'
])




/*= === === === === === === === === === === === === === === === === === === === === === === === === === === === === === === === === === === * /
 /*=====================================   ITEMS SERVICE    ===============================================*/
/*=======================================================================================================*/
/* here we operate with Form's and Form's Editor data*/
/* started from app_controller.js*/
.service('itemsDataProcessing', [ 'fillFieldsWithDefaultValues', function(fillFieldsWithDefaultValues) {
  
  // Temp solution, before GhConstructor will be rebuild
  const addItems = gudhub.itemProcessor.addNewItems.bind(gudhub.itemProcessor);
  
  gudhub.itemProcessor.addNewItems = async function (app_id, itemsList){
    await fillFieldsWithDefaultValues(app_id, itemsList);
    return addItems(app_id, itemsList)
  }
  return gudhub.itemProcessor
}])




/*=======================================================================================================*/
/*===============================  FILL FIELDSWITH DEFAULT VALUES  ======================================*/
/*=======================================================================================================*/
//-- When we create new item we generate default value for some fields in case if GH-ELEMENT has "Used Defoult Value" feature turned on
//-- Each GH-ELEMENT has getDefaultValue() method so we use it to get a defoult value
.factory('fillFieldsWithDefaultValues', ['PipeService', 'GHConstructor', '$q', function(PipeService, GHConstructor, $q) {
  'use strict';

  return function(appId, itemsList) {
    var deferred = $q.defer();

      PipeService.on('gh_models_get', {app_id: appId}, function itemsPipe(event, fieldModels) {
        PipeService.destroy('gh_models_get', {app_id: appId}, itemsPipe);
        var fieldsToFillCount = fieldModels.length;
        var valuesArray = new Array(itemsList.length)
        fieldModels.forEach(function (fieldModel) {
          if ( fieldModel.data_model.use_default_value) {
            GHConstructor.getInstance(fieldModel.data_type).then(function (response) {
              valuesArray = getValueFromItemList(fieldModel.field_id);
              response.getDefaultValue(fieldModel, valuesArray, itemsList, appId).then(arraOfVal => {
                fillItemsWithValues(fieldModel.field_id, arraOfVal);

                if (!--fieldsToFillCount) {
                  deferred.resolve();
                }

              });
            });
          }else{
            if(!--fieldsToFillCount){
              deferred.resolve();
            }
          }
        });

      }).emit('gh_models_get', {}, {app_id: appId});


    return deferred.promise;


    function getValueFromItemList(fieldModelID){
      var arrayWthValue = [];
      for(var i = 0; i < itemsList.length; i++){
        var fieldInItemList = itemsList[i].fields.find(function(field){
          return field.field_id == fieldModelID;
        })
        if(fieldInItemList){
          arrayWthValue.push(fieldInItemList.field_value)
        }else{
          arrayWthValue.push(null)
        }
      }

      return arrayWthValue;
    }

    function fillItemsWithValues(field_idPar, arrayVal){
        for(var i = 0; i < itemsList.length; i++){
          // looking for field
          itemsList[i].fields.forEach(function(field){
            if(String(field.field_id) == String(field_idPar)){
              field.field_value = arrayVal[i];
              arrayVal[i] = null;
            }
          })
          //if we haven't found the field then we push value into the item
          if(arrayVal[i] == 0 || arrayVal[i]){
            itemsList[i].fields.push({field_id: field_idPar, field_value: arrayVal[i]})
            arrayVal[i] = null;
          }
        }
    }

  };

}]);