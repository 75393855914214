require('./../../config.js');

angular.module('ghAppSlider', [
    // 'dndLists'
])

    .directive('appsSlider', [function() {
        return {
            restrict: 'E',
            scope: {
                showCheck: '=',
                apps: '='
                // TODO
                // add mode = 'popup'
                // Make apps property optional
                // Fix bug with wrong sorting on first tab
            },
            template: /*html*/`
            <div class="slide-area slide-area__prev" data-direction="prev"></div>
            <div class="container_slider" md-selected="selected_index" ng-style="{'transform':sliderTransform ,'width':sliderWidth }">
                <ul class="flex flex-row gh_slider_wrap">
                    <li ng-if="appList.length == 0" class="flex-1" ng-style="{'max-width': IconsColumnWidth  + '%', 'min-width': IconsColumnWidth  + '%'}">
                        <div gh-element decorator="{data_model:{interpretation:[{src: 'form', id: 'name', settings: {show_field_name:false}}]},data_type: 'app_constructor'}" elem-src="form" class="slider_svg"></div>
                    </li>
                </ul>
                <div ng-repeat="tab in appList" class="slide_item" label="&#8226" ng-init="tab.index = $index" md-on-select="selected_index = $index" ng-style="styleSliderItem" >
                    <ul dnd-list="tab" dnd-drop="dndDrop(index, tab, item)" class="flex flex-row gh_slider_wrap">
                        <li ng-if="tab.index == 0 && $index == 0 && !apps" class="flex-1" ng-style="{'max-width': IconsColumnWidth  + '%', 'min-width': IconsColumnWidth  + '%', 'margin-left': '1%', 'margin-right': '1%'}">
                            <div gh-element decorator="{data_model:{interpretation:[{src: 'form', id: 'name', settings: {show_field_name:false}}]},data_type: 'app_constructor'}" elem-src="form" class="slider_svg"></div>
                        </li>
                        <li dnd-moved="moved($index, tab.index, 'moved_li')" dnd-disable-if="!removing_show" dnd-draggable="app" dnd-type="'app'" class="slider__item" ng-repeat-start="app in tab" ng-if="!app.children.length && (removing_show || app.show)" ng-style="{'max-width': IconsColumnWidth  + '%', 'min-width': IconsColumnWidth  + '%', 'opacity': app.show ? 1 : 0.5}">
                            <div dnd-list="app.children" dnd-inserted="createFolder(index, tab.index, item, app.app_id)" dnd-disable-if="apps" dnd-allowed-types="['app']" ng-if="!app.children.length" class="slider__app">
                                <a ng-show="removing_show" ng-href="/act/app_settings/{{app.app_id}}?tab=3" class="cancel transition-disabled slider_cancel" gh-icon="pencil ffffff 100% normal"></a>
                                <label ng-show="showCheck" class="container_checkbox"><input    ng-model="confirmed" ng-change="change({{app.app_id}})"  class="cancel transition-disabled slider_cancel" type="checkbox" ><span class="checkmark"></span></label>    
                                <div gh-element app-id="{{app.app_id}}" decorator="{data_model: {open_app: true, hide_menu:true}, data_type: 'app'}"  value="app.app_id" elem-src="table" class="slider_svg"></div>
                            </div>
                        </li>
                        <li dnd-moved="moved($index, tab.index, 'moved_li')" dnd-disable-if="!removing_show" dnd-draggable="app" dnd-type="'folder'" class="slider__item" ng-repeat-end ng-if="app.children.length && (removing_show || app.show)" ng-style="{'max-width': IconsColumnWidth  + '%', 'min-width': IconsColumnWidth  + '%', 'opacity': app.show ? 1 : 0.5}">
                            <div class="slider__folder" dnd-list="app.children" dnd-inserted="createFolder(index, tab.index, item, app.app_id)" dnd-disable-if="apps" dnd-allowed-types="['app']" ng-click="openFolder(app)">
                                <div class="slider__folder_item">
                                    <div gh-element app-id="{{app.app_id}}" decorator="{data_model: {open_app: false, hide_menu:true, size: 50}, data_type: 'app'}"  value="app.app_id" elem-src="table" class="slider_svg"></div>
                                </div>
                                <div ng-repeat="child in app.children" class="slider__folder_item">
                                    <div gh-element app-id="{{child.app_id}}" decorator="{data_model: {open_app: false, hide_menu:true, size: 50}, data_type: 'app'}"  value="child.app_id" elem-src="table" class="slider_svg"></div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="slider_button"></div>
            <div class="slide-area slide-area__next" data-direction="next"></div>
            `,
            controller: ['$scope', '$element','PipeService', 'sliderService', 'GhDialog', 'sendEmailService', 'sendInviteService', 'storage', 'cnfg', '$timeout', function($scope, $element, PipeService, sliderService, GhDialog, sendEmailService, sendInviteService, storage, cnfg, $timeout) {

                let removingFromFolder = false;

                $scope.openFolder = (app) => {
                    const children = JSON.parse(JSON.stringify(app.children));
                    const preparedApp = JSON.parse(JSON.stringify(app));
                    delete preparedApp.children;
                    const appsList = [preparedApp, ...children];
                    const dialog = GhDialog.show({
                        toolbar: false,
                        template: {
                            content: /*html*/`
                                <div class="folder-dialog__wrapper wrap-apps-list">
                                    <div ng-click="runEditMode()" class="edit-mode">
                                        <span gh-icon="pencil ffffff 30px"></span>
                                    </div>
                                    <apps-slider ng-class="{ 'preview-mode': previewMode }" apps="appsList"></apps-slider>
                                </div>
                            `
                        },
                        class: 'folder-dialog',
                        locals: {
                            appsList: appsList
                        },
                        controller: ['$scope', 'appsList', 'elementNode', function($scope, appsList, elementNode) {
                            $scope.appsList = appsList;
                            const dialogElement = elementNode[0]

                            dialogElement.querySelector('.gh-ui-dialog-backdrop').addEventListener('dragenter', (e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                const timeout = setTimeout(() => {
                                    removingFromFolder = true;
                                    $scope.cancel();
                                }, 1000);
                                dialogElement.querySelector('.gh-ui-dialog-backdrop').addEventListener('dragleave', function(e) {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    clearTimeout(timeout);
                                });
                            });

                            dialogElement.style.color = 'red';
                            // const background = $element[0].querySelector('.folder-dialog');
                        }]
                    });
                }

                $scope.dndDrop = (appIndex, tab, app) => {
                    if(removingFromFolder) {
                        const parentAppId = app.parent_id;

                        if(parentAppId) {
                            delete app.parent_id;

                            const parentTab = $scope.appList.find(tab => tab.find(app => app.app_id === parentAppId));
                            const parentApp = parentTab.find(app => app.app_id === parentAppId);

                            if(parentApp) {
                                parentApp.children.forEach((child, childIndex) => {
                                    if(child.app_id === app.app_id) {
                                        parentApp.children.splice(childIndex, 1);
                                    }
                                });
                            }
                        }

                        removingFromFolder = false;
                    }
                    return app;
                }

                $scope.moved = (appIndex, tabIndex, test) => {
                    $scope.appList[tabIndex].splice(appIndex, 1);
                    const appsPerTab = ($scope.appList[0].length + 1);
                    $scope.appList.forEach((tab, tabI) => {
                        tab.forEach((app, appI) => {
                            let priority;
                            if(tabI === 0) {
                                priority = appI;
                            } else {
                                priority = (appsPerTab * tabI) + appI;
                            }
                            app.priority = priority;
                        });
                    });
                }

                $scope.createFolder = (appIndex, tabIndex, item, parentAppId) => {
                    item.parent_id = parentAppId;
                }

                $element[0].querySelectorAll('.slide-area').forEach(function(el) {
                    el.addEventListener('dragenter', function(e) {
                        e.preventDefault();
                        e.stopPropagation();
                        const timeout = setTimeout(() => {
                            move({clientX: el.dataset.direction == 'next' ? -1000 : 1000}, true)
                        }, 500);
                        el.addEventListener('dragleave', function(e) {
                            e.preventDefault();
                            e.stopPropagation();
                            clearTimeout(timeout);
                        });
                    });
                });

                if(!$scope.apps) {
                    sliderService.getApplist($scope.removing_show).then(function(tabs){
                        $scope.appList = tabs;
                        $scope.$digest();
                    });
                } else {
                    const tabs = sliderService.createTabsList($scope.apps);
                    $scope.appList = tabs;
                }
                $scope.$evalAsync(function() {
                    $scope.removing_show = false;
                });
                $scope.storage_user = angular.copy(storage.getMainStorage().user);
                $scope.appListToSend = [];

                $scope.change = function(appId){
                    var findIndex = $scope.appListToSend.findIndex(function(app){
                        return app.appId == appId
                    })
                    if(findIndex == -1){
                        $scope.appListToSend.push({app_id: appId, permission: 1})
                    }else{
                        $scope.appListToSend.splice(findIndex, 1)
                    }
                }
                $scope.$parent.cancelInvite = function(){
                    $scope.showCheck = false;
                }
                $scope.$parent.openPopUpSend = function(){
                     GhDialog.show({
                    	position: 'full',
                    	template: {
                    	toolbar: '<div class="dialog-delete-app"><p>Invite user</p>This application was share</div>',
                        content: '<div ng-show="!sendFinished" class="dialog-delgete-app-content">' +
                                '<div style="margin:  0 auto; width: 40%;"><gh-element value="sendEmail" style="display:  flex;" decorator="{data_model:{multiple: 1},data_type: \'email\', field_name: \'Email\'}" elem-src="form" data-position="" element-type="email"></gh-element></div>'+
                                '<div class="app_slider_table">'+
                                '   <table><tbody><tr class="app_slider_table_name_tr"><td>App</td><td>Permission</td></tr><tr ng-repeat="app in appList"><td><div gh-element app-id="app.app_id" elem-src="form"  decorator="decorator" value="app.app_id" class="invite_app"></div></td> ' +
                                ' <td ><gh-element value="app.permission" decorator="{data_model:{multiple: 1,options:[{name: \'Read\',value: 1,color: \'#FF0000\'}, {name: \'Write\',value: 2,color: \'#FF6600\'}, {name: \'Admin\',value: 3,color: \'#FF6600\'}],interpretation:[{src: \'form\',id: \'input\',settings: {editable: 1,show_field_name: 0,show_field: 1},style: {font_size: 12}}]},data_type: \'radio_button\'}"  elem-src="form" data-position="" element-type="radio_button"></gh-element></td></tr></tbody></table>' +
                                '</div>'+
                    			'   <button type="button" class="btn btn-grean" ng-click="apply()">Apply</button>'+
                                '   <button type="button" class="btn btn-blue-reverse" ng-click="cancel()">Cancel</button></div>' +
                                '<div ng-show="sendFinished" class="gh-csv-importing_finish">'+
                                '     <div class="app_icon" gh-icon="check_circle 10cfbd 150px app"></div>'+
                                '     <p class="gh-importing_wait">Successfully Completed</p>'+
                                '     <button ng-click="cancel()" class="btn btn-grean  gh-step_bttn"><span>Finish</span></button>' +
                                ' </div> '
                    	},
                    	locals: {
                            appIdlist: $scope.appListToSend,
                            user: $scope.storage_user
                    	},
                        controller: ['$scope', 'appIdlist', 'user', function($scope, appIdlist, user) {
                            $scope.appList = appIdlist;
                            $scope.sendFinished = false;
                            $scope.sendEmail = '';
                            $scope.decorator = {
                                data_type: 'app',
                                data_model: {
                                    interpretation: [{
                                        src: "form",
                                        id: "default_inline",
                                        settings: {
                                            editable: 0,
                                            show_field_name: 0,
                                            show_field: 1
                                        },
                                        style: {
                                            font_size: 20,
                                            position: "beetwen"
                                        }
                                    }]
                                }
                            };
                            
                            $scope.apply = function(){
                                $scope.content = {
                                    emails: $scope.sendEmail,
                                    companyName: 'Gudhub',
                                    body: '<!DOCTYPE html>' +
                                    '<html>' +
                                    ' <head>' +
                                    '   <title>Gudhub</title>' +
                                    ' </head>' +
                                    ' <body style="color: #000000;">' +
                                    '<p>Hello,<br />'+ user.fullname +' shared his applications with you. Please sign-up at <a href="' + cnfg.file_server_url +'/signup/'+ $scope.sendEmail +'">EasyStoneShop.com</a></p>' +
                                    '</body>' +
                                    '</html>'
                                }
                                if($scope.sendEmail){
                                    sendInviteService.sendInvite($scope.appList, $scope.sendEmail).then(function(){
                                        sendEmailService.sendEmail($scope.content, $scope.appList).then(function(){
                                            $scope.sendFinished = true;
                                        });
                                    });
                                }
                                
                            }
                        	}]
                        }).then(function(data) {});
                }
                let N = 0;
                let slideBack = 0;

                let i = 0, //-- Current Slide
                    x0 = null,
                    locked = false,
                    w, h, ini, fin, rID = null,
                    anf;

                const _C = $element[0].children[1];
                const NF = 20; //-- Number of frames (influence on speed )
                var address = {
                    recipient: 'all'
                };

                PipeService.on('gh_apps_list_update', address, async function app_listPipe(event, storageAppsList) {
                    await gudhub.appProcessor.refreshAppsList();
                    sliderService.getApplist($scope.removing_show).then(function(tabs){
                        // replace app list only if new app list is different
                        if(angular.toJson(tabs) !== angular.toJson($scope.appList)){
                            $scope.appList = tabs;
                            $scope.$digest();
                        };
                        
                    });
                }, $scope);

                PipeService.on('gh_apps_list_refreshed', {}, function app_listPipe(event, storageAppsList) {
                    sliderService.getApplist($scope.removing_show).then(function(tabs){
                        // replace app list only if new app list is different
                        if(angular.toJson(tabs) !== angular.toJson($scope.appList)){
                            $scope.appList = tabs;
                            $scope.$digest();
                        };
                        
                    });
                }, $scope);


                $scope.sliderTransform = "";
                $scope.sliderWidth = "";
                $scope.IconsPerRow = 0;
                $scope.IconsPerColumn = 0;

                let editModeActive = false;

                $scope.$parent.runEditMode = function(){
                    editModeActive = !editModeActive;
                    toggleSlideListeners();
                    $scope.showCheck = false;
                    $scope.removing_show = !$scope.removing_show;
                    $scope.$parent.removing_show_pencil = !$scope.$parent.removing_show_pencil;
                    if(!$scope.apps) {
                        sliderService.getApplist($scope.removing_show).then(function(tabs){
                            $scope.appList = tabs;
                        });
                    } else {
                        const tabs = sliderService.createTabsList($scope.apps);
                        $scope.appList = tabs;
                    }

                    if(!editModeActive) {
                        const apps = $scope.appList.reduce((previous, current) => {
                            const childrens = [];
                            current.forEach(child => {
                                if(child.children) {
                                    childrens.push(...child.children);
                                }
                            });
                            previous.push(...current, ...childrens);
                            return previous;
                        }, []);

                        const dataToSave = apps.map(app => {
                            return {
                                app_id: app.app_id,
                                priority: app.priority,
                                parent_id: app.parent_id
                            }
                        });

                        localStorage.setItem('apps', JSON.stringify(dataToSave));
                    }
                    
                    ani();
                    // // DND function to run
                    // if(!$scope.removing_show){
                    //     console.log("ADDD SLIDER MOVE FUNCTIOn")
                    //     _C.addEventListener('mousemove', drag, false);
                    //     _C.addEventListener('touchmove', drag, false);
                    // }else{
                    //     _C.removeEventListener('mousemove', drag, false);
                    //     _C.removeEventListener('touchmove', drag, false);
                    // }
                }

                $scope.$watchCollection('appList', function() {

                     size();
                    if($scope.appList == undefined){
                        return;
                    }
                    if ($scope.appList.length > 0) {
                        N = $scope.appList.length;

                        let sliderWidth_v = N * 100;
                        $scope.sliderWidth = sliderWidth_v + "%";

                        let sliderWidthItem_v = 100 / N;
                        $scope.styleSliderItem = {
                            "width": sliderWidthItem_v + "%"
                        }

                        //When one slide removed we recalculate transform position
                        if( (i+1) > N ){
                          i = i-1;
                          let curentSlidePoss = -i*100/N;
                          $scope.sliderTransform = "translate(" + curentSlidePoss + "%)";
                        }

                        //-- Add button in the bottom --//
                        const sliderButtons = $element[0].querySelector('.slider_button');

                        if( sliderButtons ){
                          sliderButtons.innerHTML = '';
                          for (let i = 0; i < N; i++){
                            let sliderButton = document.createElement("div");
                            sliderButton.classList.add('slider-button_item');
                            sliderButtons.appendChild(sliderButton);
                          }
                        }

                        //-- Made button move slider  --//
                        const button_slid = $element[0].getElementsByClassName('slider-button_item');
                        button_slid[i].classList.add('slider-button_active');

                        if(button_slid){
                          for (let k = 0; k < button_slid.length; k++) {
                              button_slid[k].addEventListener('click', function (index) {
                                for(let j = 0; j < button_slid.length; j++){
                                    button_slid[j].classList.remove('slider-button_active');
                                };
                              button_slid[k].classList.add('slider-button_active');  //-- Add active class to button --//
                                  ini = slideBack;
                                  i = k;
                                  fin = i;
                                  anf = NF;
                                  ani();
                                  slideBack = i;
                              });
                          }

                        }


                    }

                });



                const TFN = {
                    'linear': function(k) {
                        return k
                    },
                    'ease-in': function(k, e = 1.675) {
                        return Math.pow(k, e)
                    },
                    'ease-out': function(k, e = 1.675) {
                        return 1 - Math.pow(1 - k, e)
                    },
                    'ease-in-out': function(k) {
                        return .5 * (Math.sin((k - .5) * Math.PI) + 1)
                    }
                };



                //-- Stop Animation --//
                function stopAni() {
                    cancelAnimationFrame(rID);
                    rID = null
                };


                //-- Animation Slading --//
                function ani(cf = 0) {
                    let v = (ini + (fin - ini) * TFN['ease-out'](cf / anf)) / N * (-100);
                    $scope.sliderTransform = "translate(" + v + "%)";
                    $timeout(function(){
                      $scope.$digest()
                    }, 0)

                    if (cf === anf) {
                        stopAni();
                        return
                    }

                    //-- Runs sliding Animation  "anf"  times
                    // cf - curent frame
                    rID = requestAnimationFrame(ani.bind(this, ++cf))
                };

                function unify(e) {
                    return e.changedTouches ? e.changedTouches[0] : e
                };

                function lock(e) {
                    x0 = unify(e).clientX;
                    locked = true

                };

                function drag(e) {
                    e.preventDefault();

                    if (locked) {
                        let dx = unify(e).clientX - x0,
                            f = +(dx / w).toFixed(2);
                        let v = (i - f) / N * (-100);
                        $scope.sliderTransform = "translate(" + v + "%)";
                        $scope.$digest();
                    }
                };


                function move(e, fromDrag = false) {
                    if($scope.appList.length !== 0){
                        if (locked || fromDrag) {
                            let dx = unify(e).clientX - x0, //-- Delta X mouse muvment in pixels
                                s = Math.sign(dx),
                                f = +(s * dx / w).toFixed(2); //--  Delta X mouse muvment in renge 0.0 - 1.0
    
                            ini = i - s * f;
                            if ((i > 0 || s < 0) && (i < N - 1 || s > 0) && f > .2) {
                                i -= s;
                                f = 1 - f
                            }
    
                            fin = i;
                            anf = Math.round(f * NF); //-- calculates number of animation frames
    
                            let coutIni = ini % Math.floor(ini);
                            if( coutIni != 0){
                              ani();
    
                            }
                            x0 = null;
                            locked = false;
                        }
    
                        //-- Add active class to button --//
                        const button_slid = $element[0].getElementsByClassName('slider-button_item');
                        for(let j = 0; j < button_slid.length; j++){
                            button_slid[j].classList.remove('slider-button_active');//-- delet active class to button
                        };
                        button_slid[i].classList.add('slider-button_active'); 
                    }
                };



                //--Size window--//
                function size() {
                    w = window.innerWidth;
                    h = window.innerHeight;

                    appIconsCounter(w);
                };

                //--Count how many column in the one row--//
                function appIconsCounter(width) {
                    if (width > 1280) {
                        $scope.IconsPerRow = 6;
                    } else if (width > 1000) {
                        $scope.IconsPerRow = 5;
                    } else if (width > 750) {
                        $scope.IconsPerRow = 4;
                    } else if (width > 500) {
                        $scope.IconsPerRow = 3;
                    } else {
                        $scope.IconsPerRow = 2;
                    }
                    $scope.IconsColumnWidth = 100 / $scope.IconsPerRow - 2;
                    $timeout(function(){
                      $scope.$digest()
                    }, 0)
                };


                size();

                // Delete application
                $scope.deleteApp = function(appId) {
                  PipeService.emit('gh_delete_app', {}, {app_id: appId});
                  $scope.buttonDelete = false;
                };

                addEventListener('resize', size, false);

                let slideListenersInitialized = false;

                function toggleSlideListeners() {
                    if (!slideListenersInitialized) {
                        _C.addEventListener('mousedown', lock, false);
                        _C.addEventListener('touchstart', lock, false);
                        
                        _C.addEventListener('mousemove', drag, false);
                        _C.addEventListener('touchmove', drag, false);
                        
                        _C.addEventListener('mouseup', move, false);
                        _C.addEventListener('touchend', move, false);
                        
                        _C.addEventListener('mouseleave', move, false);
                    } else {
                        _C.removeEventListener('mousedown', lock, false);
                        _C.removeEventListener('touchstart', lock, false);
                        
                        _C.removeEventListener('mousemove', drag, false);
                        _C.removeEventListener('touchmove', drag, false);
                        
                        _C.removeEventListener('mouseup', move, false);
                        _C.removeEventListener('touchend', move, false);
                        
                        _C.removeEventListener('mouseleave', move, false);
                    }
                    slideListenersInitialized = !slideListenersInitialized;
                }

                toggleSlideListeners();

            }]
        }
    }])
