import { AutomationInstanceCreator } from './../automation/AutomationInstanceCreator.js';

angular.module('automationComponentModule', [])

.directive('automationComponent', ['$document', function ($document) {

  return {
    scope: {
      element: '=',
      components: '='
    },

    controller: ['$scope', '$element', async ($scope, $element) => {
      // if we don't have module in registered modules we must register this module
      if(!$scope.components.find(component => component.data_model.data_type === $scope.element.data_type)) {
        await $scope.$parent.$parent.$parent.registerModule($scope.element.data_type);
        $scope.$apply();
      }

      $element[0].querySelector('.add-node-button').addEventListener('dragstart', e => {
        e.dataTransfer.setData('text/plain', e.target.getAttribute('data-name'));
      });

    }],
    template: `
    <div draggable="true" data-name="{{element.name}}" class="add-node-button">
      <div gh-icon="{{element.icon}} #0D99FF 30px"></div>
      <span>{{element.name}}</span>
    </div>`
  }
}])