import './api_automation.scss';
import "/gh_elements/trigger/gh_trigger_switch.scss";
angular.module('apiElementsTableModule', [])

  .directive('apiElementsTable', ['GhDialog', 'cnfg', function (GhDialog, cnfg) {
    return {
      restrict: 'E',
      scope: {
        apiList: '='
      },

      controller: ['$scope', '$element', async function ($scope, $element) {

        $scope.apiListWithApp = [];
        for(let i = 0; i < $scope.apiList.length; i++) {
          const app = await gudhub.getAppInfo($scope.apiList[i].app_id);
          $scope.apiListWithApp.push($scope.apiList[i]);
          $scope.apiListWithApp[i].app = app;
          $scope.$digest();
        }

        $scope.updateApi = async (api) => {
          let updatedApi = JSON.parse(angular.toJson( api ));
          delete updatedApi.app;
          await fetch(`${cnfg.node_services_api_path}/api/editor/update`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json;charset=utf-8",
              Authorization: `Bearer ${api.auth_key.replace(/\s/g, "")}`,
            },
            body: JSON.stringify(updatedApi),
          });
        };

        function showCredentials(api) {
          GhDialog.show({
            toolbar: true,
            position: "center",
            template: {
              content: `<ul class="gh-api-credentials">
                            <li class="gh-api-credentials-item">
                              <span class="gh-api-credentials-title">API endpoint: </span>
                              <span class="gh-api-credentials-content" ng-click="copy(api_address)">{{api_address}}</span>
                            </li>
                        </ul>`,
            },
            locals: {
              api
            },
            controller: [
              "$scope",
              "api",
              "cnfg",
              "ghToastService",
              function ($scope, api, cnfg, ghToastService) {
                $scope.api = api;
                $scope.api_address = `${cnfg.node_services_api_path}api/${api.app_id}/${api.method}`;
                $scope.copy = (text) => {
                  const textArea = document.createElement("textarea");
                  textArea.style.position = "fixed";
                  textArea.style.top = 0;
                  textArea.style.left = 0;
                  textArea.style.width = "2em";
                  textArea.style.height = "2em";
                  textArea.style.padding = 0;
                  textArea.style.border = "none";
                  textArea.style.outline = "none";
                  textArea.style.boxShadow = "none";
                  textArea.style.background = "transparent";
                  textArea.value = text;
                  document.body.appendChild(textArea);
                  textArea.focus();
                  textArea.select();

                  try {
                    const successful = document.execCommand("copy");
                    if (successful) {
                      ghToastService.simple("Copied");
                    }
                  } catch (err) {
                    console.log("Oops, unable to copy");
                  }

                  document.body.removeChild(textArea);
                };
              },
            ],
          });
        };

        $scope.openDeleteDialogPopup = function (api) {
          GhDialog.show({
            position: 'center',
            toolbar: false,
            template: {
              toolbar: '',
              resizable: false,
              content: `
              <div class="delete_group-popup">
                <div class="dialog-share-group">
                  <span gh-icon="rubbish FF0D0D 65px"></span>
                  <p class="dialog-share-group__title">Deleting Api!</p>
                </div>
                <div class="dialog-share-group__content">
                  <p class="info">Do you really want to delete api? You will not be able to restore it.</p>
                  <div class="dialog-share-group__content__buttons">
                    <button class="dialog-share-group__content__buttons__add btn btn-blue-reverse" ng-click="deleteApi()">Delete</button>
                    <button class="dialog-share-group__content__buttons__cancel btn btn-blue" ng-click="cancel()">Cancel</button>
                  </div>
                </div>
              </div>`,
            },
            locals: {
              api,
              apiList: $scope.apiListWithApp,
              apiScope: $scope
            },
              controller: ['$scope', 'apiList', 'apiScope', function ($scope, apiList, apiScope) {

                $scope.deleteApi = async function() {
                  try {
                    await fetch(`${cnfg.node_services_api_path}/api/editor/delete`, {
                      method: 'DELETE',
                      headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${api.auth_key.replace(/\s/g, "")}`,
                      },
                      body: JSON.stringify({app_id: api.app_id, item_id: api.item_id, field_id: api.field_id })
                    });
                    apiList.splice(apiList.indexOf(api), 1)
                    apiScope.$apply();
                    $scope.cancel();
                  } catch(error) {
                    console.log(error);
                  }
                }
              }]
              
          })
        };
        
        $scope.showAutomationDialog = (api) => {

          GhDialog.show({
            position: 'bottom',
            toolbar: true,
            template: {
              toolbar: `
                <div ng-click="apply()" class="gh-automation-apply-button action gh-btn inline btn-blue gh-btn-bg"><p>Apply</p></div>
                <gh-input class="gh-automation-target-app-dropdown" ng-model="api.method"/>
                <button ng-click="showCredentials(api)" class="btn-blue gh-api-btn-credentials">Credentials</button>
                <gh-automation-mock debug-info="debugInfo" options="options" trigger="api" init="init">,
              `,
              resizable: false,
              content: `
                <div class="gh-element-automation">
                
                  <gh-automation debug-info="debugInfo" gh-model="api.model" nodes-model="settings" trigger="api" init="init"/>
                
                </div>
              `,
            },

            locals: {
              api,
              showCredentials,
              $parentScope: $scope
            },

            controller: ['$scope', 'api', 'showCredentials', '$parentScope', function ($scope, api, showCredentials, $parentScope) {

              $scope.api = api;
              $scope.showCredentials = showCredentials;

              $scope.debugInfo = {
                debuggers: {

                },
                currentNodes: []
              }

              $scope.debugInfo = {
                debuggers: {
  
                },
                currentNodes: []
              }

              $scope.options = [
                {
                  name: "GET",
                  value: "GET",
                },
                {
                  name: "HEAD",
                  value: "HEAD",
                },
                {
                  name: "POST",
                  value: "POST",
                },
                {
                  name: "PUT",
                  value: "PUT",
                },
                {
                  name: "DELETE",
                  value: "DELETE",
                },
              ];
              $scope.settings = {
                  include_nodes: gudhub.storage.getModulesList('automation', false, 'API'),
              };

              $scope.apply = async function() {
                await $parentScope.updateApi($scope.api);
                $scope.hide();
              }

            }]
          })
        };
      }],

      template: /*html*/
      
      `
      <div class="automation_table api_table">
      <div class="listing_table">
        <div class="listing_table_head_wrapper">
          <div class="listing_table_column bottom_line w-35">
            <div class="tab">Name</div>
          </div>
          <div class="listing_table_column w-35">
            <div class="tab">Application</div>
          </div>
          <div class="listing_table_column w-15">
            <div class="tab">Status</div>
          </div>
          <div class="listing_table_column w-15">
          </div>
        </div>
        <div class="listing_table_body_wrapper">
        
          <div class="listing_table_row" ng-repeat="api in apiListWithApp">
          <div class="wrapper">
            <div class="listing_table_column w-35 api_name">
              <div class="name">{{api.method}}</div>
            </div>
            <div class="listing_table_column w-35 app_name">
              <span class="app_icon" gh-icon="{{api.app.icon.icon_id}} {{api.app.icon.icon_color}} 40px normal {{api.app.icon.gradient_up}} {{api.app.icon.gradient_down}}"></span>
              <div class="name">{{api.app.app_name}}</div>
            </div>
            <div class="listing_table_column w-15">
              <div class="status">
                <gh-boolean class="gh-api-btn-switch" ng-click="updateApi(api)" gh-model="api.active" gh-mode="switch" editable="true"></gh-boolean>
              </div>
            </div>
            <div class="listing_table_column w-15">
                <span ng-click="showAutomationDialog(api)" class="edit_icon" gh-icon="pencil 0d99ff 20px"></span>
            </div>
            <div class="listing_table_column w-5">
              <span class="delete" gh-icon="rubbish 0d99ff 20px" ng-click="openDeleteDialogPopup(api)">Delete</span>
            </div>
        </div>
        
      </div>
      </div>`
      
    }
  }]);
