angular.module('ghCheckboxListModule', [])

  .directive('ghCheckboxList', [function() {

    return{
      restrict: 'E',
      scope: {
        ghValues: '=', // Array of all possible values
        ghModel: '=', // Checked value
        ghField: '=' // Model of interpetation field in checkbox
      },
      template:
        ' <md-checkbox ng-repeat="item in ghValues" ng-checked="exists(item.value)" ng-click="checkValue(item.value)">'+
        '   <div>{{item.name}}</div>'+
        ' </md-checkbox>',
      controller: [ '$scope', '$element', '$attrs', function($scope, $element, $attrs) {

        // Check if value existing in values array
        $scope.exists = function (value) {
          return $scope.ghModel.indexOf(value) > -1;
        };

        // Set/delete value from ghModel
        $scope.checkValue = function (value) {
          var idx = $scope.ghModel.indexOf(value);
          if (idx > -1) {
            $scope.ghModel.splice(idx, 1);
            return;
          }
          $scope.ghModel.push(value);
        };

      }]
    };

  }]);

