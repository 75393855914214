angular.module('wallyModule', [
])
.directive('ghWally', ['$templateCache', '$compile', function($templateCache, $compile) {
	return {
		restrict: 'E',
    scope: {
			scene: '@'
		},
		link: function(scope, elem) {
			var loaderTemplate = '<div></div>';

		/*----------  wally Rendering ------------*/
		var wallyRendering = function( template  ) {
		/*-- rendering to html*/
		var el = angular.element( template );
		var compiled = $compile(el);
		elem.empty();
		elem.prepend(el);
			scope.hideLoader = true;
		compiled(scope);
		};

		var checkTemplate = function( req ){
			// debugger;
		if( $templateCache.get(  scope.scene  + '.html' ) ){
			var template = $templateCache.get(scope.scene + '.html');
			wallyRendering( template );
				clearInterval(stop);
		} else {
			// debugger;
				wallyRendering( loaderTemplate );
				stop = setInterval(checkTemplate.bind(null, scope.scene), 1000);
		}
		};
		checkTemplate(scope.scene);
		},
	}

}]);