import './gh_automation_dialog.scss';

angular.module('ghAutomationDialogModule', [
])

  .directive('ghAutomationDialog', [function () {

    return {
      restrict: 'E',

      scope: {
        ghModel: '=', // Checked value
        nodesModel: '=',//!!!!!!!!!!! DO NOT FORGER TO ADD COMPONETS IN DIRERECTIVE ATRIBUTE !!!!!!!!!!//
        appId: '=',
        elementId: '=',
        trigger: '=',
        init: '=?',
        mode: '=?',
        inputs: '=?',
        options: '=?',
        reinitCallback: '=?',
        syncEvaluation: '=?'
      },

      controller: ['$scope', 'GhDialog', function ($scope, GhDialog) {

        function applyModel(model) {
          $scope.ghModel = model;
        }

        function applyOptions(options) {
          $scope.options = options;
          if($scope.reinitCallback && typeof $scope.reinitCallback === 'function') {
            $scope.reinitCallback();
          }
        }

        function applySyncEvaluation(syncEvaluation) {
          $scope.syncEvaluation = syncEvaluation;
        }

        $scope.openPopup = () => {
          GhDialog.show({
            position: 'bottom',
            class: 'gh-automation-dialog__popup',
            template: {
              toolbar: /*html*/`
              <div class="iterator-settings__toolbar">
                  <div ng-click="apply()" class="iterator-settings__apply gh-automation-dialog-apply action gh-btn inline btn-blue gh-btn-bg" role="button" tabindex="0">
                    <p>Apply</p>
                  </div>
                  <div class="iterator-settings__tabs">
                    <label ng-class="{'active': currentTab == 1}" ng-click="showIteratorSettings()" role="button" tabindex="0" class="active">
                        <div class="icon" gh-icon="integrations C0C1C3 32px normal"></div>
                        <div>Iterator</div>
                    </label>
                    <label ng-class="{'active': currentTab == 2}" ng-click="currentTab = 2" role="button" tabindex="0">
                        <div class="icon" gh-icon="configuration C0C1C3 32px normal"><</div>
                        <div>Options</div>
                    </label>
                  </div>
                </div>
                        `,
              content: /*html*/`
                    <gh-automation ng-show="currentTab == 1" debug-info="debugInfo" gh-model="ghModel" nodes-model="nodesModel"></gh-automation>
                    <gh-element class="sync-evaluation" value="syncEvaluation" decorator="{field_name: 'Sync iteration', data_model:{interpretation:[{src: 'form'}]},data_type: 'boolean'}" elem-src="form" ng-show="currentTab == 2"></gh-element>
                    <gh-option-table ng-show="currentTab == 2" items="options" pattern="patterns"></gh-option-table>
              `
            },
            locals: {
              ghModel: $scope.ghModel,
              options: $scope.options,
              syncEvaluation: $scope.syncEvaluation
            },
            controller: ['$scope', 'ghModel', 'options', 'syncEvaluation', function ($scope, ghModel, options, syncEvaluation) {
              $scope.ghModel = ghModel;
              $scope.currentTab = 1;

              $scope.options = options;

              $scope.syncEvaluation = syncEvaluation;

              $scope.showIteratorSettings = () => {
                $scope.currentTab = 1;
                $scope.debugInfo?.reinit();
              }

              $scope.patterns = [
                {
                  property: "type",
                  type: "text_opt",
                  prop_name: "Type",
                  data_model: function (option) {
                    return {
                      options: [
                        { value: "items", name: "Items" },
                        { value: "object", name: "Object" },
                        { value: "field_value", name: "Value" },
                      ],
                    };
                  },
                  display: true
                },
                {
                  property: "socket_name",
                  type: "text",
                  prop_name: "Socket Name",
                  data_model: function (option) {
                    return {};
                  },
                  display: true
                }
              ];

              for(const nodeIndex in $scope.ghModel.nodes) {

                const node = $scope.ghModel.nodes[nodeIndex];

                if(node.name === 'Iterator Input') {

                  ghModel.nodes[nodeIndex].data.options = options;

                }

                if(node.name === 'Iterator Output') {
                    
                    ghModel.nodes[nodeIndex].data.options = options;
  
                  }

              }

              $scope.apply = () => {
                ghModel = $scope.ghModel;
                options = $scope.options;
                syncEvaluation = $scope.syncEvaluation;
                applyModel(ghModel);
                applyOptions(options);
                applySyncEvaluation(syncEvaluation);
                $scope.cancel();
              }

              $scope.debugInfo = {
                debuggers: {

                },
                currentNodes: []
              }

              $scope.nodesModel = {};
              $scope.nodesModel.include_nodes = gudhub.storage.getModulesList('automation', false, 'Iterator');
            }]
          })
        }

        $scope.openDebugPopup = () => {
          GhDialog.show({
            position: 'full',
            template: {
              content: /*html*/`
                <iterator-debugger trigger="trigger" debug-info="debugInfo" gh-model="ghModel" mocks="mocks"></iterator-debugger>
                <gh-automation hide-catalog="true" debug-info="debugInfo" trigger="trigger" gh-model="ghModel" nodes-model="nodesModel"></gh-automation>
              `
            },
            locals: {
              ghModel: $scope.ghModel,
              inputs: $scope.inputs
            }, 
            controller: ['$scope', 'ghModel', 'inputs', async function ($scope, ghModel, inputs) {
              $scope.ghModel = ghModel;

              $scope.debugInfo = {
                debuggers: {

                },
                currentNodes: []
              }

              $scope.trigger = {
                debug: true
              }

              $scope.mocks = [];

              $scope.nodesModel = {};
              $scope.nodesModel.include_nodes = gudhub.storage.getModulesList('automation', false);
              let additionalNodes = [{
                  name: "Pop Up Form",
                  data_type: "PopUpForm",
                  icon: 'automation_pop_up_form'
              }];

              $scope.nodesModel.include_nodes = additionalNodes.concat($scope.nodesModel.include_nodes);

              inputs = JSON.parse(JSON.stringify(inputs));
              
              const items = inputs.inpItems[0];
              const object = inputs.inpObject;
              const value = inputs.inpValue;

              const iterations = Math.max(items ? items.length : 0, object ? Object.keys(object).length : 0);
                
              if(iterations > 0) {
                for(let i = 0; i < iterations; i++) {
                  const inputs = {
                    items: items ? items[i] : null,
                    object: object ? Object.values(object)[i] : null,
                    value: value ? value[i] : null
                  }

                  $scope.mocks.push(inputs);
                }
              }

            }]
          })
        }
      }],

      template: /*html*/`
        <span ng-if="!mode" ng-click="openPopup()" class="automation-settings-icon" gh-icon="configuration ffffff 24px normal"></span>
        <span ng-if="mode === 'debug'" ng-click="openDebugPopup()" gh-icon="integrations ffffff 24px normal"></span>
      `,
    };

  }]);
