import './appsList.scss';
angular.module('appsListModule', [])

  .directive('appsList', ['GhDialog', function (GhDialog) {
    return {
      restrict: 'E',
      scope: {
        apps: '='
      },

      controller: ['$scope', '$element', async function ($scope, $element) {
        $scope.options = [{
          name: 'Block',
          value: 0,
          color: '#000000'
        }, {
          name: 'Read',
          value: 1,
          color: '#05B52C'
        }, {
          name: 'Write',
          value: 2,
          color: '#0FB5FF'
        },{
          name: 'Admin',
          value: 3,
          color: '#E70061'
        }]

        $scope.showDeleteAppPopup = function (app) {
          GhDialog.show({
            position: 'center',
            toolbar: false,
            template: {
              toolbar: '',
              resizable: false,
              content: `
              <div class="delete_group-popup">
                <div class="dialog-share-group">
                  <span gh-icon="rubbish FF0D0D 65px"></span>
                  <p class="dialog-share-group__title">Deleting Application!</p>
                </div>
                <div class="dialog-share-group__content">
                  <p class="info">Do you really want to delete this application?</p>
                  <div class="dialog-share-group__content__buttons">
                    <button class="dialog-share-group__content__buttons__add btn btn-blue-reverse" ng-click="deleteApp()">Delete</button>
                    <button class="dialog-share-group__content__buttons__cancel btn btn-blue" ng-click="cancel()">Cancel</button>
                  </div>
                </div>
              </div>`,
            },
            locals: {
              app,
              applications: $scope.apps
            },
              controller: ['$scope', 'app', 'applications', function ($scope, app, applications) {

                $scope.deleteApp = async function() {
                  try {
                    await gudhub.sharing.delete(app.app_id, gudhub.storage.user.user_id);
                    applications.splice(applications.indexOf(app), 1);
                    $scope.$apply();
                    $scope.cancel();
                  } catch(error) {
                    console.log(error);
                  }
                }
              }]
              
          })
        }

      }],

      template: /*html*/
      
      `
      <div class="listing_table">
        <div class="listing_table_head_wrapper">
          <div class="listing_table_column bottom_line w-50">
            <div class="tab">Name</div>
          </div>
          <div class="listing_table_column w-35">
            <div class="tab">Permission</div>
          </div>
          <div class="listing_table_column w-15">
            
          </div>
        </div>
        <div class="listing_table_body_wrapper" ng-style="overflow">
          <div class="listing_table_row" ng-repeat="app in apps">
            <div class="listing_table_column w-50">
              <span class="number">{{$index + 1}}</span>
              <span class="app_icon" gh-icon="{{app.icon.icon_id}} {{app.icon.icon_color}} 40px normal {{app.icon.gradient_up}} {{app.icon.gradient_down}}"></span>
              <div class="name">{{app.app_name}}</div>
            </div>
            <div class="listing_table_column w-35">
              <gh-radio-button class="gh-radio-button" gh-options="options" gh-model="app.permission" gh-mode="current_value" editable="false"/> +
            </div>
            <div class="listing_table_column w-15">
              <span ng-click="showDeleteAppPopup(app)" class="delete_icon" gh-icon="rubbish 0d99ff 20px"></span>
            </div>

          </div>
        </div>
      </div>`
      
    }
  }]);
