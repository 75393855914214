import Rete from "rete";

export class ModuleControl extends Rete.Control {
  constructor(emitter, key, readonly, node, settings, template, promise) {
    super(key);
    this.render = "js";
    this.key = key;
    this.node = node;
    this.settings = settings;
    this.template = template;
    this.promise = promise;
  }

  handler(el, editor, $scope, $compile) {
    //-- Container for controls
    const container = document.createElement("div");
    container.className = "gh-node-content";
    container.setAttribute('data-node-id', this.node.id);
    el.appendChild(container);

    $scope.debugInfo.debuggers = $scope.debugInfo.debuggers || {};
    $scope.debugInfo.currentNodes = $scope.debugInfo.currentNodes || [];

    this.node.data.model = $scope.ghModel.nodes[this.node.id]?.data?.model ? $scope.ghModel.nodes[this.node.id].data.model : {
      id: "trigger@0.1.0",
      nodes: { 
        1: { 
          id: 1,
          data: {},
          inputs: {},
          outputs: { 
            items: { 
              connections: []
            }
          },
          position: [50, 50],
          name: "Iterator Input" 
        },
        2: { 
          id: 2,
          data: {},
          inputs: { 
            items: {
              connections: []
            }
          },
          outputs: {},
          position: [350, 50],
          name: "Iterator Output" 
        }
      }
    }

    if(this.promise) {
      const resolve = this.promise.resolve;
      const nodeId = this.node.id
      $scope.debugInfo.debuggers[nodeId] = {
        resolve() {
          resolve();
          $scope.debugInfo.currentNodes = $scope.debugInfo.currentNodes.filter((id) => id != nodeId)
          this.promiseResolved = true;
        },
        promiseResolved: false,
        code: {}
      };

      this.promise.updater = (data = "No DATA") => {
        $scope.nodeActive = true;
        $scope.debugInfo.debuggers[nodeId].code = typeof data === 'object' ? JSON.parse(JSON.stringify(data)) : data;
        $scope.debugInfo.currentNodes.push(nodeId);
        el.parentElement.classList.add('active');
        $scope.$digest();
      };

      if (!$scope.trigger || !$scope.trigger.debug) {
        $scope.debugInfo.debuggers[nodeId].resolve()
      }
    }

    $scope.nodeSettings = [
      {
        title: "Debugger",
        type: "general_setting",
        icon: "",
        columns_list: [
          [
            {
              type: "header",
              title: "Inputs data",
            },

            {
              type: "ghElement",
              property: "inputsData",
              data_model: function (fieldModel) {
                return {
                  data_model: {
                    documentData: fieldModel.inputsData,
                    interpretation: [
                      {
                        src: "form",
                        id: "json",
                        settings: {
                          editable: 1,
                          show_field_name: 0,
                          show_field: 1,
                        },
                      },
                    ],
                  },
                  field_name: "Inputs data",
                  name_space: "inputs_data",
                  data_type: "json_viewer",
                };
              },
            },
          ],
          [
            {
              type: "header",
              title: "Outputs data",
            },
            {
              type: "ghElement",
              property: "outputsData",
              data_model: function (fieldModel) {
                return {
                  data_model: {
                    documentData: fieldModel.outputsData,
                    interpretation: [
                      {
                        src: "form",
                        id: "json",
                        settings: {
                          editable: 1,
                          show_field_name: 0,
                          show_field: 1,
                        },
                      },
                    ],
                  },
                  field_name: "Outputs data",
                  name_space: "outputs_data",
                  data_type: "json_viewer",
                };
              },
            },
          ],
        ],
      },
    ];

    $scope.nodeActive = false;

    $scope.reinitCallback = () => {
      $scope.debugInfo?.reinit();
    }

    //-- Controls (Icon with edit button )
    let elem = angular.element(/*html*/`<gh-automation-dialog sync-evaluation="ghModel.nodes[${this.node.id}].data.syncEvaluation" reinit-callback="reinitCallback" ng-if="!trigger.debug" options="ghModel.nodes[${this.node.id}].data.options" gh-model="ghModel.nodes[${this.node.id}].data.model"></gh-automation-dialog>
                                <gh-element ng-if="trigger.debug" elem-src="form"
                                    decorator="{data_model: {settings: nodeSettings, color: 'fff', icon: 'table' }, data_type: 'additional_settings'}"
                                    value="debugInfo.debuggers[${this.node.id}].code"/>

                                <gh-automation-dialog mode="'debug'" inputs="debugInfo.debuggers[${this.node.id}].code.inputsData" ng-if="trigger.debug && nodeActive" gh-model="ghModel.nodes[${this.node.id}].data.model"></gh-automation-dialog>
                                <span ng-if="!trigger.debug" class="gh-delete-node" ng-click="deleteNode(${this.node.id})" gh-icon="rubbish ffffff 24px normal"></span>
                                <span ng-if="trigger.debug" class="gh-delete-node" ng-click="debugInfo.debuggers[${this.node.id}].resolve()" gh-icon="wmv ffffff 24px normal"></span>
                                <span class="automation-settings-icon" gh-icon="${this.template.icon} ffffff 84px normal"></span>`);

    //-- appending angular element
    setTimeout(() => {
      const node = angular.element(container)[0].parentElement.parentElement;
      const nodeId = this.node.id;
      $scope.$watch('debugInfo.currentNodes', (new_value, old_value) => {
        if(old_value.includes(this.node.id)) {
          node.classList.remove('active');
          $scope.nodeActive = false;
        }
      });

      const compiled = $compile(elem);
      angular.element(container).append(elem);
      compiled($scope);
    }, 0);
  }
}
