import './appsTable.scss';
angular.module('appsTableModule', [])

  .directive('appsTable', ['GhDialog', function (GhDialog) {
    return {
      restrict: 'E',
      scope: {
        group: '='
      },

      controller: ['$scope', '$element', async function ($scope, $element) {
        $scope.options = [{
          name: 'Block',
          value: 0,
          color: '#000000'
        }, {
          name: 'Read',
          value: 1,
          color: '#05B52C'
        }, {
          name: 'Write',
          value: 2,
          color: '#0FB5FF'
        },{
          name: 'Admin',
          value: 3,
          color: '#E70061'
        }]

        $scope.showDeleteAppPopup = function (app) {
          GhDialog.show({
            position: 'center',
            toolbar: false,
            template: {
              toolbar: '',
              resizable: false,
              content: `
              <div class="delete_group-popup">
                <div class="dialog-share-group">
                  <span gh-icon="rubbish FF0D0D 65px"></span>
                  <p class="dialog-share-group__title">Deleting Application!</p>
                </div>
                <div class="dialog-share-group__content">
                  <p class="info">Do you really want to delete this application?</p>
                  <div class="dialog-share-group__content__buttons">
                    <button class="dialog-share-group__content__buttons__add btn btn-blue-reverse" ng-click="deleteApp()">Delete</button>
                    <button class="dialog-share-group__content__buttons__cancel btn btn-blue" ng-click="cancel()">Cancel</button>
                  </div>
                </div>
              </div>`,
            },
            locals: {
              app,
              groupApplications: $scope.groupApps,
              group: $scope.group
            },
              controller: ['$scope', 'app', 'group', 'groupApplications', function ($scope, app, group, groupApplications) {

                $scope.deleteApp = function() {
                  try {
                    gudhub.groupSharing.deleteAppFromGroup(app.app_id, group.group_id, group.group_permission);
                    groupApplications.splice(groupApplications.indexOf(app), 1);
                    $scope.cancel();
                  } catch(error) {
                    console.log(error);
                  }
                }
              }]
              
          })
        }

        $scope.$watch('appName', async function(newVal, oldVal) {
          if(newVal && oldVal != newVal) {
            try {
              $scope.appName = '';
              let findedApp = $scope.groupApps.find(app => {
                return app.app_id == newVal;
              })
              if(!findedApp) {
                let newApp = await gudhub.groupSharing.addAppToGroup(newVal, $scope.group.group_id, 1);
                newApp.info = await gudhub.appProcessor.getAppInfo(newApp.app_id);
                $scope.groupApps.push(newApp);
                $scope.$digest();
              }
            } catch (err) {
              console.log(err);
            }
            
          }
        })

        $scope.updatePermissionToApp = async (e, app) => {
          
          if(e.target.parentElement.classList.contains('gh-radio-button_dropdown-item')) {
            try {
              await gudhub.groupSharing.updateAppInGroup(app.app_id, app.group_id, app.app_permission);
            } catch(err) {
              console.log(err)
            }
          }
        }

        $scope.userApps = gudhub.appProcessor.getAppListFromStorage();
        $scope.userApps = $scope.userApps.filter(app => {
          return app.permission == 3 || app.permission == 4;
        }).map(app => {
          app.name = app.app_name;
          app.value = app.app_id;
          return app;
        });
        
        $scope.groupApps = await gudhub.groupSharing.getAppsByGroup($scope.group.group_id);
        for(let app of $scope.groupApps) {
          app.info = await gudhub.appProcessor.getAppInfo(app.app_id);
        }
        $scope.$apply();
      }],

      template: /*html*/
      
      `<div class="add_member_wrapper"> 
        <button class="btn btn-blue add_member" ng-show="!showInput && (group.group_permission == 2 || group.group_permission == 3)" ng-click="showInput=true;"><span gh-icon="bold_plus fff 20px"></span>Add</button>
      </div>
      <div class="user_selecting-wrapper" ng-show="showInput">
        <div class="search" gh-icon="search 0D99FF 22px"></div>
        <gh-input-list gh-dropdown="userApps" gh-model="appName" ng-model="appName" gh-data-type="text_opt" size="small"></gh-input-list>
        <div class="close" ng-click="showInput = false;" gh-icon="cross 0D99FF 22px"></div>
      </div>
      <div class="listing_table">
        <div class="listing_table_head_wrapper">
          <div class="listing_table_column bottom_line w-50">
            <div class="tab">Name</div>
          </div>
          <div class="listing_table_column w-35">
            <div class="tab">Permission</div>
          </div>
          <div class="listing_table_column w-15">
            
          </div>
        </div>
        <div class="listing_table_body_wrapper" ng-style="overflow">
          <div class="listing_table_row" ng-repeat="app in groupApps">
            <div class="listing_table_column w-50">
              <span class="number">{{$index + 1}}</span>
              <span class="app_icon" gh-icon="{{app.info.icon.icon_id}} {{app.info.icon.icon_color}} 40px normal {{app.info.icon.gradient_up}} {{app.info.icon.gradient_down}}"></span>
              <div class="app_name">{{app.info.app_name}}</div>
            </div>
            <div class="listing_table_column w-35">
              <gh-radio-button class="gh-radio-button" ng-click="updatePermissionToApp($event, app)" gh-options="options" gh-model="app.app_permission" gh-mode="current_value" editable="group.group_permission == 2 || group.group_permission == 3  ? true : false"/> +
            </div>
            <div class="listing_table_column w-15">
              <span ng-if="(group.group_permission == 3 || group.group_permission == 2) && app.group_permission != 3" ng-click="showDeleteAppPopup(app)" class="delete_icon" gh-icon="rubbish 0d99ff 20px"></span>
            </div>

          </div>
          <div ng-if="!groupApps.length" class="listing_table_nodata">
            <p>No Applications</p>
          </div> 
        </div>
      </div>`
      
    }
  }]);
