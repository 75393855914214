angular.module('appCtrlMod', [
  'ghConstructor'
])

  /*==============================================================================================*/
  /*==============================   APP MANAGER CONTROLLER   ====================================*/
  /*==============================================================================================*/
  .controller('AppsCtrl', ['$route', '$location', '$routeParams', '$injector', '$q', 'GHConstructor', function ($route, $location, $routeParams, $injector, $q, GHConstructor) {

    GHConstructor.getInstance($routeParams.actionType).then(function (data) {
      var route_settings = {
        app_id: $routeParams.param_1,
        view_id: $routeParams.param_2,
        item_id: $routeParams.param_3
      };

      $injector.get('ghWindowsService').newWindow($routeParams.actionType, route_settings);

      // This need in preview mode in component
      if ($location.$$host === 'preview.gudhub.com' || $location.$$search.preview == 'true') {
        window.parent.postMessage('preview-loaded', '*');
      }
    });

  }]);
