angular.module('directive_input', [])

.directive('ghInputRange', [ '$compile', '$document', '$filter', function() {
    'use strict';
    return {
      scope: {
        ghModel: '=',
      },

      controller: [ '$scope', function($scope) {

        $scope.models = new Array(2);

        function setValue(args) {
          if (args[0] && args[1]) {
            $scope.ghModel = String(args[0]) + ':' + String(args[1]);
          }
        }

        $scope.$watchCollection('models', function(n,o) {
          if (n != o) {
            setValue($scope.models);
          }
        });

      }],
      template:
        `<gh-input ng-model="models[0]" gh-data-type="number" size="small"></gh-input>
        <gh-input ng-model="models[1]" gh-data-type="number" size="small"></gh-input>`,
    };
  }])

.directive('ghInputValue', [ function(){
    'use strict';
    return {
        scope: {
            ghModel: '='
        },
        controller: ['$scope', function($scope){
            console.log($scope.ghModel, "IN NEW DIRECTIVE");
        }],
        template:
            `<span>VOVA</span>`
    }
}])

.service('ghInputRegular', function() {

  this.checkRegularExp = function(regular, value) {
    // if(typeof regular == "undefined"){
    //   console.log("Reqular are undefined");
    //   regular = /^.*$/;
    // }

    var pattern = new RegExp(regular);
    var result = true;

    if (value && pattern && !pattern.test(value)) {
      result = false;
    }
    return result;
  };
});