angular.module('ghRadioButtonModule', [])

.directive('ghRadioButton', [function() {
    var directive = {};

    directive.scope = {
        ghModel: '=',
        ghOptions: '=',
        ghMode: '@',
        editable: '='
    };

    directive.controller = ['$scope', '$element', function($scope, $element) {
        function listenerCallback (e) {
            if(window.innerHeight - e.clientY < 150) {
                $scope.styles = {
                    top: 'auto',
                    bottom: '100%'
                }
                $scope.$apply();
            } else {
                $scope.styles = {
                    top: '100%',
                    bottom: 'auto'
                }
                $scope.$apply();
            }
            const dropdown = $element[0].querySelector('.gh-radio-button_dropdown');
            const item = document.querySelector('.gh-radio-button_dropdown-item');
            const button = $element[0].querySelector('.gh-button');
            if(e.target !== dropdown && e.target !== button && e.target !== item) {
                $scope.showDropdown = false; 
                $scope.$apply();
                window.removeEventListener('click', listenerCallback);
            }
        }
        
        $scope.changeValue = function(newVal) {
            window.addEventListener('click', listenerCallback)
            if ($scope.editable) {
                $scope.ghModel = newVal.toString();
            }
            if($scope.ghMode == 'current_value' && $scope.editable) {
                $scope.showDropdown = true;
            }
        };

        $scope.isSelected = function(val) {
            return $scope.ghModel == val;
        };
        $scope.hideDropdown = function(newVal) {
            $scope.changeValue(newVal);
            $scope.showDropdown = false;
            window.removeEventListener('click', listenerCallback);
        }

    }];

    directive.template =`
        <ul class="gh-radio-button_contain">
           <li ng-repeat="item in ghOptions | radioButtonValue : ghModel : ghMode" ng-click="changeValue(item.value)" class="gh-radio-button-item" ng-class="{\'selected\': isSelected(item.value), \'with-icon\': item.icon}">
             <div class="gh-button gh-button-inversed gh-color-grey gh-radio-button-item"  ng-style="isSelected(item.value) && !item.icon && {\'background-color\':item.color, \'border-color\':item.color, \'color\': \'white\', \'font-weight\': \'500\'} || { \'color\': item.color}">{{item.name}}</div>
           </li>
        <div class="gh-radio-button_dropdown" ng-if="showDropdown" ng-style="styles">
            <div class="gh-radio-button_dropdown-item" ng-repeat="item in ghOptions | radioButtonValue : ghModel: 'dropdown'">
                <div ng-click="hideDropdown(item.value)" class="gh-button gh-button-inversed gh-color-grey gh-radio-button-item" ng-style="isSelected(item.value) && !item.icon && {\'background-color\':item.color, \'border-color\':item.color, \'color\': \'white\', \'font-weight\': \'500\'} || { \'color\': item.color}">{{item.name}}</div>
            </div> 
        </div>
        </ul>`;

    return directive;
}])

.filter('radioButtonValue', function() {
    return function(options, currentValue, interpreterType) {
        if(interpreterType == 'dropdown') return options.filter(function(opt) {
            return Number(opt.value) != currentValue;
        });
        if (options.length && interpreterType == 'current_value') {
            return options.filter(function(opt) {
                return Number(opt.value) == currentValue;
            });
        } else {
            return options;
        }
    };
});