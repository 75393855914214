import Rete from "rete";
import { sockets } from './controls/sockets.js';
import { MainControl } from './controls/main_control.js';
import { ModuleControl } from "./controls/module_control.js";
import { NoEditControl } from './controls/no_edit_control.js';
import { Utilities } from './controls/utilities.js';
import { GUDHUB_SETTINGS } from "./../gudhub_settings.js";

export class AutomationInstanceCreator {

  constructor() {
    
  }

  static async createInstance(name, gudhub) {
    gudhub = gudhub ? gudhub : window.gudhub;
    const automationModulesList = gudhub.storage.getModulesList('automation');

    if(typeof window !== 'undefined') {

      try {
        const componentCreator = await import(/* webpackIgnore: true */automationModulesList.find(module => module.data_type === name).url);
        const componentClass = componentCreator.default(Rete, sockets, MainControl, NoEditControl, Utilities, { node_services_api_path: GUDHUB_SETTINGS.node_services_api_path }, ModuleControl);
        const component = new componentClass();
        return component;
      } catch(err) {
        console.log('Error while creating automation instance: ', name);
        console.log(err);
      }

    } else {

      try {
        const url = automationModulesList.find(module => module.data_type === name).url;
        const response = await gudhub.req.axiosRequest({ method: "GET", url });
        const code = response;
        let encodedCode = encodeURIComponent(code);
        encodedCode = 'data:text/javascript;charset=utf-8,' + encodedCode;

        const componentCreator = await import(/* webpackIgnore: true */encodedCode);
        const componentClass = componentCreator.default(Rete, sockets, MainControl, NoEditControl, Utilities, { node_services_api_path: GUDHUB_SETTINGS.node_services_api_path });
        const component = new componentClass();
        return component;
      } catch(err) {

        console.log('Error while creating automation instance: ', name);
        console.log(err);

      }

    }
  }

}