

/************************************************************************************************************|
|********************************************    GH CONSTRUCTOR    ******************************************|
|************************************************************************************************************|
|  ┌──────────────────────────────────────────────────────────────────────────────────────────────────────┐
|  │                 var constructor = GHConstructor.getInstance(constructor_type);                             │
|  └──────────────────────────────────────────────────────────────────────────────────────────────────────┘
|
|  GHConstructor could create action or field by running appropriate constuctor
|  For this moment GHConstructor four two types of constructors:
|   
|    'field' - This type of element is the most popular, it saves value in field_value (e.g. text, number, date, email, etc)
|    'action' - Action is not saving value it's just doing some actions (e.g. open_item, open_app, delete, etc)
|    'file' - is saving file_id in field_value then it also need to load file from server by this file_id
|    'json' - is saving document_id in field_value then it also need to load document from server by this document_id
|
|   
|    getTemplate: function() {
|     return{ 
|       constructor: 'file', // Could be: 'field', 'action', 'file', 'json'
|       private: false, // if 'private == true' then we are not showing this element in 'Template Editor' on a left bar
|       name: 'Quote tool', // this name is going to be shown in 'Template Editor' bar
|       icon: 'quoters', // we use this icon for 'Template Editor' bar
|       type: 'quote_tool', // it's element identificator
|       model: {...} // here we keep element's settings 
|     }
|  }
|  
|
|************************************************************************************************************|
*/

angular.module('ghConstructor', [])

/*=========================================================================================================*/
/*=================================================  ACTION CONSTRUCTOR   =================================*/
/*=========================================================================================================*/
.factory('GHConstructor', ['$q', '$location', '$injector', 'storage', '$ocLazyLoad', 'cnfg', 'ModulesList',function($q, $location, $injector, storage, $ocLazyLoad, cnfg, ModulesList) {
  var modules_path = {};

  angular.forEach(ModulesList.getModulesList(), function(module) {
    modules_path[module.name] = module.url;
  });

  //*****************  CONSTRUCTOR *******************//
  var constructor = async function (module_id) {
    let instance = await gudhub.ghconstructor.getInstance(module_id);
    return instance;
  };

  return {
    getInstance: constructor
  };
}]);
