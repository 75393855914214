angular.module('setViewTemplateModule', [])
.service('setViewTemplateService', [function() {

this.viewTemplates = [
  {
      title: 'Title Name', 
      icon: 'view_template_one',
      template: [{
        'allowed_types': ['gh_float'],
        'template_id': 'gh_flex',
        'name': '',
        'icon_id': '',
        'class': 'gh_flex gh_col_height_1 gh_height_px',
        'container_id': 0,
        'priority': 0,
        'content': [{
          'allowed_types': ['gh_float', 'gh_element', 'gh_tabs'],
          'template_id': 'gh_float',
          'name': '',
          'icon_id': '',
          'class': 'gh_float gh_col_width_12 gh_col_height_0 gh_height_percent gh_width_percent',
          'container_id': 0,
          'priority': 0,
          'content': []
        }]
      },{
        'allowed_types': ['gh_float'],
        'template_id': 'gh_flex',
        'name': '',
        'icon_id': '',
        'class': 'gh_flex gh_col_height_0 gh_height_percent vertical_scroll',
        'container_id': 0,
        'priority': 0,
        'content': [{
          'allowed_types': ['gh_float', 'gh_element', 'gh_tabs'],
          'template_id': 'gh_float',
          'name': '',
          'icon_id': '',
          'class': 'gh_float gh_col_width_12 gh_col_height_0 gh_height_percent gh_width_percent vertical_scroll',
          'container_id': 0,
          'priority': 0,
          'content': [{
              "allowed_types": ["gh_float", "gh_element", "gh_tabs", "gh_dropdown"],
              "template_id": "gh_float", 
              "name": "",
              "icon_id": "", 
              "class": "gh_float gh_col_width_12 gh_col_height_0 gh_height_percent gh_width_percent vertical_scroll",
              "container_id": 0,
              "priority": 0,
              "content": []
           }]
        }]
      }]
  },
  {
      title: 'Title Name',
      icon: 'view_template_two',
      template: [{
        'allowed_types': ['gh_float'],
        'template_id': 'gh_flex',
        'name': '',
        'icon_id': '',
        'class': 'gh_flex gh_col_height_1 gh_height_px',
        'container_id': 0,
        'priority': 0,
        'content': [{
          'allowed_types': ['gh_float', 'gh_element', 'gh_tabs'],
          'template_id': 'gh_float',
          'name': '',
          'icon_id': '',
          'class': 'gh_float gh_col_width_12 gh_col_height_0 gh_height_percent gh_width_percent',
          'container_id': 0,
          'priority': 0,
          'content': []
        }]
      },{
        'allowed_types': ['gh_float'],
        'template_id': 'gh_flex',
        'name': '',
        'icon_id': '',
        'class': 'gh_flex gh_col_height_0 gh_height_percent vertical_scroll',
        'container_id': 0,
        'priority': 0,
        'content': [{
          "allowed_types": ["gh_float", "gh_element", "gh_tabs", "gh_dropdown"],
          "template_id": "gh_float", 
          "name": "",
          "icon_id": "", 
          "class": "gh_float gh_col_width_12 gh_col_height_0 gh_height_percent gh_width_percent vertical_scroll",
          "container_id": 0,
          "priority": 0,
          "content": [{
            'allowed_types': ['gh_flex', 'gh_element', 'gh_tabs'],
            'template_id': 'gh_float',
            'name': '',
            'icon_id': '',
            'class': 'gh_float gh_col_width_6 gh_col_height_0 gh_height_percent gh_width_percent vertical_scroll',
            'container_id': 0,
            'priority': 0,
            'content': []
          },
          {
            'allowed_types': ['gh_float', 'gh_element', 'gh_tabs'],
            'template_id': 'gh_float',
            'name': '',
            'icon_id': '',
            'class': 'gh_float gh_col_width_6 gh_col_height_0 gh_height_percent gh_width_percent vertical_scroll',
            'container_id': 0,
            'priority': 0,
            'content': []
          }]
        }]
      }]
  },
  {
      title: 'Title Name', 
      icon: 'view_template_three',
      template: [{
        'allowed_types': ['gh_float'],
        'template_id': 'gh_flex',
        'name': '',
        'icon_id': '',
        'class': 'gh_flex gh_col_height_1 gh_height_px',
        'container_id': 0,
        'priority': 0,
        'content': [{
          'allowed_types': ['gh_float', 'gh_element', 'gh_tabs'],
          'template_id': 'gh_float',
          'name': '',
          'icon_id': '',
          'class': 'gh_float gh_col_width_12 gh_col_height_0 gh_height_percent gh_width_percent',
          'container_id': 0,
          'priority': 0,
          'content': []
        }]
      },{
        'allowed_types': ['gh_float'],
        'template_id': 'gh_flex',
        'name': '',
        'icon_id': '',
        'class': 'gh_flex gh_col_height_0 gh_height_percent vertical_scroll',
        'container_id': 0,
        'priority': 0,
        'content': [{
          "allowed_types": ["gh_float", "gh_element", "gh_tabs", "gh_dropdown"],
          "template_id": "gh_float", 
          "name": "",
          "icon_id": "", 
          "class": "gh_float gh_col_width_12 gh_col_height_0 gh_height_percent gh_width_percent vertical_scroll",
          "container_id": 0,
          "priority": 0,
          "content": [{
            'allowed_types': ['gh_float', 'gh_element', 'gh_tabs'],
            'template_id': 'gh_float',
            'name': '',
            'icon_id': '',
            'class': 'gh_float gh_col_width_4 gh_col_height_0 gh_height_percent gh_width_percent vertical_scroll',
            'container_id': 0,
            'priority': 0,
            'content': []
          },
          {
            'allowed_types': ['gh_float', 'gh_element', 'gh_tabs'],
            'template_id': 'gh_float',
            'name': '',
            'icon_id': '',
            'class': 'gh_float gh_col_width_4 gh_col_height_0 gh_height_percent gh_width_percent vertical_scroll',
            'container_id': 0,
            'priority': 0,
            'content': []
          },
          {
            'allowed_types': ['gh_float', 'gh_element', 'gh_tabs'],
            'template_id': 'gh_float',
            'name': '',
            'icon_id': '',
            'class': 'gh_float gh_col_width_4 gh_col_height_0 gh_height_percent gh_width_percent vertical_scroll',
            'container_id': 0,
            'priority': 0,
            'content': []
          }]
        }]
      }]
  },
  {
      title: 'Title Name',
      icon: 'view_template_four',
      template: [{
        'allowed_types': ['gh_float'],
        'template_id': 'gh_flex',
        'name': '',
        'icon_id': '',
        'class': 'gh_flex gh_col_height_1 gh_height_px',
        'container_id': 0,
        'priority': 0,
        'content': [{
          'allowed_types': ['gh_float', 'gh_element', 'gh_tabs'],
          'template_id': 'gh_float',
          'name': '',
          'icon_id': '',
          'class': 'gh_float gh_col_width_12 gh_col_height_0 gh_height_percent gh_width_percent',
          'container_id': 0,
          'priority': 0,
          'content': []
        }]
      },{
        'allowed_types': ['gh_float'],
        'template_id': 'gh_flex',
        'name': '',
        'icon_id': '',
        'class': 'gh_flex gh_col_height_0 gh_height_percent vertical_scroll',
        'container_id': 0,
        'priority': 0,
        'content': [{
          "allowed_types": ["gh_float", "gh_element", "gh_tabs", "gh_dropdown"],
          "template_id": "gh_float", 
          "name": "",
          "icon_id": "", 
          "class": "gh_float gh_col_width_12 gh_col_height_0 gh_height_percent gh_width_percent vertical_scroll",
          "container_id": 0,
          "priority": 0,
          "content": [{
            'allowed_types': ['gh_float', 'gh_element', 'gh_tabs'],
            'template_id': 'gh_float',
            'name': '',
            'icon_id': '',
            'class': 'gh_float gh_col_width_6 gh_col_height_0 gh_height_percent gh_width_percent vertical_scroll',
            'container_id': 0,
            'priority': 0,
            'content': []
          },
          {
            'allowed_types': ['gh_float', 'gh_element', 'gh_tabs'],
            'template_id': 'gh_float',
            'name': '',
            'icon_id': '',
            'class': 'gh_float gh_col_width_6 gh_col_height_0 gh_height_percent gh_width_percent vertical_scroll',
            'container_id': 0,
            'priority': 0,
            'content': []
          },{
              'allowed_types': ['gh_float', 'gh_element', 'gh_tabs'],
              'template_id': 'gh_float',
              'name': '',
              'icon_id': '',
              'class': 'gh_float gh_col_width_12 gh_col_height_0 gh_height_percent gh_width_percent',
              'container_id': 0,
              'priority': 0,
              'content': [] 
          }]
        }]
      }]
  },
  {
      title: 'Title Name', 
      icon: 'view_template_five',
      template: [{
        'allowed_types': ['gh_float'],
        'template_id': 'gh_flex',
        'name': '',
        'icon_id': '',
        'class': 'gh_flex gh_col_height_1 gh_height_px',
        'container_id': 0,
        'priority': 0,
        'content': [{
          'allowed_types': ['gh_float', 'gh_element', 'gh_tabs'],
          'template_id': 'gh_float',
          'name': '',
          'icon_id': '',
          'class': 'gh_float gh_col_width_12 gh_col_height_0 gh_height_percent gh_width_percent',
          'container_id': 0,
          'priority': 0,
          'content': []
        }]
      },{
        'allowed_types': ['gh_float'],
        'template_id': 'gh_flex',
        'name': '',
        'icon_id': '',
        'class': 'gh_flex gh_col_height_0 gh_height_percent vertical_scroll',
        'container_id': 0,
        'priority': 0,
        'content': [{
          "allowed_types": ["gh_float", "gh_element", "gh_tabs", "gh_dropdown"],
          "template_id": "gh_float", 
          "name": "",
          "icon_id": "", 
          "class": "gh_float gh_col_width_12 gh_col_height_0 gh_height_percent gh_width_percent vertical_scroll",
          "container_id": 0,
          "priority": 0,
          "content": [{
            'allowed_types': ['gh_float', 'gh_element', 'gh_tabs'],
            'template_id': 'gh_float',
            'name': '',
            'icon_id': '',
            'class': 'gh_float gh_col_width_6 gh_col_height_0 gh_height_percent gh_width_percent vertical_scroll',
            'container_id': 0,
            'priority': 0,
            'content': []
          },
          {
            'allowed_types': ['gh_float', 'gh_element', 'gh_tabs'],
            'template_id': 'gh_float',
            'name': '',
            'icon_id': '',
            'class': 'gh_float gh_col_width_6 gh_col_height_0 gh_height_percent gh_width_percent vertical_scroll',
            'container_id': 0,
            'priority': 0,
            'content': []
          },{
            'allowed_types': ['gh_float', 'gh_element', 'gh_tabs'],
            'template_id': 'gh_float',
            'name': '',
            'icon_id': '',
            'class': 'gh_float gh_col_width_6 gh_col_height_0 gh_height_percent gh_width_percent vertical_scroll',
            'container_id': 0,
            'priority': 0,
            'content': []
          },{
            'allowed_types': ['gh_float', 'gh_element', 'gh_tabs'],
            'template_id': 'gh_float',
            'name': '',
            'icon_id': '',
            'class': 'gh_float gh_col_width_6 gh_col_height_0 gh_height_percent gh_width_percent vertical_scroll',
            'container_id': 0,
            'priority': 0,
            'content': []
          }]
        }]
      }]
  },
  {
      title: 'Title Name',
      icon: 'view_template_six',
      template: [{
        'allowed_types': ['gh_float'],
        'template_id': 'gh_flex',
        'name': '',
        'icon_id': '',
        'class': 'gh_flex gh_col_height_1 gh_height_px',
        'container_id': 0,
        'priority': 0,
        'content': [{
          'allowed_types': ['gh_float', 'gh_element', 'gh_tabs'],
          'template_id': 'gh_float',
          'name': '',
          'icon_id': '',
          'class': 'gh_float gh_col_width_12 gh_col_height_0 gh_height_percent gh_width_percent',
          'container_id': 0,
          'priority': 0,
          'content': []
        }]
      },{
        'allowed_types': ['gh_float'],
        'template_id': 'gh_flex',
        'name': '',
        'icon_id': '',
        'class': 'gh_flex gh_col_height_0 gh_height_percent vertical_scroll',
        'container_id': 0,
        'priority': 0,
        'content': [{
          "allowed_types": ["gh_float", "gh_element", "gh_tabs", "gh_dropdown"],
          "template_id": "gh_float", 
          "name": "",
          "icon_id": "", 
          "class": "gh_float gh_col_width_12 gh_col_height_0 gh_height_percent gh_width_percent vertical_scroll",
          "container_id": 0,
          "priority": 0,
          "content": [{
            'allowed_types': ['gh_float', 'gh_element', 'gh_tabs'],
            'template_id': 'gh_float',
            'name': '',
            'icon_id': '',
            'class': 'gh_float gh_col_width_12 gh_col_height_0 gh_height_percent gh_width_percent vertical_scroll',
            'container_id': 0,
            'priority': 0,
            'content': []
          },{
            'allowed_types': ['gh_float', 'gh_element', 'gh_tabs'],
            'template_id': 'gh_float',
            'name': '',
            'icon_id': '',
            'class': 'gh_float gh_col_width_12 gh_col_height_0 gh_height_percent gh_width_percent',
            'container_id': 0,
            'priority': 0,
            'content': []
          }]
        }]
      }]
  },
  {
      title: 'Title Name',
      icon: 'view_template_seven',
      template: [{
        'allowed_types': ['gh_float'],
        'template_id': 'gh_flex',
        'name': '',
        'icon_id': '',
        'class': 'gh_flex gh_col_height_1 gh_height_px',
        'container_id': 0,
        'priority': 0,
        'content': [{
          'allowed_types': ['gh_float', 'gh_element', 'gh_tabs'],
          'template_id': 'gh_float',
          'name': '',
          'icon_id': '',
          'class': 'gh_float gh_col_width_12 gh_col_height_0 gh_height_percent gh_width_percent',
          'container_id': 0,
          'priority': 0,
          'content': []
        }]
      },{
        'allowed_types': ['gh_float'],
        'template_id': 'gh_flex',
        'name': '',
        'icon_id': '',
        'class': 'gh_flex gh_col_height_0 gh_height_percent vertical_scroll',
        'container_id': 0,
        'priority': 0,
        'content': [{
          "allowed_types": ["gh_float", "gh_element", "gh_tabs", "gh_dropdown"],
          "template_id": "gh_float", 
          "name": "",
          "icon_id": "", 
          "class": "gh_float gh_col_width_12 gh_col_height_0 gh_height_percent gh_width_percent vertical_scroll",
          "container_id": 0,
          "priority": 0,
          "content": [{
            'allowed_types': ['gh_float', 'gh_element', 'gh_tabs'],
            'template_id': 'gh_float',
            'name': '',
            'icon_id': '',
            'class': 'gh_float gh_col_width_4 gh_col_height_0 gh_height_percent gh_width_percent vertical_scroll',
            'container_id': 0,
            'priority': 0,
            'content': []
          },
          {
            'allowed_types': ['gh_float', 'gh_element', 'gh_tabs'],
            'template_id': 'gh_float',
            'name': '',
            'icon_id': '',
            'class': 'gh_float gh_col_width_4 gh_col_height_0 gh_height_percent gh_width_percent vertical_scroll',
            'container_id': 0,
            'priority': 0,
            'content': []
          },
          {
            'allowed_types': ['gh_float', 'gh_element', 'gh_tabs'],
            'template_id': 'gh_float',
            'name': '',
            'icon_id': '',
            'class': 'gh_float gh_col_width_4 gh_col_height_0 gh_height_percent gh_width_percent vertical_scroll',
            'container_id': 0,
            'priority': 0,
            'content': []
          },{
            'allowed_types': ['gh_float', 'gh_element', 'gh_tabs'],
            'template_id': 'gh_float',
            'name': '',
            'icon_id': '',
            'class': 'gh_float gh_col_width_6 gh_col_height_0 gh_height_percent gh_width_percent',
            'container_id': 0,
            'priority': 0,
            'content': []
          },
          {
            'allowed_types': ['gh_float', 'gh_element', 'gh_tabs'],
            'template_id': 'gh_float',
            'name': '',
            'icon_id': '',
            'class': 'gh_float gh_col_width_6 gh_col_height_0 gh_height_percent gh_width_percent',
            'container_id': 0,
            'priority': 0,
            'content': []
          }]
        }]
      }]
  },
  {
      title: 'Title Name',
      icon: 'view_template_eight',
      template: [{
        'allowed_types': ['gh_float'],
        'template_id': 'gh_flex',
        'name': '',
        'icon_id': '',
        'class': 'gh_flex gh_col_height_0 gh_height_percent vertical_scroll',
        'container_id': 0,
        'priority': 0,
        'content': [{
          'allowed_types': ['gh_float', 'gh_element', 'gh_tabs'],
          'template_id': 'gh_float',
          'name': '',
          'icon_id': '',
          'class': 'gh_float gh_col_width_12 gh_col_height_0 gh_height_percent gh_width_percent vertical_scroll',
          'container_id': 0,
          'priority': 0,
          'content': []
        }]
      }]
  }
]

this.initList = (list) => {
  this.list = list;
}

this.setViewTemplate = () => {

  this.viewTemplates[this.currentTemplate].template.forEach( template => {
    this.list.content.push(template);
  })
  
}


this.getViewTemplates = () => {
  return this.viewTemplates;
}

this.setCurrentViewTemplate = (index) => {
  this.currentTemplate = index;
}

this.getCurrentViewTemplate = () => {
  return this.viewTemplates[this.currentTemplate];
}

}]);