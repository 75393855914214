import { GUDHUB_SETTINGS } from "../../config.js";
import HelpLoader from "./HelpLoader.js";
class TooltipGenerator {
  constructor(items, linkToMd, elementTags) {
      this.items = items;
      this.linkToMd = linkToMd;
      this.elementTags = elementTags;
      this.slugs = [];
  }

  async setMarkdown() {
    // Need to get raw because this is full code of markdown
    this.md = await HelpLoader.getModule(this.elementTags, this.linkToMd.replace('/src/', '/raw/'));
  }

  getMarkdown() {
    return this.md;
  }

  findSlugInItem(item) {

    let parent = item.fields.find(field => {
      return field.field_id == GUDHUB_SETTINGS.app_help.field_id_parent;
    })

    const addSlugToList = () => {
      const slug = item.fields.find(field => field.field_id == GUDHUB_SETTINGS.app_help.field_id_slug);
      if(slug && slug.field_value) {
        this.slugs.push(slug.field_value);
      }
    }

    if(parent && parent.field_value) {
      addSlugToList();

      const itemParentId = parent.field_value.split('.')[1];
      const itemParent = this.items.find(item => item.item_id == itemParentId);
      
      this.findSlugInItem(itemParent);
    } else {
      addSlugToList();
      return;
    }

  }

  replaceLinks(currentItem) {

    // Get all links to other md files
    const linksArr = this.md.match(/\([^*|\)]*.md\)/g);
    const anchors = this.md.match(/\([^#|)]*#[^)]*\)/g);
    const baseUrl = 'https://gudhub.com/docs/';

    // Get slug of folder in every item with parent and push it to array
    if(linksArr) {

      linksArr.forEach(link => {
        this.slugs = [];
        let mdFileName = link.includes('/') ? link.slice(link.lastIndexOf('/'), link.length - 1) : link.slice(1, link.length - 1);
        let findedItem = this.items.find(item => {
          return item.fields.find(field => {
            return field.field_id == GUDHUB_SETTINGS.app_help.field_id_link && field.field_value.includes(mdFileName);
          })
        })

        if(findedItem) {
          this.findSlugInItem(findedItem);
        }

      this.md = this.md.replaceAll(link.slice(1, link.length - 1), baseUrl + this.slugs.reverse().join('/'));
      })
    }

    // Replace all anchors to full URL
    anchors.forEach(anchor => {
      this.slugs = [];
      this.findSlugInItem(currentItem);
      this.md = this.md.replaceAll(anchor, '(' + baseUrl + this.slugs.reverse().join('/') + anchor.slice(1, anchor.length));
    })
  }

  replaceImages() {
     // Get all images and replace relative path to full
     const imageLinks = this.md.match(/\([^ ]*\.(gif|jpe?g|tiff?|png|webp)/g);
     imageLinks.forEach(imageLink => {
      const baseImgUrl = 'https://gudhub.com/assets-website/images/';
      // Need add ( symbol for correct view in markdown
      const link = '(' + baseImgUrl  + this.md.substring(this.md.indexOf(imageLink) + 1, this.md.indexOf(imageLink) + imageLink.length).replaceAll('/', '-');
      this.md = this.md.replace(imageLink, link);
     });
  }

  isCommentsExist(nameSpace) {
    const commentRegExpStart = new RegExp(`<!--[^*]tooltip-start ${nameSpace} ?-->`);
    const commentRegExpEnd = new RegExp(`<!--[^*]tooltip-end ${nameSpace} ?-->`);
    this.commentStart = this.md.match(commentRegExpStart);
    this.commentEnd = this.md.match(commentRegExpEnd);
    return this.commentStart && this.commentEnd;
  }

  removeComments() {
    this.md = this.md.slice(this.md.indexOf(this.commentStart[0]) + this.commentStart[0].length, this.md.indexOf(this.commentEnd[0]))
  }

}
export default TooltipGenerator;
