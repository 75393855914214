export class Utilities {

  static ifNoConnections(outputs) {
    let numberOfConnections = 0;
    for (const prop in outputs) {
      numberOfConnections += outputs[prop].connections.length;
    }
    return numberOfConnections == 0;
  }

  static getConditionFunc(value_condition, value) {
    switch (value_condition) {
      case ">":
        return (condition) => this.valuesIsNumber([condition, value]) ?  Number(condition) > Number(value) :  condition > value;
      case "<":
        return (condition) =>  this.valuesIsNumber([condition, value]) ?  Number(condition) < Number(value) :  condition < value;
      case ">=":
        return (condition) =>  this.valuesIsNumber([condition, value]) ?  Number(condition) >= Number(value) :  condition >= value;
      case "<=":
        return (condition) =>  this.valuesIsNumber([condition, value]) ?  Number(condition)<= Number(value) : condition <= value;
      case "!=":
        return (condition) =>  this.valuesIsNumber([condition, value]) ?  Number(condition) != Number(value) : condition != value;
      case "==":
      default:
        return (condition) =>  this.valuesIsNumber([condition, value]) ?  Number(condition) == Number(value) : condition == value;
    }
  }

  static valuesIsNumber(values = []) {
    return values.every((value) => !Number.isNaN(Number(value)));
  }

  static isValueExists(input) {
    return input.length > 0 && input.some((inp) => {
      if (Array.isArray(inp)) {
        return inp.some((i) => i != undefined || i != null);
      } else {
        return inp != undefined || inp != null;
      }
    });
  }

  static createQueryString(queries = []) {
    return queries.length
      ? `?${queries.map((query) => query.join("=")).join("&")}`
      : "";
  }

}