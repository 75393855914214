angular.module('ghUserSelectingModule', [
  'authorizationMod'
])
.directive('ghUserSelecting', ['authService', '$compile', function(authService, $compile) {
  var directive = {};

  directive.scope = {
    ghModel: '=',
    options: '=?ghDropdown',
    ghField: '=?',
    ghMode: '@',
    editable: '='
  };

  directive.controller = [ '$scope', '$element', '$attrs', function($scope, $element, $attrs) {

    if (angular.isUndefined($scope.options)) $scope.options = [];

    $scope.settings = {
      photo_size: $scope.ghField.data_model && $scope.ghField.data_model.photo_size ? $scope.ghField.data_model.photo_size : '64px',
      display_type: $scope.ghMode || 'photo_full_name',
      url: ''
    };
    if($scope.ghField.data_model.photo_size == '100%') {
      $element[0].style.width = '100%'
    }

    $scope.userData = null;


  let firstlyLoaded = true;

    $scope.$watch('ghModel', async function(userId, oldUserId) {
      if (userId && (firstlyLoaded || userId != oldUserId)) {
        let user = userId;
        
        if(typeof userId !== 'number') {
          let users = userId.split(',');
            if(users.length > 1) {
              user = users[0];
            }
        }

        firstlyLoaded = false;
        $scope.userData = await authService.getUserById(user);
        let url = $scope.ghField.data_model.photo_size == '100%' ? $scope.userData.avatar_512 : $scope.userData.avatar_128;
        if (url) {
          $scope.settings.url = url.replace('http://gudhub.com', 'https://gudhub.com');
        } else {
          $scope.settings.url = null;
        }
      } else {
        $scope.userData = null;
        $scope.settings.url = null;
      }
      const timer = setTimeout(() => {
        $scope.$digest();
        clearTimeout(timer)
      }, 0)

    });

    $scope.deleteUser = function () {
      $scope.ghModel = "";
    };

  }];

    directive.link = function (scope, element, attrs) {
      var showInput = scope.editable ? ' <div>' +
      '   <gh-input ng-show="!ghField.field_value && !ghField.data_model.multiple_value" gh-field="ghField" ng-model="ghModel" autocomplete="usersList keyword" gh-dropdown="options" gh-data-type="user" size="large"></gh-input>' +
      '   <gh-input-list gh-editable="editable" add-button-name="Add user" gh-field="ghField" ng-if="ghField.data_model.multiple_value" autocomplete="usersList keyword" gh-model="ghField.field_value" gh-data-type="user" size="large" gh-multiple-selection="false"></gh-input-list>' +
      '   <span ng-show="ghModel && !ghField.data_model.multiple_value" ng-click="deleteUser()" ng-class="{\'small\': settings.photo_size.includes(64) || settings.photo_size.includes(128)}" class="edit-btn" gh-icon="cross 0893d2 30px normal"></span>'+
      ' </div>' : '';
      var template =
      '<div class="gh-user">' +
      ' <div ng-show="ghModel && !ghField.data_model.multiple_value" class="gh-user_wrapper" ng-switch="settings.display_type">' +
      '     <div ng-switch-when="photo_full_name" class="gh-photo_full_name">'+
      '       <div class="photo" ng-show="!settings.url" gh-icon="user_round 0fb5ff {{settings.photo_size || 30}} normal"></div>'+
      '       <img class="photo" ng-show="settings.url" style="width:{{settings.photo_size}}" ng-src="{{settings.url}}" />'+
      '       <p class="name">{{userData.fullname}}</p>'+
      '     </div>'+
      '     <div ng-switch-when="photo">'+
      '       <div ng-show="!settings.url" gh-icon="user_round 0fb5ff {{settings.photo_size}} normal"></div>'+
      '       <img class="photo" ng-show="settings.url" style="width:{{settings.photo_size}}" ng-src="{{settings.url}}" />'+
      '     </div>'+
      '     <div ng-switch-when="icon">'+
      '       <div class="photo" ng-show="settings.url" gh-icon="user_round 0fb5ff {{settings.photo_size}} normal"></div>'+
      '     </div>'+
      '     <div ng-switch-when="little_photo" class="gh-photo_full_name one_line">'+
      '       <div class="photo" ng-show="!settings.url" gh-icon="user_round 0fb5ff {{settings.photo_size || 15}} normal"></div>'+
      '       <img class="photo" ng-show="settings.url" style="width:40px" ng-src="{{settings.url}}" />'+
      '       <p class="name">{{userData.fullname}}</p>'+
      '     </div>'+
      '     <div ng-switch-when="custom_size" class="gh-photo_full_name one_line align_text_center">'+
      '       <div class="photo" ng-show="!settings.url" gh-icon="user_round 0fb5ff {{settings.photo_size || 15}} normal"></div>'+
      '       <img class="photo" ng-show="settings.url" style="width:{{settings.photo_size}}" ng-src="{{settings.url}}" />'+
      '       <p >{{userData.fullname}}</p>'+
      '     </div>'+
      '     <div ng-switch-default>'+
      '       <p class="name">{{userData.fullname}}</p>'+
      '     </div>'+
      ' </div>' + showInput +
      '</div>';

      var el = $compile(angular.element(template))(scope);
      element.append(el);
    };

  return directive;
}]);
