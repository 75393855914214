
    angular.module('ghDocumentRepeat', [])

        .directive('ghDocRepeat', ghDocRepeat);

    /*ghDocRepeat.$inject = [''];*/
    function ghDocRepeat() {
        /* Usage:*/
        /* For generate list of docs*/

        /* Creates:*/
        /* Create a list (like 'ng-repeat') of chosen items to generate and print the pdf document*/

        var directive = {
            restrict: 'A',
            transclude: 'element',
            compile: function(el, attr, linker) {

                return function(scope, $element, $attr) {

                    angular.forEach(scope.$eval($attr.ghDocRepeat).reverse(), function(fields) {
                        /* Create new scope for each document*/
                        var child = scope.$new();
                        /* Append item field values to isolate scope*/
                        angular.extend(child, fields);
                        /* Append element*/
                        linker(child, function(clone) {
                            $element.after(clone);
                        });
                    });
                };
            }
        };
        return directive;
    }
