class HelpLoader {
  constructor() {
      this.cache = {};
      this.modulesQueue = {};
  }

  async getModule(module_id, link) {
      if(this.getCached(module_id)) {
          return this.getCached(module_id);
      } else if (this.modulesQueue[module_id]) {
          await this.modulesQueue[module_id];
      } else {
          this.modulesQueue[module_id] = this.getMdData(module_id, link);
          await this.modulesQueue[module_id];
      }
      return this.getCached(module_id);
  }
  
  getCached(module_id) {
    return this.cache[module_id];
  }
  
  async getMdData(module_id, link) {
    const response = await fetch(link);
    const text = await response.text();
    this.cache[module_id] = text;

  }

}
export default new HelpLoader();
