require('./../config.js');

angular.module('sharingApiModule', [
  'authorizationMod',
  'config'
])

.service('sharing', [ 'sharingApi', function(sharingApi) {
  this.sharingAdd = function(appId, userId, permission) {
    return sharingApi.sharingAdd(appId, userId, permission);
  };

  this.sharingUpdate = function(userId, appId, permission) {
    return sharingApi.sharingUpdate(userId, appId, permission);
  };

  this.sharingDelete = function(userId, appId) {
    return sharingApi.sharingDelete(userId, appId);
  };

  this.getPermisionedUsers = function(appId) {
    return sharingApi.getPermisionedUsers(appId);
  };
}])

.factory('sharingApi', [ 'authService', '$http', 'cnfg', '$q', '$httpParamSerializerJQLike', function(authService, $http, cnfg, $q, $httpParamSerializerJQLike) {
  var sharingApiObject = {};

  sharingApiObject.sharingAdd = function(appId, userId, permission) {
    return authService.getToken().then(function(accessToken) {
      var requestConfig = {
        method: 'POST',
        url: cnfg.server_url + '/sharing/add',
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        data: $httpParamSerializerJQLike({
          token: accessToken,
          app_id: appId,
          user_id: userId,
          sharing_permission: permission
        })
      };
      return $http(requestConfig);
    });
  };

  sharingApiObject.sharingUpdate = function(userId, appId, permission) {
    return authService.getToken().then(function(accessToken) {
      var requestConfig = {
        method: 'POST',
        url: cnfg.server_url + '/sharing/update',
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        data: $httpParamSerializerJQLike({
          token: accessToken,
          user_id: userId,
          app_id: appId,
          sharing_permission: permission
        })
      };
      return $http(requestConfig);
    });
  };

  sharingApiObject.sharingDelete = function(userId, appId) {
    return authService.getToken().then(function(accessToken) {
      var requestConfig = {
        method: 'POST',
        url: cnfg.server_url + '/sharing/delete',
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        data: $httpParamSerializerJQLike({
          token: accessToken,
          user_id: userId,
          app_id: appId
        }),
        timeout: 2500
      };
      return $http(requestConfig);
    });
  };

  sharingApiObject.getPermisionedUsers = function(appId) {
    return authService.getToken().then(function(accessToken) {
      var requestConfig = {
        method: 'POST',
        url: cnfg.server_url + '/sharing/get-app-users',
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        data: $httpParamSerializerJQLike({
          token: accessToken,
          app_id: appId,
        })
      };
      return $http(requestConfig);
    });
  };

  return sharingApiObject;
}]);
