angular.module('fileManagerModule', [])

/*========================================================================================================*/
/*===========================================  FILE MANAGER  =============================================*/
/*========================================================================================================*/
/*this is the main method where we initiate data exchanging between server and client*/

.service('fileManager', [ function() {

  return gudhub.fileManager;

  //var log = cnfg.log.fileManager; /*- show/hide console output*/


  // /*------------------------- UPLOAD FILE FROM STRING  ----------------------*/
  // this.uploadFileFromString = function(fileObject) {
  //   return fileApi.uploadFileFromString(fileObject).then(function(response) {
  //     fileStorage.addFileToStorage(response.app_id, response);
  //     return response;
  //   });
  // };

  // /*------------------------- UPDATE FILE FROM STRING  ----------------------*/
  // this.updateFileFromString = function(data, id, name, ext, format) {
  //   return fileApi.updateFileFromString(data, id, name, ext, format).then(function(response) {
  //     fileStorage.updateFileInStorage(response.data.file_id, response.data.app_id, response.data);
  //     return response;
  //   });
  // };

  // /*------------------------- UPLOAD FILE  ----------------------*///!!!!!!!!!!!!!! This method is not used
  // this.uploadFile = function(fileData, appId, itemId) {
  //   return fileApi.uploadFile(fileData, appId, itemId).then(function(response) {
  //     fileStorage.addFileToStorage(response.app_id, response);
  //     return response;
  //   }, function() {
  //     return $q.reject();
  //   });
  // };


  // /*------------------------- DUPLICATE FILE  ----------------------*/
  // this.duplicateFile = function(files) {
  //   return fileApi.duplicateFile(files).then(function(response) {
  //     console.log("duplicateFile1", response);
  //     response.forEach(fileObj => {
  //         fileStorage.addFileToStorage(fileObj.app_id, fileObj);
  //     });
  //     return response;
  //   });
  // };



  // /*----------------------------------- GET FILE INFO -------------------------------*/
  // this.getFile = function(appId, fileId) {
  //   return fileApi.getFile(appId, fileId).then(function(response) {
  //     return response;
  //   }, function () {
  //     return $q.reject();
  //   });
  // };


	// /*--------------------------------- DOWNLOAD FILE -------------------------------*/
	// this.downloadFileFromString = function(appId, fileId) {
	// 	var self = this;

	// 	return this.isFileExists(appId, fileId).then(function(result) {
	// 		if (result) {
	// 			var file;
	// 			return self.getFile(appId, fileId).then(function(result) {
	// 				file = result;
	// 				return $http({
	// 					method: 'GET',
	// 					url: result.url + '?timestamp=' + result.last_update,
	// 					responseType: 'text/html'
	// 				});
	// 			}).then(function(response) {

	// 				return $q.when({
	// 					file: file,
	// 					data: response.data,
	// 					type: 'file'
	// 				});
	// 			}, function(response) {
	// 			});
	// 		} else {
	// 			var blobInstance = new Blob(['<b>Click to add note...</b>'], {
	// 				type: 'text/html'
	// 			});
	// 			var url = $window.URL.createObjectURL(blobInstance);
	// 			return $q.when({
	// 				url: url,
	// 				data: '',
	// 				type: 'blob'
	// 			});
	// 		}
	// 	});
	// };

	// this.getDeserializedObjectByFileID = async (appId, fileId) => {
  //   let fileDescriptor = await this.getFile(appId, fileId);

  //   return $http({
  //     method: 'GET',
  //     url: `${fileDescriptor.url}?${fileDescriptor.last_update}`
  //   });
  // };

  // /*------------------ DOWNLOAD FILE DATA ---------------*/
  // this.downloadFileData = function(url) {
  //   return fileApi.downloadFileData(url).then(function(response) {
  //     return response;
  //   }, function(err) {
  //     return $q.reject(err);
  //   });
  // };


  // /*------------------ CHECK IF FILE IS ON SERVER ---------------*/
  // //-- Check whether there is a file on the server
	// this.isFileExists = function(appID, fileID) {
	// 	var urlPattern = /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/;
	// 	if (angular.isDefined(fileID) && fileID !== null) {
	// 		return this.getFile(appID, fileID).then(function(result) {
	// 			return $q.when(urlPattern.test(result.url));
	// 		});
	// 	} else {
	// 		return $q.when(false);
	// 	}
	// };


  // /*------------------ GET FILES ---------------*/
  // this.getFiles = function(appId, filesId) {
  //   return fileApi.getFiles(appId, filesId).then(function(response) {
  //     return response;
  //   });
  // };


  // /*------------------ DELETE FILE --------------- */
  // this.deleteFile = function(id, appId) {
  //   if (!id || !appId) return Promise.resolve();
  //   return fileApi.deleteFile(id, appId).then(function() {
  //     fileStorage.deleteFileFromStorage(id, appId);
  //     return $q.when();
  //   }).catch(() => console.log("File is not exist on server"))
  // };


  // /*--------------------- CHECK IF ELEMENT IS FILE ---------------------*/
  // this.checkIfElementIsFile = function(app_id, field_id) {
  //   return new Promise(resolve => {
  //     let model_address = {app_id:app_id, field_id:field_id}

  //     PipeService.on('gh_model_get', model_address, function itemPipe(event, fieldModel) {
  //       GHConstructor.getInstance(fieldModel.data_type).then(function(data) {
  //         PipeService.destroy('gh_model_get', model_address, itemPipe);
  //         resolve (data.getTemplate().constructor == 'file') //-- if it's file we resolve 'true' for filter
  //       });
  //     }).emit('gh_model_get', {}, model_address);
  //   })
  // }


  // /*--------------------- FILE INSTALLER HELPER ---------------------*/
  // //-- We use this method for to dublication file in items
  // this.fileInstallerHelper = function (appId, items, elementId) {
  //   const files = [[]];
  //   const deferred = $q.defer();
  //   let chainPromises = Promise.resolve();
  //   let results = [];

  //   angular.forEach(items, item => {
  //     const itemId = item.item_id;
  //     const field = item.fields.find(field => field.element_id === elementId);

  //     if (field && field.field_value) {
  //       const fileIds = field.field_value.split(',');

  //       angular.forEach(fileIds, fileId => {
  //         const newFile = {
  //           source: fileId,
  //           destination: {
  //             app_id: appId,
  //             item_id: itemId,
  //             element_id: elementId
  //           }
  //         };

  //         if (files[files.length - 1].length !== cnfg.maxDuplicatedFiles) {
  //           files[files.length - 1].push(newFile)
  //         } else {
  //           files.push([newFile]);
  //         }
  //       });
  //     }
  //   });

  //   files.forEach(filePack => {
  //     chainPromises = chainPromises
  //       .then(() => {
  //         return this.duplicateFile(filePack);
  //       })
  //       .then(result => {
  //         results = [
  //           ...results,
  //           ...result
  //         ];
  //       });
  //   });

  //   chainPromises.then(() => {
  //     // UPDATE FILE VALUE
  //     angular.forEach(items, item => {
  //       const itemId = item.item_id;
  //       const field = item.fields.find(field => field.element_id === elementId);

  //       if (field && field.field_value) {
  //         field.field_value = '';
  //         results.forEach(file => {
  //           if (file.item_id === itemId) {
  //             field.field_value = field.field_value.split(',').filter(fileId => fileId).concat(file.file_id).join(',');
  //           }
  //         });
  //       }
  //     });

  //     deferred.resolve(items);
  //   });

  //   return deferred.promise;
  // };




  // /*------------------------- DOWNLOAD FILE TO HARD DISK ----------------------*/
  // this.downloadFile = function(file){
  //   DownloadFileService.save(file);
  // };


  // /*------------------------------------END--------------------------------------*/
}])





// /*========================================================================================================*/
// /*===========================================  FILE API  =================================================*/
// /*========================================================================================================*/
// /**
//  * EXAMPLE:
//  * files = [
//  *  {
//  *    source: 7777,
//  *    destination: {
//  *      app_id: 888,
//  *      item_id: 999,
//  *      element_id: 333
//  *    }
//  *  },
//  *  {....},
//  *  {....}
//  * ];
//  */
// .factory('fileApi', [ 'cnfg', 'authService', '$http', '$q', 'appDataProcesingService', 'fileStorage', '$httpParamSerializerJQLike', 'PipeService', function(cnfg, authService, $http, $q, appDataProcesingService, fileStorage, $httpParamSerializerJQLike, PipeService) {
//   return {



//       duplicateFile : function(files = []) {
//         var deferred = $q.defer();

//         authService.getToken().then(function(accessToken) {
//           var requestConfig = {
//             method: 'POST',
//             url: cnfg.server_url + '/api/new/file/duplicate',
//             headers: {'Content-Type': 'application/x-www-form-urlencoded'},
//             data: $httpParamSerializerJQLike({
//               token: accessToken,
//               files: angular.toJson(files)
//             })
//           };

//           $http(requestConfig).then(function (response) {
//             //-- Add file to starage
//             try {
//               deferred.resolve(response.data);
//             } catch (e) {
//               deferred.reject();
//               console.log("fileApi:duplicateFile FILE is not updated");
//             }
//           });

//         });

//         return deferred.promise;
//       },




//       deleteFile : function(id, appId) {
//         return authService.getToken().then(function(accessToken) {
//           var baseUrl = cnfg.server_url;
//           var requestUrl = baseUrl + '/file/delete';
//           return $http.get(requestUrl, {
//             params: {
//               token: accessToken,
//               file_id: Number(id)
//             }
//           });
//         })
//       },



//       getFiles : function(appId, filesId) {
//         var deferred = $q.defer();
//         var files = [];

//         appDataProcesingService.getApp(appId, "", "fileApi->getFiles").then(function(result) {
//           if(result) {
//              var res = result.file_list.filter(function (appFile) {
//                return filesId.some(function (id) {
//                  return id == appFile.file_id;
//                });
//              });
//              deferred.resolve(res);
//            }
//         });
//         return deferred.promise;
//       },


//       getFile : function(appId, fileId) {
//         var deferred = $q.defer();

//         appDataProcesingService.getApp(appId, "", "fileApi->getFile").then(function(result) {
//           if(result) {
//             var finded = null;
//             angular.forEach(result.file_list, function (file, index) {
//               if (file.file_id == fileId) {
//                 finded = file;
//               }
//             });
//             if (finded) {
//               deferred.resolve(finded);
//             } else {
//               deferred.reject();
//             }
//           }else {
//             deferred.reject();
//           }
//         });

//         return deferred.promise;
//       },


//       uploadFile : function(fileData, appId, itemId) {
//         var deferred = $q.defer();

//         /*-- Getting token from server*/
//         authService.getToken().then(function(accessToken) {
//           /*console.log("uploadFile", event, appId, itemId );*/
//           var baseUrl = cnfg.server_url;
//           var requestUrl = baseUrl + '/file/formupload';
//           var fileObj = null;

//           /* POST*/
//           var request = new XMLHttpRequest();
//           request.open('POST', requestUrl, /* async = */ false);

//           request.onerror = function() {
//             deferred.reject();
//           };

//           /*-- Appending parameters to the Request*/
//           var formData = new FormData();
//           formData.append('token', accessToken);
//           formData.append('app_id', appId);
//           formData.append('item_id', itemId);
//           formData.append('file-0', fileData);

//           /*-- Sending Request to server ( it will not go further once response is received)*/
//           request.send(formData);


//           try {
//             fileObj = angular.fromJson(request.response)[0]; /*-- server return array with one element*/
//             deferred.resolve(fileObj);
//           } catch (e) {
//             deferred.reject();
//           }

//         });

//         return deferred.promise;
//       },

//       // =================================== UPLOAD FILE FROM STRING ===================================================
//       /*
//       * {
//       *   format: '', ('base64', 'text', 'html')
//       *   source: '', (file data)
//       *   file_name: '', (filename without extension)
//       *   extension: '', ('txt', 'jpeg', ect.)
//       *   app_id: '',
//       *   item_id: '',
//       * }
//       *
//       * */
//       uploadFileFromString : function(fileObject) {
//         var deferred = $q.defer();

//         var fileObj;
//         authService.getToken().then(function(accessToken) {

//           var requestConfig = {
//             method: 'POST',
//             url: cnfg.server_url + '/file/upload',
//             headers: {'Content-Type': 'application/x-www-form-urlencoded'},
//             data: $httpParamSerializerJQLike({
//               token: accessToken,
//               file: angular.toJson(fileObject)
//             })
//           };

//           $http(requestConfig).then(function (response) {
//             deferred.resolve(response.data);
//           }, function (err) {
//             deferred.reject();
//           });
//         });

//         return deferred.promise;
//       },

//       updateFileFromString : function(data, id, name, ext, format) {  
//         return authService.getToken().then(function(accessToken) {
//           var fileObject = {
//             format: format,
//             source: data,
//             file_name: name,
//             extension: ext,
//             file_id: id
//           };

//           var requestConfig = {
//             method: 'POST',
//             url: cnfg.server_url + '/file/update',
//             headers: {'Content-Type': 'application/x-www-form-urlencoded'},
//             data: $httpParamSerializerJQLike({
//               token: accessToken,
//               file: angular.toJson(fileObject)
//             })
//           };

//           return $http(requestConfig);
//         }).then(function(response) {
//           return $q.when(response);
//         }, function(response) {

//         });
//       },

//       downloadFileData: function(url) {
//         return $http.get(url, {responseType: 'blob'}).then(function(response) {
//           return response;
//         }, function(err) {
//           return $q.reject(err);
//         });
//       }
//     };
// }])


// /*--------------------------------------------------------------------------*/
// /*------------------------- DOWLOAD FILE TO HARD DISK ----------------------*/
// /*--------------------------------------------------------------------------*/

// .service('DownloadFileService', ['$timeout', function($timeout){

//   var showSave;
//   var DownloadAttributeSupport = 'download' in document.createElement('a');

//   // BlobBuilder/URL implementation
//   var URL = window.URL || window.webkitURL || window.mozURL || window.msURL;
//   // IE 10 has a handy navigator.msSaveBlob method
//   navigator.saveBlob = navigator.saveBlob || navigator.msSaveBlob || navigator.mozSaveBlob || navigator.webkitSaveBlob;
//   //Anyway, HMTL5 defines a very similar but more powerful window.saveAs function
//   window.saveAs = window.saveAs || window.webkitSaveAs || window.mozSaveAs || window.msSaveAs;

//   // Mime types that (potentially) don't trigger a download when opened in a browser
//   var BrowserSupportedMimeTypes = {
//    "image/jpeg": true,
//    "image/png": true,
//    "image/gif": true,
//    "image/svg+xml": true,
//    "image/bmp": true,
//    "image/x-windows-bmp": true,
//    "image/webp": true,
//    "audio/wav": true,
//    "audio/mpeg": true,
//    "audio/webm": true,
//    "audio/ogg": true,
//    "video/mpeg": true,
//    "video/webm": true,
//    "video/ogg": true,
//    "text/plain": true,
//    "text/html": true,
//    "text/xml": true,
//    "application/xhtml+xml": true,
//    "application/json": true
//   };

//   // List of mime types by extension
//   var extensionMimeTypes = {
//     'html': 'text/html',
//     'htm': 'text/html',
//     'zip': 'application/zip',
//     'gz': 'application/x-gzip',
//     'xls': 'application/vnd.ms-excel',
//     'xlm': 'application/vnd.ms-excel',
//     'xlc': 'application/vnd.ms-excel',
//     'xla': 'application/vnd.ms-excel',
//     'xlsx': 'application/vnd.ms-excel',
//     'txt': 'text/plain',
//     'tiff': 'image/tiff',
//     'tif': 'image/tiff',
//     'svg': 'image/svg+xml',
//     'rtf': 'application/rtf',
//     'ppt': 'application/vnd.ms-powerpoint',
//     'pps': 'application/vnd.ms-powerpoint',
//     'pot': 'application/vnd.ms-powerpoint',
//     'ppm': 'image/x-portable-pixmap',
//     'pdf': 'application/pdf',
//     'mp2': 'video/mpeg',
//     'mp3': 'video/mpeg',
//     'mpa': 'video/mpeg',
//     'mpeg': 'video/mpeg',
//     'mpg': 'video/mpeg',
//     'jpg': 'image/jpeg',
//     'jpeg': 'image/jpeg',
//     'jpe': 'image/jpeg',
//     'doc': 'application/msword',
//     'docx': 'application/msword',
//     'dot': 'application/msword',
//     'bmp': 'image/bmp',
//     'gif': 'image/gif',
//     'json': 'application/json',
//     'xml': 'application/xhtml+xml'
//   };

//   // Create new blob object
//   function NewBlob(data, datatype) {
//     var out;

//     try {
//       console.log(datatype);
//       out = new Blob([data], {
//         type: datatype
//       });
//     } catch (e) {
//       window.BlobBuilder = window.BlobBuilder ||
//         window.WebKitBlobBuilder ||
//         window.MozBlobBuilder ||
//         window.MSBlobBuilder;

//       if (e.name == 'TypeError' && window.BlobBuilder) {
//         var builder = new BlobBuilder();
//         builder.append(data);
//         out = builder.getBlob(datatype || "application/octet-stream");
//       } else if (e.name == "InvalidStateError") {
//         // InvalidStateError (tested on FF13 WinXP)
//         out = new Blob([data], {
//           type: datatype
//         });
//       } else {
//         // We're screwed, blob constructor unsupported entirely
//         console.debug("Error downloading file!");
//       }
//     }
//     return out;
//   }

//   (function(){
//     if (navigator.saveBlob) {

//      showSave = function (data, name, mimetype) {
//        var blob = NewBlob(data, mimetype);
//        if(blob){
//          if (!name) name = "Download.bin";
//          if (window.saveAs) {
//            window.saveAs(blob, name);
//          } else {
//            navigator.saveBlob(blob, name);
//          }
//        }
//      };

//     } else if(URL){

//       showSave = function (data, name, mimetype) {

//         if (!mimetype) mimetype = "application/octet-stream";
//         var blob = NewBlob(data, mimetype), url;

//         // Currently only Chrome (since 14-dot-something) supports the download attribute for anchor elements.
//         if (DownloadAttributeSupport) {

//           url = URL.createObjectURL(blob);
//           var link = document.createElement("a");
//           link.setAttribute("href",url);
//           link.setAttribute("download", name || "Download.bin");

//           var event = document.createEvent('MouseEvents');
//           event.initMouseEvent('click', true, true, window, 1, 0, 0, 0, 0, false, false, false, false, 0, null);
//           link.dispatchEvent(event);

//         } else {

//           if (BrowserSupportedMimeTypes[mimetype.split(";")[0]] === true) {
//             mimetype = "application/octet-stream";
//           }

//           url = URL.createObjectURL(blob);
//           window.open(url, '_blank', '');
//         }

//         $timeout(function () {
//          URL.revokeObjectURL(url);
//         }, 250);
//       };

//     } else if (!/\bMSIE\b/.test(navigator.userAgent)) {

//       showSave = function (data, name, mimetype) {

//         if (!mimetype) mimetype = "application/octet-stream";
//         // Again I need to filter the mime type so a download is forced.
//         if (BrowserSupportedMimeTypes[mimetype.split(";")[0]] === true) {
//           mimetype = "application/octet-stream";
//         }
//         window.open("data:"+mimetype+","+encodeURIComponent(data), '_blank', '');
//      };

//     }

//   }());


//   this.save = function(file){
//     var xhr = new XMLHttpRequest();
//     xhr.open('GET', `${file.url}?timestamp=${file.last_update}`, true);
//     xhr.responseType = 'arraybuffer';

//     xhr.onload = function(e) {
//       if (this.status == 200) {
//         showSave(xhr.response, file.file_name + '.' + file.extension, extensionMimeTypes[file.extension]);
//       }
//       xhr = null;
//     };
//     xhr.send();

//   };

//   return this;
// }])






// /*=======================================================================================================*/
// /*==================================  UPDATE FILE IN MAIN STORAGE  ======================================*/
// /*=======================================================================================================*/
// .factory('fileStorage', [ '$q', '$http', '$location', 'cnfg', 'storage', 'PipeService', function($q, $http, $location, cnfg, storage, PipeService) {
//   var log = cnfg.log.fileStorage; /*- show/hide console output*/

//   return {



//     /*------------------------------- ADD FILE TO STORAGE --------------------------------------*/
//     addFileToStorage: function(appId, file) {
//       var deferred = $q.defer();

//       /*-- getting app from storage*/
//       const app = storage.getApp( appId );
//         /*-- pushing file to the app*/
//         app.file_list.push(file);
//         //PipeService.emit('gh_file_add', {app_id:appId, item_id:item.item_id, field_id:field.field_id}, field.field_value);
//         deferred.resolve();

//       log ? console.log('STORAGE: file was added') : undefined;
//       return deferred.promise;
//     },

//     addFilesToStorage: function(appId, files) {
//       var deferred = $q.defer();

//       /*-- getting app from storage*/
//       const app = storage.getApp( appId );
//         /*-- pushing file to the app*/

//         app.file_list.push(...files);
//         //PipeService.emit('gh_file_add', {app_id:appId, item_id:item.item_id, field_id:field.field_id}, field.field_value);
//         deferred.resolve();
      

//       log ? console.log('STORAGE: file was added') : undefined;
//       return deferred.promise;
//     },



//     /*------------------------------- DELETE FILE FROM STORAGE --------------------------------------*/
//     deleteFileFromStorage: function(id, appId) {
//       const app = storage.getApp( appId );
//         angular.forEach(app.file_list, function(file) {
//           if (file.file_id == id) {
//             app.file_list.splice(app.file_list.indexOf(file), 1);
//             PipeService.emit('gh_file_delete', {file_id: id}, file);
//           }
//         });
//     },


//     /*------------------------------- UPDATE FILE IN STORAGE --------------------------------------*/
//     updateFileInStorage: function(fileId, appId, file) {
//       const app = storage.getApp( appId );
//         angular.forEach(app.file_list, function(storageFile) {
//           if (storageFile.file_id == fileId) {
//             console.log('last_update', storageFile.last_update, file.last_update);
//             storageFile.last_update = file.last_update;
//             PipeService.emit('gh_file_update', {file_id: fileId}, /*doesn't send anything - file id still the same*/);
//           }
//         });
//     }
//   };



// }]);
