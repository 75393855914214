require("./../config.js");

angular
  .module("ModulesListModule", ["config"])

  /*=======================================================================================================*/
  /*===============================================   MODULES STORAGE   ======================================*/
  /*=======================================================================================================*/
  .service("ModulesList", [
    "cnfg",
    function(cnfg) {
      this.getModulesList = function() {
        return gudhub.storage.getModulesList();
      }
    }
  ]);
