angular.module("ghFilterSearchModule", []).directive("ghFilterSearch", [
  function () {
    return {
      scope: {
        ghModel: "=",
        fieldId: "=",
        appId: "=",
      },
      controller: [
        "$scope",
        "filterItem",
        "PipeService",
        async function ($scope, filterItem, PipeService) {
          $scope.ghModel = $scope.ghModel || {};

          async function getFilterModel() {
            if ($scope.appId && $scope.fieldId) {
              $scope.field = await getField({
                app_id: $scope.appId,
                field_id: $scope.fieldId, 
              });
              $scope.filterModel = await filterItem.getNewFilter($scope.field);
            }
          }

          await getFilterModel();

          $scope.$watch("appId", async () => {
            await getFilterModel();
          });

          $scope.$watch("fieldId", async () => {
            await getFilterModel();
          });
          
          $scope.setSearchOption = function (option) {
            $scope.ghModel = {
              data_type: $scope.field.data_type,
              field_id: $scope.field.field_id,
              search_type: option.id,
              selected_search_option_variable: option.name,
              valuesArray: [],
            };
          };

          function getField(address) {
            return new Promise((resolve) => {
              PipeService.on("gh_model_get", address, function fieldPipe(
                event,
                field
              ) {
                resolve(field);
                PipeService.destroy("gh_model_get", address, fieldPipe);
              }).emit("gh_model_get", {}, address);
            });
          }
        },
      ],
      template: `<div layout="column" ng-class="{open: show}" ng-init="show = true" class="filter-content">
            <div layout="row" style="position: relative">
              <span ng-init="dropdownShow = false" class="gh-dropdown">
                <span class="gh" ng-click="dropdownShow = !dropdownShow" gh-icon="bot_arrow 0893d2 22px normal" style="margin-top: 5px;"></span>
                  <ul ng-show="dropdownShow" class="display-block">
                    <li ng-repeat="option in filterModel.search_options" ng-click="setSearchOption(option); $parent.dropdownShow = false">
                       <p>{{option.name}}</p>
                    </li>
                  </ul>
                </span>
              <span layout="column" ng-click="dropdownShow = !dropdownShow" layout-alling="start center" layout-margin="5">{{ghModel.selected_search_option_variable || "Select Search Type"}}</span>
            </div>
        </div>`,
    };
  },
]);
