require("./gh_loader.scss");

angular
  .module("loaderModule", [])

  // COLOR LOADER
  // $blue: #0893d2;
  // $light-blue: #EDF2F6;
  // $sky-blue: #ddf0f9;
  // $green: #10cfbd;
  // $red: #FF3333;
  // $pure-white: #fff;
  // $white: #fafdfd;
  // $black: #575757;
  // $light-grey: #e7e7ee;
  // $grey: #d9d9d9;
  // $dark-grey: #bbbcc5;

  .directive("ghLoader", [
    "$compile",
    function($compile) {
      return {
        replace: false,
        restrict: "E",
        scope: {
          color: "@",
          type: "@?",
          appId: "=?",
          icon: "=?"
        },

        link: async function(scope, element, attrs) {
          let template;
          switch (scope.type) {
            case "dots":
              template = `<div class="wrap_loader_dots">
                            <div class="loading">
                              <div class="bounceball {{color}} one"></div>
                              <div class="bounceball {{color}} two"></div>
                              <div class="bounceball {{color}} three"></div>
                            </div>
                          </div>`;
              break;
            
            case "dual-ring":
                template = `<div class="wrap_loader_dots">
                                <div class="lds-dual-ring {{color}}"></div>
                            </div>`;
              break;
            
            case "app":
              if(scope.appId) {
              
                scope.appInfo = await gudhub.appProcessor.getAppInfo(scope.appId);
                template = `<div class="wrap_loader_dots">
                              <span class="hide app_loader" gh-icon="${scope.appInfo.icon.icon_id} ${scope.appInfo.icon.icon_color} 150px app ${scope.appInfo.icon.gradient_up} ${scope.appInfo.icon.gradient_down}"></span>
                            </div>`;
              } else {
                template = `<div class="wrap_loader_dots">
                              <span class="hide app_loader" gh-icon="${scope.icon.icon_id} ${scope.icon.icon_color} 150px app ${scope.icon.gradient_up} ${scope.icon.gradient_down}"></span>
                            </div>`;
              }
            break;

            default:
              template = `<div class="wrap_loader_dots">
                                <div class="lds-dual-ring {{color}}"></div>
                         </div>`;
          }

          const elm = angular.element(template);
          const compiled = $compile(elm);
          element.empty();
          element.append(elm);

          if(scope.type == 'app') {

            //animation for app loader
            const canvas = document.createElement('canvas');
            const context = canvas.getContext("2d");
            const animationSpeed = 1;

            const generatePieces = (rows, columns, canvasWidth, canvasHeight) => {
            const pieces = [];
            const cellWidth = canvasWidth / columns;
            const cellHeight = canvasHeight / rows;
              for(let r = 0; r < rows; r++) {
                for(let c = 0; c < columns; c++) {
                  pieces.push({
                    x: cellWidth * c,
                    y: 1000,
                    targetY: cellHeight * r,
                    width: cellWidth,
                    height: cellHeight
                  })
                }
              }
              return pieces;
            }

            //we need use timeout because we don't have element in DOM
            setTimeout(() => {
              init();
            }, 100)

            const init = () => {
              getCanvasIcon(); 
              requestAnimationFrame(renderAnimation) 
            };

            const getCanvasIcon = () => {

              const svg = element[0].querySelector(".app_loader svg path");

              // 512px because default size of icon path is 512px
              let size = parseInt("512px");
              canvas.width = 512;
              canvas.height = 512;
              scope.icon = scope.appInfo?.icon ? scope.appInfo.icon : scope.icon;

              const gradient = context.createLinearGradient(0,0,512,512);
              
              gradient.addColorStop(0,'#' + scope.icon.gradient_up);
              gradient.addColorStop(1,'#' + scope.icon.gradient_down);
              
              context.fillStyle = gradient;
              context.fillRect(0, 0, size, size);
              
              if(svg != null) {
                let p = new Path2D(svg.getAttribute('d'));
                context.fillStyle = '#' + scope.icon.icon_color;
                context.fill(p);
                return canvas;
              } else {
                const fontSize = size * 0.7;
                context.font = `${fontSize}px sans-serif`;
                context.fillStyle = "#fff";
                context.fillText(decodeURIComponent(scope.icon.icon_id), 40, 385);
                return canvas;
              }

            };

            //here we render animation in DOM
            const renderAnimation = () => {
              const canvas1 = document.createElement("canvas");
              canvas1.width = 512;
              canvas1.height = 512;
              canvas1.style.borderRadius = '30px';
              canvas1.style.transform = 'scale(0.3)';
              
              element[0].querySelector('.wrap_loader_dots').append(canvas1);
              const pieces = generatePieces(4, 4, canvas1.width, canvas1.height);
              
              const ctx1 = canvas1.getContext("2d");
              
              for(let p = 0; p < pieces.length; p++) {
                pieces[p].image = context.getImageData(pieces[p].x, pieces[p].targetY, pieces[p].width, pieces[p].height);
              }
              ;
              const render = () => {
                for(let p = pieces.length - 1; p >= 0; p--) {
                  setTimeout(() => {
                    for(let i = 0; i < pieces[p].height; i++) {
                      setTimeout(() => {
                        ctx1.clearRect(pieces[p].x, pieces[p].y, pieces[p].width, pieces[p].height);
                        pieces[p].y -= 1;
                        ctx1.putImageData(pieces[p].image, pieces[p].x, pieces[p].y);
                      }, i * animationSpeed)
                    }
                  }, p * pieces[p].height * animationSpeed)
                }

                ctx1.clearRect(0, 0, canvas1.width, canvas1.height);
                pieces.forEach(piece => {
                  piece.y = piece.targetY + piece.height;
                })

                setTimeout(() => {
                  requestAnimationFrame(render);
                },pieces[0].height * pieces.length * animationSpeed + pieces[0].height + 100)
                
              };

              render();
            };
          }

          compiled(scope);
        }
      };
    }
  ]);
