angular.module('templatesGeneratorService', [])
.service('templatesGeneratorService', ['$filter', function($filter){

  this.templates = [
    {
      template_name: "Personal Information",
      icon: "user_info",
      
      content: [
        {
          template_id: 'gh_element',
          name: '',
          icon_id: '',
          class: '',
          priority: 0,
          container_id: 0,
          content: [],
          model: {
            field_name: "First Name",
            data_type: "text",
            data_model: {
              multiple_value: false
            }
          },
        },
        {
          template_id: 'gh_element',
          name: '',
          icon_id: '',
          class: '',
          priority: 0,
          container_id: 0,
          content: [],
          model: {
            field_name: "Last Name",
            data_type: "text",
            data_model: {
              multiple_value: false
            }
          }
        },
        {
          template_id: 'gh_element',
          name: '',
          icon_id: '',
          class: '',
          priority: 0,
          container_id: 0,
          content: [],
          model: {
            field_name: "Phone",
            data_type: "phone",
            data_model: {
              multiple_value: false
            }
          },
        },
        {
          template_id: 'gh_element',
          name: '',
          icon_id: '',
          class: '',
          priority: 0,
          container_id: 0,
          content: [],
          model: {
            field_name: "Email",
            data_type: "email",
            data_model: {
              multiple_value: false
            }
          },
        },
        {
          template_id: 'gh_element',
          name: '',
          icon_id: '',
          class: '',
          priority: 0,
          container_id: 0,
          content: [],
          model: {
            field_name: "D.O.B",
            data_type: "date",
          },
        }
        
      ]
    },
    {
      template_name: "Location",
      icon: "location_info",
      
      content: [
        {
          template_id: 'gh_element',
          name: '',
          icon_id: '',
          class: '',
          priority: 0,
          container_id: 0,
          content: [],
          model: {
            field_name: "Address",
            data_type: "text",
            field_value: "",
            data_model: {
              multiple_value: false
            }
          },
        },
        {
          template_id: 'gh_element',
          name: '',
          icon_id: '',
          class: '',
          priority: 0,
          container_id: 0,
          content: [],
          model: {
            field_name: "Country",
            data_type: "text",
            field_value: "",
            data_model: {
              multiple_value: false
            }
          }
        },
        {
          template_id: 'gh_element',
          name: '',
          icon_id: '',
          class: '',
          priority: 0,
          container_id: 0,
          content: [],
          model: {
            field_name: "Zip",
            data_type: "text",
            field_value: "",
            data_model: {
              multiple_value: false
            }
          }
        },
        {
          template_id: 'gh_element',
          name: '',
          icon_id: '',
          class: '',
          priority: 0,
          container_id: 0,
          content: [],
          model: {
            field_name: "City",
            data_type: "text",
            field_value: "",
            data_model: {
              multiple_value: false
            }
          }
        },
        {
          template_id: 'gh_element',
          name: '',
          icon_id: '',
          class: '',
          priority: 0,
          container_id: 0,
          content: [],
          model: {
            field_name: "Phone",
            data_type: "phone",
            data_model: {
              multiple_value: false
            }
          },
        },
        
      ]
    }
  ]

  this.getTemplates = function () {
    return this.templates;
  }

}])