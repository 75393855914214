import Rete from "rete";
import { AutomationInstanceCreator } from './AutomationInstanceCreator.js';

import './iterator_debugger.scss';

angular.module("iteratorDebuggerModule", []).directive("iteratorDebugger", [
  function () {
    return {
      scope: {
        ghModel: "=",
        mocks: "=",
        trigger: "=",
        debugInfo: "="
      },
      controller: [
        "$scope",
        function ($scope) {

          $scope.runDebug = async () => {

            // Reinit is adding in gh automation to rerender gh automation

            $scope.debugInfo.reinit();

            if ($scope.isDebugging) return;

            if (!$scope.currentMock) return;

            $scope.isDebugging = true;

            const mock = $scope.mocks[$scope.currentMock];

            const model = $scope.ghModel;

            const engine = new Rete.Engine("trigger@0.1.0");

            const automationModulesList = gudhub.storage.getModulesList('automation');

            const promises = [];

            for (let module of automationModulesList) {
              promises.push(new Promise(async (resolve) => {
                let instance = await AutomationInstanceCreator.createInstance(module.data_type, gudhub);
                engine.register(instance);
                resolve();
              }))
            }

            await Promise.all(promises);

            engine.on("error", ({ message, data }) => {
              console.error('MESSAGE: ', message);
              console.error('DATA: ', data);
            });

            await new Promise(resolve => setTimeout(resolve, 0));

            const result = await new Promise(resolve => {
              engine.process(model, null,
                {
                  inputs: mock,
                  onFinish(data) {
                    resolve(data);
                  }
                },
                resolve, gudhub);
            });

            $scope.isDebugging = false;

            return result;
          }

          $scope.$watch(
            "mocks",
            () => {
              $scope.preparedMocks = $scope.mocks.map((mock, index) => {
                return {
                  name: `Iteration - ${index}`,
                  value: index
                }
              })
            },
            true
          );

          $scope.decorator = {
            data_model: {
              color: "0893d2",
              settings: [
                {
                  type: "general_setting",
                  icon: "menu",
                  title: "Options",
                  columns_list: [
                    [
                      { title: "Mocks", type: "header" },
                      {
                        type: "html",
                        class: "option-column_750px",
                        data_model(fieldModel) {
                          return {
                            patterns: [
                              {
                                property: "name",
                                type: "text",
                                prop_name: "Name",
                                data_model: function (option) {
                                  return {};
                                },
                                display: true,
                              },
                              {
                                property: 'current_item',
                                type: 'item_ref',
                                prop_name: 'Current item',
                                display: true,
                                data_model: function (option) {
                                  return {
                                    refs: [
                                      {
                                        app_id: $scope.appId,
                                        field_id: fieldModel.data_model.field_for_items_select
                                      }
                                    ],
                                  };
                                }
                              },
                              {
                                property: 'selected_items',
                                type: 'item_ref',
                                prop_name: 'Selected items',
                                display: true,
                                data_model: function (option) {
                                  return {
                                    multiple_value: true,
                                    refs: [
                                      {
                                        app_id: $scope.appId,
                                        field_id: fieldModel.data_model.field_for_items_select
                                      }
                                    ],
                                  };
                                }
                              },
                              {
                                property: 'new_item',
                                type: 'item_ref',
                                prop_name: 'New item',
                                display: true,
                                data_model: function (option) {
                                  return {
                                    refs: [
                                      {
                                        app_id: $scope.appId,
                                        field_id: fieldModel.data_model.field_for_items_select
                                      }
                                    ],
                                  };
                                }
                              },
                              {
                                property: 'value',
                                type: 'text',
                                prop_name: 'Value',
                                display: true,
                                data_model: () => {
                                  return {};
                                }
                              }
                            ],
                          };
                        },
                        control:
                          '<gh-option-table items="fieldModel.options" pattern="field_model.patterns"></gh-option-table>',
                      },
                    ],
                  ],
                },
              ],
            },
            data_type: "additional_settings",
          };
        },
      ],
      template: /*html*/`
        <div class="iterator-debugger">
          <gh-input ng-model="currentMock" gh-dropdown="preparedMocks"></gh-input>
          <span class="iterator-debugger__run" gh-icon="wmv 0893d2 24px normal" ng-click="runDebug()"></span>  
        </div>
      `,
    };
  },
]);
