require('../../gh_elements/image/imageRotation_factory.js');
require('./gh_avatar.scss');

angular.module('ghAvatarModule', [
    'ngImgCrop',
    'fileManagerModule',
    'authorizationMod',
    'imageRotaionModule'
])


.directive('ghAvatar', [ '$document', '$timeout', 'avatarEditorService', 'fileManager', function($document, $timeout, avatarEditorService, fileManager) {

    return {
        restrict: 'E',
        scope: {
            ghModel: '=',
            fieldDataModel: '='
        },
        template:
            '<div class="text-center aligncenter">' +
            '   <input type="file" avatar-upload>' +
            '   <md-progress-circular ng-show="file.loading && ghModel" md-mode="indeterminate"></md-progress-circular>'+
            '   <div ng-if="file.err || !ghModel" gh-icon="user_round edf2f7 0px auto normal"></div>'+
            '   <div class="user-logo" ng-show="!file.loading" >' +
            '      <img img-load ng-show="!file.err || !file.loading && ghModel" class="avatar" ng-src="{{ghModel}}"/>'+
            '   </div>' +
            '   <div ng-show="(!file.loading || !ghModel) && fieldDataModel.uploadEvents" class="transition-disabled cloud-keyframe" gh-icon="cloud ffffff {{fieldDataModel.avatar_size * 0.7}}px">'+
            '       <span>Click to Upload or Drag &amp; Drop</span>'+
            '   </div>'+
            '</div>',
        controller: [ '$scope', '$element', '$attrs', '$mdDialog', function($scope) {

            $scope.file = {
                errMsg: '',
                loading: true,
                err: false
            };
        }],
        link: function(scope, element, attrs) {

            if(!scope.fieldDataModel){
                return;
            }

            if(scope.fieldDataModel.uploadEvents){
                element = element.children();

                element.on('click', function(){
                    this.children[0].click();
                });
                /* drag and drop events*/
                var counter = 0;
                element.on('dragenter', function(event) {
                    counter++;
                    element.addClass('hover');
                    event.preventDefault();
                });
                /* it's for prevent opening file in browser*/
                element.on('dragover', function(event) {
                    event.preventDefault();
                });

                element.on('dragleave', function(event) {
                    counter--;
                    if (counter === 0) {
                        element.removeClass('hover');
                    }
                    event.preventDefault();
                });

                element.on('drop', function(event) {
                    event.preventDefault();

                    element.removeClass('hover');
                    counter = 0;
                    if (avatarEditorService.testExtension(event.dataTransfer.files[0].name)) {
                        scope.file.loading = true;

                        avatarEditorService.editImage(event.dataTransfer.files[0], scope).then(function(base64_image) {
                            scope.ghModel = base64_image;
                        }, function() {
                            scope.file.loading = false;
                        });

                    }
                });
            }
        }
    };
}])

.directive('imgLoad', [function(){
    return {
        restrict: 'A',

        link: function(scope, element, attrs){

        console.log('start dir');

            element.on('load', function (event) {
                scope.$apply(function() {
                    scope.file.loading = false;
                    scope.file.err = false;
                });
            });

            element.on('error', function(event) {
                scope.file.loading = false;
                scope.file.err = true;
            });
        }
    };
}])

.directive('avatarUpload', [ 'avatarEditorService', function(avatarEditorService){
    return {
        restrict: 'A',
        link: function(scope, element, attrs){

            element.on('change', function(event) {

                var file = event.target.files[0];
                if(!scope.ghModel){
                    scope.file.loading = false;
                }

                if (scope.file.loading || !file) {
                    return false;
                }

                if (avatarEditorService.testExtension(file.name)) {
                    scope.file.loading = true;

                    avatarEditorService.editImage(file, scope).then(function(base64_image) {
                        scope.ghModel = base64_image;
                    }, function() {
                        scope.file.loading = false;
                    });

                }
                /* Reset input to reload image*/
                element[0].value = '';
            });

        }
    };
}])

.service('avatarEditorService', [ '$q', '$mdDialog', 'authService','imageRotaionFactory', function($q, $mdDialog, authService, imageRotaionFactory) {
    var self = this;

    var avatarExtensions = /\.(gif|jpg|jpeg|tiff|png)$/i;
    /* Test of image file extension*/
    this.testExtension = function(name) {
        if (avatarExtensions.test(name)) {
            return true;
        }
        return false;
    };
    /* Template of image editor modal window*/
    this.template =
        '<md-dialog aria-label="avatar" ng-cloak>'+
        '   <form>'+
        '       <md-dialog-content>'+
        '           <div class="md-dialog-content">'+
        '               <img-crop style="height:350px;width:500px" area-type="circle"  image="editor.myImage" result-image="editor.myCroppedImage" result-image-format="image/jpeg" ></img-crop>'+
        '           </div>'+
        '       </md-dialog-content>'+
        '       <md-dialog-actions layout="row">'+
        '           <span flex></span>'+
        '           <md-button ng-click="cancel()">Cancel</md-button>'+
        '           <md-button ng-click="ok()">Ok</md-button>'+
        '       </md-dialog-actions>'+
        '    </form>'+
        '</md-dialog>';

    /* Create popup window with image editor*/
    this.editImage = function(fileObj){
        var deferred = $q.defer();
        /*Open popup window*/
        
        $mdDialog.show({
            controller: ['$scope', function($scope) {

                var reader = new FileReader();
                reader.onload = function(evt){
                    imageRotaionFactory.getOrientation(fileObj).then(orientation =>{
                        imageRotaionFactory.resetOrientation(evt.target.result, orientation, fileObj.type).then(rotatedImg=>{
                            $scope.editor.myImage = rotatedImg;
                            $scope.$digest();    
                        })
                      })
                };

                reader.readAsDataURL(fileObj);

                $scope.editor = {
                    myImage: '',
                    myCroppedImage: ''
                };

                $scope.cancel = function(){
                    $mdDialog.cancel();
                };

                $scope.ok = function(){
                    $mdDialog.hide($scope.editor.myCroppedImage);
                    
                };
            }],
            template: self.template,
            clickOutsideToClose: true,
            fullscreen: true
        })
            .then(function(myCroppedImage){
                deferred.resolve(myCroppedImage);
              
            },function(){
                deferred.reject();
            });

        return deferred.promise;
    };

    /* Upload user avatar to server*/
    this.uploadAvatar = function(source){


        var deferred = $q.defer();
        authService.updateAvatar(source).then(function() {
            deferred.resolve();
        }, function() {
            deferred.reject();
        });
        return deferred.promise;
    };
    return this;
}]);
