require('./../../config.js');

angular.module('sendingInviteModule', [])
    .service('sendInviteService', ['$q', '$http', 'cnfg', 'authService', function ($q, $http, cnfg, authService) {

        this.sendInvite = function (applist, userEmails) {
            var deferred = $q.defer();

            authService.getToken().then(function( accessToken ) {
                sendToApi(applist, userEmails.split(','), accessToken).then(function(data){
                    console.log("FINISH SEND", data)
                    deferred.resolve(data)
                })
            })

            return deferred.promise;
        }
        

        function replaceSpecialCharacters(param) {
            return angular.toJson(param).replace(/\+|&|%/g, function(match) {
                switch (match) {
                    case '+':
                        return '%2B';
                    case '&':
                        return '%26';
                    case '%':
                        return '%25';
                }
            })
        }
    

        function sendToApi(apps, guests_emails, accessToken) {
            return $http({
              method: 'POST',
              url:  cnfg.server_url + '/api/invitation/add',
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            },
            data: 'guests_emails=' + replaceSpecialCharacters(guests_emails) +
            '&apps=' + replaceSpecialCharacters(apps) +
            '&token=' + accessToken,
            }).then(function(result) {
              return result;
            }, function(err) {
              return $q.reject(err);
            });
          }
          
        return this;
    }]);