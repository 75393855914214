import "./../../automation/gh_automation.js";
import Rete from "rete";
import { AutomationInstanceCreator } from './../../automation/AutomationInstanceCreator.js';

angular.module("ghAutomationMockModule", []).directive("ghAutomationMock", [
  function () {
    return {
      scope: {
        trigger: "=",
        options: "=",
        init: "=",
      },
      controller: [
        "$scope",
        function ($scope) {
          $scope.mocks = [];
          $scope.runDebug = async function () {
            if(!$scope.currentMock) return;
            const mockData = $scope.trigger.mocks.options[$scope.currentMock];
            if (mockData && !$scope.isDebug) {
              $scope.isDebug = true;
              await $scope.init();
              const request =
                typeof mockData.mock.request === "string"
                  ? JSON.parse(mockData.mock.request)
                  : mockData.mock.request || {};
              const triggerData = {
                type: mockData.output,
                request,
              };
              await automations($scope.trigger.model, triggerData);
              $scope.isDebug = false;
            }
          };

          function automations(model, obj) {
            return new Promise(async (resolve, reject) => {
              const engine = new Rete.Engine("trigger@0.1.0");
              console.log("DEBUG START");

              const automationModulesList = gudhub.storage.getModulesList('automation');

              const promises = [];

              for(let module of automationModulesList) {
                promises.push(new Promise(async (resolve) => {
                  let instance = await AutomationInstanceCreator.createInstance(module.data_type);
                  engine.register(instance);
                  resolve();
                }))
              }

              await Promise.all(promises);

              engine.on("error", ({ message, data }) => {});
              engine.process(model, null, obj, resolve, gudhub);
            });
          }

          $scope.$watch(
            "trigger.mocks",
            () => {
              if ($scope.trigger.mocks && $scope.trigger.mocks.options) {
                $scope.mocks = $scope.trigger.mocks.options.map(
                  (option, index) => ({
                    name: option.name,
                    value: index,
                  })
               
                );
              }
            },
            true
          );
          $scope.decorator = {
            data_model: {
              color: "0893d2",
              settings: [
                {
                  type: "general_setting",
                  icon: "menu",
                  title: "Options",
                  columns_list: [
                    [
                      { title: "Mocks", type: "header" },
                      {
                        type: "html",
                        class: "option-column_750px",
                        data_model(fieldModel) {
                          return {
                            patterns: [
                              {
                                property: "name",
                                type: "text",
                                prop_name: "Name",
                                data_model: function (option) {
                                  return {};
                                },
                                display: true,
                              },
                              {
                                property: "output",
                                type: "text_opt",
                                prop_name: "Output",
                                data_model: function (option) {
                                  return { options: $scope.options };
                                },
                                display: true,
                              },
                              {
                                property: "mock",
                                prop_name: "Settings",
                                type: "additional_settings",
                                display: true,
                                data_model() {
                                  return {
                                    settings: [
                                      {
                                        title: "Mock",
                                        type: "general_setting",
                                        icon: "configuration",
                                        columns_list: [
                                          [
                                            {
                                              type: "ghElement",
                                              class: "option-column_full_size",
                                              property: "request",
                                              data_model: function () {
                                                return {
                                                  data_model: {
                                                    code_mode: "javascript",
                                                    save_file: false,
                                                    interpretation: [
                                                      {
                                                        src: "form",
                                                        settings: {
                                                          show_field_name: 0,
                                                        },
                                                      },
                                                    ],
                                                  },
                                                  field_name: "Code Editor",
                                                  name_space: "code_editor",
                                                  data_type: "code_editor",
                                                };
                                              },
                                            },
                                          ],
                                        ],
                                      },
                                    ],
                                  };
                                },
                              },
                            ],
                          };
                        },
                        control:
                          '<gh-option-table items="fieldModel.options" pattern="field_model.patterns"></gh-option-table>',
                      },
                    ],
                  ],
                },
              ],
            },
            data_type: "additional_settings",
          };
        },
      ],
      template: ` <span class="gh-automation-debug-mode">Debug Mode</span>
                        <gh-boolean class="gh-automation-trigger-switch gh-api-btn-switch" gh-model="trigger.debug" gh-mode="switch" editable="true"></gh-boolean>
                        <div ng-show="trigger.debug" class="gh-automation-mock">
                           <gh-input class="gh-automation-target-app-dropdown" ng-model="currentMock" gh-dropdown="mocks"></gh-input>
                           <gh-element elem-src="form"
                                       decorator="decorator"
                                       value="trigger.mocks"></gh-element>
                           <span class="gh-automation-mock-run" gh-icon="wmv 0893d2 24px normal" ng-click="runDebug()"></span>                    
                        </div>`,
    };
  },
]);
