import './lost_connection.css';

angular.module('connectionModule', [])
.directive('lostConnection', function() {
	return {
		controller: [ '$scope', function($scope) {
			$scope.showNoInternetConnectionDialog = false;

			function showInternetConnectionDialog (showDialog) {
				$scope.showNoInternetConnectionDialog = showDialog;
			 	$scope.$apply();
			}

			function checkInternetConnection() {
				if(navigator.onLine)
				showInternetConnectionDialog (false);
				else {
					console.log("It's work")
				}
			}
			
	

			window.addEventListener('online', () => {
				showInternetConnectionDialog (false);
			});
			window.addEventListener('offline', () => {
				showInternetConnectionDialog (true);
			});
		}],

		template: 
		`<div class="internet_error" ng-if="showNoInternetConnectionDialog"> 
			<div class="internet_error-bg">
			<span class="error_icon" gh-icon="alert fff 280px normal"></span>
			<h2>No Internet Connection</h2>
			<p>No Internet connection found. Check you connection and try again.</p>
			<div class="tryAgain_btn" ng-click = "checkInternetConnection()">Retry</div>
			<div>
		</div>`,
	}
})