require('./../config.js');
require('./modules_list.js');

angular.module('mainStorage', [
  'ModulesListModule',
  'config',
])





/*=======================================================================================================*/
/*===============================================   MAIN STORAGE   ======================================*/
/*=======================================================================================================*/
    
    
    
.service('storage', ['$q', 'cnfg', function($q, cnfg) {
  var log = cnfg.log.storage; /*- show/hide console output*/
  return gudhub.storage;
  
  // var mainStorage = {
  //   apps_list: [], //-- All our application are saved here
  //   users_list: [], //-- here we cash requests regarding other users
  //   user: {}, //-- here is information regarding current user
  //   // modules_list: ModulesList.getModulesList() //-- Here we keep saved information regarding all gh-elements from modules_list.js file
  // };
  //
  // this.getMainStorage = function() {
  //   return mainStorage;
  // };
  //
  // this.getModules = function() {
  //   return mainStorage.modules_list;
  // };
  //
  // /* -- Delete all applications from storage -- */
  // this.unsetApps = function() {
  //   mainStorage.apps_list = [];
  // };
  //
  //
  //
  // this.getAppFromStorage = function(appId){
  //   var deferred = $q.defer();
  //   var app;
  //
  //   angular.forEach(mainStorage.apps_list, function(currApp) {
  //     if (currApp.app_id == appId) {
  //       app = currApp;
  //     }
  //   });
  //
  //   if (app) {
  //     deferred.resolve(app);
  //   } else {
  //     deferred.reject(false);
  //   }
  //   return deferred.promise;
  // };
  //
  //
  // this.getApp = function(appId) {
  //   var app = {};
  //   angular.forEach(mainStorage.apps_list, function(currentApp) {
  //     if (currentApp.app_id == appId) {
  //       app = currentApp;
  //     }
  //   });
  //
  //   return app;
  // };
  //
  //
  // this.setUser = function(data) {
  //   if (data.avatar_128) {
  //     data.avatar_128 = data.avatar_128 + '?' + new Date().getTime();
  //   }
  //
  //   if (data.avatar_512) {
  //     data.avatar_512 = data.avatar_512 + '?' + new Date().getTime();
  //   }
  //   angular.extend(mainStorage.user, data);
  // };
  //
  // this.unsetUser = function() {
  //   mainStorage.user = {};
  // };
  //
  // this.getUser = function(data) {
  //   return mainStorage.user;
  // };
  //
  // this.updateToken = function(newToken) {
  //   mainStorage.user.token = newToken;
  // };

}])




/*======================================================================================================|
|======================================   FIELD TEMPLATES STORAGE   ====================================|
|=======================================================================================================|
|-- here we store all fields templates
*/
.service('fieldTemplatesStorage', ['GHConstructor', '$q', 'storage', '$templateCache','ModulesList', function(GHConstructor, $q, storage, $templateCache, ModulesList) {


  /*----------------------------  GETTING LIST OF FIELDS AND ACTIONS TEMPLATES  ------------------------*/
  /*-- This list will be used in edit_template action */
  this.getFieldsTemplates = function() {
      var fieldTemplates = [];
      var modules = ModulesList.getModulesList(); /* Loading modules list from storage*/

      angular.forEach(modules, function(module, key) {

          GHConstructor.getInstance(module.name).then(function(elementTemplate) {
              elementTemplate = elementTemplate.getTemplate();

              /* in case if module is private we don't push it into field's list */
              if (!elementTemplate['private'])
                  fieldTemplates.push(elementTemplate);
          });

      });


      return fieldTemplates;
  };

}])