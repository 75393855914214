import './automationContainer.scss';

angular.module('automationContainerModule', [])

.directive('automationContainer', [function () {

  return {
    scope: {
      elementsCatalog: '=',
      components: '='
    },

    controller: ['$scope', '$element', function ($scope, $element) {
        
      $scope.limitTo = 15;
      let containerThatScroll = angular.element($element[0].querySelector('.automation-container__elements'));
      let heightToBottom = containerThatScroll[0].scrollHeight - containerThatScroll[0].scrollTop - containerThatScroll[0].clientHeight;

      containerThatScroll.bind('scroll', scrollWatcher);

      function scrollWatcher() {
        heightToBottom = containerThatScroll[0].scrollHeight - containerThatScroll[0].scrollTop - containerThatScroll[0].clientHeight;

        if(heightToBottom < 200){
          $scope.limitTo += 10;
          $scope.$apply();
        }
      }
      
    }],
    template: /*html*/`
    <div class="automation-container__wrapper">
      <div class="automation-container__search">
        <span gh-icon="search 0fb5ff 23px none"></span>
        <input type="text" ng-model="searchElements_catalog" placeholder="Search...">
      </div>
      <div class="automation-container__elements">
        <automation-component class="automation-container__element" ng-repeat="element in elementsCatalog | filter:searchElements_catalog | limitTo: limitTo" components="components" element="element"></automation-component>
      </div>
    </div>
    `
  }
}])