/********************************************************************************************************/
/***********************************   GH INFOGRAPHIC COUNTER    ****************************************/
/********************************************************************************************************/


angular.module('ghInfographicValueModule', [])

.directive('ghInfographicValue', ['$compile', 'ghInfographicValueService', function ($compile, ghInfographicValueService) {

  return {
    restrict: 'E',
    scope:{
      ghAppId: '@',
      ghItemId: '@',
      ghFieldModel: '='
    },
    controller: ['$scope', '$element', function($scope, $element){

      if(!$scope.ghAppId || !$scope.ghFieldModel || !$scope.ghItemId){
        return;
      }

      var template =
        '<p class="graphic-name">{{ghFieldModel.field_name}}</p>'+
        ghInfographicValueService.getTemplate($scope.ghFieldModel.data_model.graphic_type);
      $element.append($compile(template)($scope));

    }],
    link: function($scope){}
  };

}])

.service('ghInfographicValueService', [function () {

  this.getTemplate = function (templateType) {
    return  this.template[templateType] || '<span></span>';
  };

  this.template = {
    thickness:
      '<svg id="svg" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512 165.8" style="enable-background:new 0 0 512 165.8;">'+
      ' <polygon fill="#D9E0E3" points="221.5,46 335.2,78.7 335.2,127.3 221.5,90.3 "/>' +
      ' <polygon fill="#C2D0D3" points="335.2,78.7 498,71.9 498,118.2 335.2,127.3"/>' +
      ' <polygon fill="#A8B6BA" points="365.2,38.5 498,71.9 335.2,78.7 221.5,46"/>' +
      ' <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="221.5" y1="46" x2="130.5" y2="46"/>' +
      ' <line fill="none" stroke="#000000" stroke-miterlimit="10" x1="234.9" x1="221.5" y1="90.3" x2="130.5" y2="90.3"/>' +
      ' <foreignObject transform="matrix(1 0 0 1 14 36.7271)" style="font-size:50px;">'+
      '   <div gh-element app-id="{{ghAppId}}" item-id="{{ghItemId}}" field-id="{{ghFieldModel.data_model.fields[0].field_id}}" elem-src="table"></div>'+
      ' </foreignObject>' +
      '</svg>',

    dimension:
      '<svg version="1.1"xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" ' +
      'style="enable-background:new 0 0 512 512;" xml:space="preserve">' +
      '<g>' +
      '   <polygon fill="#A8B6BA" points="125.6,204.5 155,199.5 172.3,191.8 192.1,190.8 204.8,182.9 235.6,172 246.2,175.6 290.4,174.1' +
      '       306.4,167 314.9,169.4 336.6,161.9 348.4,157.7 361.3,157.7 376.9,157.4 390.1,152.8 398.9,154.9 412.9,149 431.9,150.1' +
      '       450.3,146.2 461,140.8 472.5,144.4 476.6,152.2 478.4,164 475.6,169.9 479.4,176 477.9,191.2 479.2,208.2 480.4,235.2 473.9,258 ' +
      '       475.1,289.2 471.3,312.7 474.5,330.5 474.5,336.2 465.9,337.6 460.9,344.8 441.1,351.9 419.2,353.1 387.3,366.7 366.4,365.7 ' +
      '       322.8,372.5 301.9,381.6 281,385.1 259.1,380.7 250.2,386.1 227.7,390.1 202.1,399.5 181,401.3 155.8,398.5 142.3,403.4 ' +
      '       128.1,400.4 120,386.5 129.7,355.5 129.9,325.2 132,319.8 126,317.1 127.6,308.9 124.2,293.7 122.8,286.3 126.8,274.3 120.8,260.9 ' +
      '       127.4,255.6 123.1,249 121.1,220.8 	"/>' +
      '   <polygon fill="#D9E0E3" points="131.5,207.6 160.9,202.6 178.2,194.8 198,193.9 210.6,186 241.4,175.1 252.1,178.7 296.3,177.1 ' +
      '       312.3,170 320.7,172.4 342.5,164.9 354.3,160.8 367.2,160.7 382.8,160.5 396,155.9 404.8,157.9 418.8,152 437.7,153.2 456.2,149.2 ' +
      '       466.9,143.9 478.4,147.5 482.5,155.2 484.3,167.1 481.5,172.9 485.3,179.1 483.8,194.3 485.1,211.3 486.3,238.2 479.8,261.1 ' +
      '       480.9,292.2 477.2,315.7 480.4,333.6 480.4,339.2 471.8,340.7 466.8,347.9 447,355 425.1,356.2 393.2,369.8 372.3,368.8 ' +
      '       328.7,375.5 307.7,384.6 286.9,388.2 264.9,383.8 256.1,389.1 233.6,393.2 208,402.5 186.9,404.4 161.7,401.6 148.2,406.4 ' +
      '       134,403.5 125.9,389.6 135.6,358.6 135.8,328.3 137.9,322.8 131.8,320.2 133.5,312 130,296.8 128.7,289.4 132.6,277.4 126.7,264 ' +
      '       133.3,258.6 129,252 127,223.8 	"/>' +
      '   <line fill="none" stroke="#444849" stroke-width="2" stroke-miterlimit="10" x1="126" y1="171.5" x2="483" y2="111.1"/>' +
      '   <line  fill="none" stroke="#444849" stroke-width="2" stroke-miterlimit="10" x1="102.2" y1="416.4" x2="103.7" y2="197"/>' +
      '</g>' +
      '<foreignObject transform="matrix(1 0 0 1 258.9448 83.8774)" style="font-size:40px;">'+
      ' <div gh-element app-id="{{ghAppId}}" item-id="{{ghItemId}}" field-id="{{ghFieldModel.data_model.fields[0].field_id}}" elem-src="table"></div>'+
      '</foreignObject>' +
      '<foreignObject transform="matrix(1 0 0 1 19.466 300.7758)" style="font-size:40px;">'+
      ' <div gh-element app-id="{{ghAppId}}" item-id="{{ghItemId}}" field-id="{{ghFieldModel.data_model.fields[1].field_id}}" elem-src="table"></div>'+
      '</foreignObject>' +
      '</svg>'
  };

  return this;
}]);



