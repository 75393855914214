require('./gh_window.scss');
import ContextMenu from './../../gui/context_menu';

/**************************************************************************************************************/
/**********************************************    GH-WINDOWS     *********************************************/
/**************************************************************************************************************/

angular.module('ghWindowModule', [
    'ghConstructor'
])





/*=========================================================================================================*/
/*========================================  GH-WINDOWS DIRECTIVE   =========================================*/
/*=========================================================================================================*/
.directive('ghWindows', [ '$compile', 'ghWindowsService', function($compile, ghWindowsService) {
  return {
      restrict: 'E',
      scope: {},
      template: '<gh-window ng-if="!hideAllExceptLast || $last" window="windows[$index]" ng-repeat="window in windows" class="{{window.window_mode}}" window-model=\"window\" style=\"z-index:{{$index+1}};\"></gh-window>',

      controller: [ '$scope', '$injector', '$element', '$location', '$routeParams', function($scope,  $injector, $element, $location, $routeParams) {
          /*-- saving models list for all windows*/
          $scope.windows = [];
          var oldWindowId;

       
          /*--Binding GH-WINDOWS with Window service*/
          ghWindowsService.init($scope);

          $scope.$on('$locationChangeSuccess', function(){
            var current_url = $location.path();
            var patt = new RegExp(/^\/act(?:\/([^\/]+)?)?(?:\/([^\/]+)?)?(?:\/([^\/]+)?)?(?:\/([^\/]+)?)?$/);
            oldWindowId = $routeParams.actionType + '_' + ($routeParams.param_1 ? $routeParams.param_1 + '_': '') +  ($routeParams.param_3 ? $routeParams.param_3 + '_' : '') + ($routeParams.param_2 ? $routeParams.param_2 : '');

            if(patt.test(current_url)){
              if($element.css('display') != 'flex'){
                $element.css('display','flex');
              }
            } else {
              $element.css('display','none');
            }
          });

          window.addEventListener('popstate', () => {
            ghWindowsService.windowDestroy(oldWindowId, false);
          });

          $scope.hideAllExceptLast = false;

          $scope.changeVisibilityPreviousWindowsInDOM = function (isHide) {
            $scope.hideAllExceptLast = isHide;
          };

      }],

      link: function(scope, element, attrs) {

      }

  };
}])

/*-----------------------------  GH-WINDOW-NEW  -------------------------------*/
.directive('ghWindow', [ '$compile', '$route', '$location', 'cnfg', 'ghWindowsService', 'GHConstructor', 'PipeService', 'GhElementEditor', 'Message', 'formEditorService', function($compile, $route, $location, cnfg, ghWindowsService, GHConstructor, PipeService, GhElementEditor, Message, formEditorService) {
  return {
      restrict: 'E',
      scope: {
          window: '='
      },
      controller: [ '$scope', function($scope) {
          /* Close current window*/
          $scope.destroyWindow = function() {
              ghWindowsService.windowDestroy($scope.window.window_id);
              window.removeEventListener('contextmenu', handleContextMenu);
          };

          $scope.$on("$destroy", function() {
            window.removeEventListener('contextmenu', handleContextMenu);
          });


          const findGhElement = (element) => {
            if (element && (element.tagName == 'GH-ELEMENT' || element.hasAttribute('gh-element')) && element.hasAttribute('field-id')) {
              return element;
            } else if(element.tagName == 'BODY') return false;
            return findGhElement(element.parentElement);
          }

          const handleContextMenu = async (event) => {
            const app = await gudhub.getAppInfo($scope.window.app_id || $route.current.params.param_1);

            if((cnfg.show_context_menu && app.permission == 4) || (cnfg.show_context_menu && app.permission == 3)){
              event.preventDefault();
              let ghElement = findGhElement(event.target);
              
              if(ghElement) {

                const ghElementContainerId = ghElement.getAttribute('container-id');
                const ghElementElemSrc = ghElement.getAttribute('elem-src');
                const ghElementFieldId = ghElement.getAttribute('field-id');
                const ghElementItemId = ghElement.getAttribute('item-id');
                const ghElementAppId = ghElement.getAttribute('app-id');
                
              async function saveChanges(fieldData) {
                let fieldModel = Object.assign({}, fieldData);
                delete fieldModel.settings;

                await gudhub.updateField(ghElementAppId, fieldModel)
              }
              
              const address = {
                app_id: ghElement.getAttribute('app-id') || $scope.window.app_id,
                field_id: ghElementFieldId
              };

              const model = await gudhub.getField(address.app_id, address.field_id) || {};
              const instance = await gudhub.ghconstructor.getInstance(model.data_type);
              if(!instance) {
                $scope.field_model = formEditorService.getElementModel(address.field_id);
              } else {
                $scope.field_model = instance.getTemplate().model;
              }
              
              let contextMenu = new ContextMenu(event, [{
                title: 'Edit template',
                events: {
                  click: function (e) {
                    $scope.$evalAsync(() => {
                      // 'param_1' is our app id, 'param_2' is our view_id
                      $location.path('act/edit_template/' + $route.current.params.param_1 + '/' + $route.current.params.param_2);
                    });
                  }
                }
              },
              {
                title: 'Edit field',
                events: {
                    click: async function(e) {
                        
                        if(Object.keys(model).length === 0){
                            Object.assign(model, $scope.field_model)
                        }
                        model.container_id = ghElementContainerId;
                        GhElementEditor.edit(model, address.app_id, ghElementItemId).then(function(fieldData) {
                            if (ghElementAppId) {
                                saveChanges(fieldData);
                            } else {
                                var message = new Message({
                                    field_id: 'existing_field_update_' + $scope.$id
                                }, $scope);

                                message.emit('existing_field_update', {
                                    to: {
                                        place: "template_editor" //-- example of message id 2365.template_editor
                                    },
                                    value: fieldData
                                });
                            }
                        });
                    }
                }
              }, {
                title: 'Edit style',
                events: {
                    click: function(e) {
                        GhElementEditor.editStyle(null, model, ghElementElemSrc, ghElementContainerId).then(function(fieldData) {
                            if (ghElementAppId) {
                                saveChanges(fieldData);
                            } else {
                                var message = new Message({
                                    field_id: 'existing_field_update_' + $scope.$id
                                }, $scope);

                                message.emit('existing_field_update', {
                                    to: {
                                        place: "template_editor" //-- example of message id 2365.template_editor
                                    },
                                    value: fieldData
                                });
                            }
                        });
                    }
                }
              }, {
                title: 'Delete element',
                events: {
                    click: function(e) {
                        if (ghElementAppId) {
                            let address = {
                                app_id: $scope.window.app_id
                            };

                            PipeService.on('gh_app_get', address, function getAppPipe(event, app) {
                                PipeService.destroy('gh_app_get', address, getAppPipe);

                                let app_copy = angular.copy(app);
                                formEditorService.deleteContainerFromView(app_copy.views_list, ghElementContainerId);

                                PipeService.emit('gh_app_update', {}, { app: app_copy });

                            }).emit('gh_app_get', {}, address);
                        } else {
                            var message = new Message({
                                container_id: 'container_delete_' + $scope.$id
                            }, $scope);

                            message.emit('container_delete', {
                                to: {
                                    place: "delete"
                                },
                                value: ghElementContainerId
                            });
                        }
                    }
                }
              }]);
            } else {
                let contextMenu = new ContextMenu(event, [{
                  title: 'Edit template',
                  events: {
                    click: function (e) {
                      $scope.$evalAsync(() => {
                        // 'param_1' is our app id, 'param_2' is our view_id
                        $location.path('act/edit_template/' + $route.current.params.param_1 + '/' + $route.current.params.param_2);
                      });
                    }
                  }
                }])
              }
            }
            
          }
          window.addEventListener('contextmenu', handleContextMenu)
      }],
      link: function(scope, element, attrs) {


            GHConstructor.getInstance(scope.window.window_mode).then(function(response) {

                response.getWindowScope(scope);
                var el = angular.element( response.getWindowHTML(scope) );
                element.empty();
                element.append($compile(el)(scope));
            });
        }
  };
}])



/*=======================================================================================================*/
/*========================================  GH-WINDOWS SERVICE   =========================================*/
/*=======================================================================================================*/
.service('ghWindowsService', ['$injector', '$timeout', function($injector, $timeout) {
  var windowsScope = {};

  var self= this;

  this.isWindowOpened = function (window_id){

    var isOpened = false;
    angular.forEach(windowsScope.windows, function(window, index){
      if(window.window_id == window_id){
        isOpened = true;
        /* -- Swap two elements of window in windows array -- */
        var windowsArr = windowsScope.windows;
        windowsArr[windowsArr.length - 1] = [windowsArr[index], windowsArr[index] = windowsArr[windowsArr.length - 1]][0];
      }
    });

    return isOpened;
  };

  /*------------- INITIALIZATION -------------*/
  this.init = function(scope) {
      windowsScope = scope;
  };

  /*--------------- NEW WINDOW --------------*/
  this.newWindow = function(actionType, scope) {
      var windowId = actionType + ( scope.app_id ? '_'+ scope.app_id : '' ) + ( scope.item_id ? '_'+ scope.item_id : '' ) + ( scope.view_id ? '_'+ scope.view_id : '' );

      /* Check if we want open window twice*/
      if(!self.isWindowOpened(windowId)){
        var windowScopeProp = {
            window_id: windowId, /*-- Window ID is equals windows url --*/
            window_mode: actionType,
            layer_position: windowsScope.windows.length,
            item_id: scope.item_id,
            app_id: scope.app_id,
            data: {},
        };

        angular.extend(windowScopeProp, scope);
        
        /*-- Creating new window in the GH-WINDOWS*/
        windowsScope.windows.push(windowScopeProp);
        $timeout(function() {
          windowsScope.$digest();//-- We added digest to fix showing popup window for Print document
        }, 0, false);

        if ($injector.get(actionType).getTemplate().hidePreviousWindows) {
          windowsScope.changeVisibilityPreviousWindowsInDOM(true);
        }
      }
  };

  this.windowDestroy = function(windowId, historyBack = true) {
    
    if (!windowId) {
      gudhub.util.selectedItems = {};
      windowsScope.windows = [];
      return;
    }

    let windowToDestroy = windowsScope.windows.find((w) => w.window_id == windowId);

    if (windowToDestroy) {
      let windowSettings = $injector.get(windowToDestroy.window_mode).getTemplate();
      windowsScope.windows.splice(windowsScope.windows.indexOf(windowToDestroy), 1);

      // There are several data types which opens in new window without route changing - so we don't need to do history back when window is closed
      if(windowSettings.run_on_rout && historyBack){
        window.history.go(-1);
      }

      if(windowSettings.hidePreviousWindows){
        windowsScope.changeVisibilityPreviousWindowsInDOM(false);
      }
    }
    // re run digest for add items and update items
    $timeout(function() {
        windowsScope.$digest();
    }, 0, false)
  };

}])

