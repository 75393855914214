require('./../gui/gh_auth_form.js');
require('../gh_elements/duration/gh_duration.scss');
require('./user_settings.scss');
require('./group_sharing/groups_table/groupsTable.directive.js');
require('./apps_list/appsList.directive.js');
require('./integrations/integrations.directive.js');
require('./automations/gh_element/element_automation.directive.js');
require('./automations/api/api_automation.directive.js');
require('./automations/trigger/trigger_automation.directive.js');
require('./automations/task/task_automation.directive.js');

import { GUDHUB_SETTINGS } from './../config.js';

angular.module('userSettingsMod', [
	'ghAuthFormModule'
])

/*--------------------- USER SETTINGS CONTROLLER --------------------                                */
.controller('userSettingsCtrl', ['$location', '$scope', 'cnfg', '$templateCache', 'storage', '$mdSidenav', function($location, $scope, cnfg, $templateCache, storage, $mdSidenav) {
	$scope.settings_list = [
		{
			title: 'Account settings',
			name: 'account',
			icon: 'user_round'
		},{
			title: 'Email Address',
			name: 'email',
			icon: 'email'
		},{
			title: 'Change Password',
			name: 'password',
			icon: 'lock'
		},{
			title: 'Groups',
			name: 'groups',
			icon: 'group'
		},{
			title: 'Applications',
			name: 'applications',
			icon: 'apps'
		},{
			title: 'Integrations',
			name: 'integrations',
			icon: 'integrations'
		},
		{
			title: 'Automations',
			name: 'automations',
			icon: 'automation'
		}];

		$scope.toggleSidenav = function(){
			$mdSidenav('left').toggle();
		};

		$scope.current_settings = null;

		$scope.selectSetting = function(current_index){
			$scope.current_settings = $scope.settings_list[current_index];
		};

		$scope.selectSetting(0);


}])

/*--------------------- USER PASSWORD CONTROLLER --------------------                                */
.controller('userAccountCtrl', ['$location', '$scope', 'cnfg', '$templateCache', 'storage', '$timeout', function($location, $scope, cnfg, $templateCache, storage, $timeout) {

	var change_avatar = false;

	$scope.storage_user = angular.copy(storage.getMainStorage().user);
	$scope.account_form = {};

	$scope.avatar_field_model = {
			image_size: 512,
			avatar_size: 180,
			uploadEvents: true
	};

	if($scope.storage_user){
			$scope.account_form = {
					first_name: $scope.storage_user.fullname.split(' ')[0] || '',
					last_name: $scope.storage_user.fullname.split(' ')[1] || ''
			};
	}

	$scope.$watch('storage_user.avatar_512', function(newVal, oldVal){
		if(newVal !== oldVal){
			change_avatar = true;
		}
	});

	$scope.send = async function() {
		var data = angular.copy($scope.account_form);
		if ($scope.accountForm.$valid) {

			var dataToSend = {
				user_id: $scope.storage_user.user_id,
				fullname: data.first_name + ' ' + data.last_name,
				username: $scope.storage_user.username
			};

			if(change_avatar){
				dataToSend.avatar = $scope.storage_user.avatar_512.replace(/^data:image\/(gif|jpg|jpeg|tiff|png);base64,/, "");
			}

			try {

				let user = await gudhub.updateUser(dataToSend);

				localStorage.setItem('user', JSON.stringify({
					fullname: user.fullname,
					avatar: user.avatar_512
				}));

				$scope.errMsg = 'Ok!';
				$scope.$digest();

			} catch(error) {

				$scope.errMsg = error.data + ' Please, try again...';

			} finally {

				$timeout(function() {
					$scope.errMsg = "";
				}, 1500);
			}
		}
	};

	$scope.cancel = function(){
		$location.path('/apps');
	};


}])
/*--------------------- USER EMAIL CONTROLLER --------------------                                */
.controller('userEmailCtrl', ['$location', '$scope', 'cnfg', '$templateCache', 'storage', '$timeout', function($location, $scope, cnfg, $templateCache, storage, $timeout) {

	$scope.storage_user = angular.copy(storage.getMainStorage().user);

	$scope.email_form = {};

	if($scope.storage_user){
			$scope.email_form = {
					email: angular.copy($scope.storage_user.username),
			};
	}

	$scope.send = async function() {
		var data = angular.copy($scope.email_form);
		if ($scope.emailForm.$valid) {

			var dataToSend = {
				user_id: $scope.storage_user.user_id,
				username: data.email,
			};

			try {

				let user = await gudhub.updateUser(dataToSend);

				$scope.errMsg = 'Ok!';
				$scope.$digest();

			} catch(error) {

				$scope.errMsg = error.data + ' Please, try again...';

			} finally {
				
				$timeout(function() {
					$scope.errMsg = "";
				}, 1500);
			}
		}
	};

	$scope.cancel = function(){
		$location.path('/apps');
	};


}])

/*--------------------- USER PASSWORD CONTROLLER --------------------                                */
.controller('userPassCtrl', ['$location', '$scope', 'cnfg', '$templateCache', 'storage', '$timeout', function($location, $scope, cnfg, $templateCache, storage, $timeout) {

  $scope.pass_form = {};

  $scope.send = async function() {
    var data = angular.copy($scope.pass_form);
    if ($scope.passForm.$valid) {

      var dataToSend = {
				user_id: storage.getMainStorage().user.user_id,
        password: data.password,
        old_password: data.old_password
      };

			try {

				let user = await gudhub.updateUser(dataToSend);

				$scope.errMsg = 'Ok!';
				$scope.$digest();

			} catch(error) {

				$scope.errMsg = error.data + ' Please, try again...';

			} finally {
				
				$timeout(function() {
					$scope.errMsg = "";
				}, 1500);
			}
    }
  };

	$scope.cancel = function(){
		$location.path('/apps');
	};

}])
.controller('groupSharing', ['$location', '$scope', 'GhDialog', async function($location, $scope, GhDialog) {

  $scope.showDialog = function() {
		GhDialog.show({
			position: 'center',
			toolbar: false,
			locals: {
				groups: $scope.userGroups
			},
			template: {
				toolbar: '',
        resizable: false,
				content: `
				<div class="dialog-share-group">
					<span gh-icon="group 0D99FF 65px"></span>
					<p class="dialog-share-group__title">Add group</p>
				</div>
				<div class="dialog-share-group__content">
					<div class="dialog-share-group__content__input">
					<label for="group_name">Group name</label>
						<input id="group_name" ng-model="groupName" type="text" placeholder="Type group name here...">
						<div ng-show="errMessage" class="error_message">{{errMessage}}</div>
					</div>
					<div class="dialog-share-group__content__buttons">
						<button class="dialog-share-group__content__buttons__cancel btn btn-blue-reverse" ng-click="cancel()">Cancel</button>
						<button class="dialog-share-group__content__buttons__add btn btn-blue" ng-click="addGroup(groupName)">Add</button>
					</div>
				</div>`,
			},
				controller: ['$scope','groups', function ($scope, groups) {
					$scope.errMessage = '';

          $scope.addGroup = async (groupName) => {
						if(!groupName) {
							$scope.errMessage = 'Please, type group name';
							return;
						}
						try {
							let group = await gudhub.groupSharing.createGroup(groupName);
							group.group_permission = '3'; //if user create group, he is owner
							groups.push(group);
							$scope.$apply();
							if(group) {
								$scope.cancel();
							}
						} catch(error) {
							$scope.errMessage = 'Something went wrong. Please, try again';
						}
					}
          
        }]
				
		})
	}

	$scope.userGroups = await gudhub.groupSharing.getGroupsByUser(gudhub.storage.getUser().user_id);
	$scope.$apply();

	$scope.cancel = function(){
		$location.path('/apps');
	};

}])

// -------------------- Applications Controller ------------------

.controller('applicationsCtrl', ['$scope', async function($scope) {

  $scope.appsList = await gudhub.getAppsList();
	$scope.appsList = $scope.appsList.filter(app => {
		return app.permission != 4 && app.sharing_type == 1;
	})
	$scope.$apply();

}])

// -------------------- Integration Controller ------------------

.controller('integrationsCtrl', ['$scope', async ($scope) => {

	const token = await gudhub.getToken();

	const response = await fetch(`${GUDHUB_SETTINGS.node_services_api_path}/integrations?token=${encodeURIComponent(token)}`);
	const integrations = await response.json();

	$scope.integrations = integrations.data;
  
	$scope.$digest();

}])

// -------------------- Automations Controller ------------------

.controller('automationsCtrl', ['$scope', 'cnfg', async ($scope, cnfg) => {
	$scope.tabs = [
		{
			name: 'Task'
		},
		{
			name: 'Trigger'
		},
		{
			name: 'Api'
		},
		{
			name: 'Elements'
		}
	];

	$scope.switchTab = (index) => {
		$scope.currentTab = index;
	}

	$scope.appsList = await gudhub.getAppsList();
	const defaultAppId = gudhub.storage.user.app_init;
	$scope.appsList = $scope.appsList.filter(app => {
		return (app.permission == 2 || app.permission == 3 || app.permission == 4) && app.app_id != defaultAppId;
	});
	$scope.clonedAppsList = JSON.parse(JSON.stringify($scope.appsList));
	$scope.$apply();

	const appsForGetApi = {
		apps: []
	}

	$scope.clonedAppsList.forEach(app => {
		appsForGetApi.apps.push(String(app.app_id));
	});

	$scope.taskList = await gudhub.req.axiosRequest(
		{
			method: 'POST',
			headers: {'Content-Type': 'application/json'},
			body: JSON.stringify(appsForGetApi),
			url: cnfg.node_services_api_path + '/tasks/get-tasks-list'
		});

	$scope.currentTab = 3;

	$scope.appsWithApi = await gudhub.req.axiosRequest(
		{ 
			method: 'POST',
			headers: {'Content-Type': 'application/json'},
			body: JSON.stringify(appsForGetApi),
			url: cnfg.node_services_api_path + '/api/get-api-list'
		});

	$scope.triggerList = await gudhub.req.axiosRequest(
		{
			method: 'POST',
			headers: {'Content-Type': 'application/json'},
			body: JSON.stringify(appsForGetApi),
			url: cnfg.node_services_api_path + '/trigger/get-trigger-list'
		});

}])