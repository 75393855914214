import { GUDHUB_SETTINGS } from '../../config.js';
angular.module('previewCtrlModule', [])

  .controller('PreviewCtrl', ['$location', async function ($location) {
    const template_id = $location.$$search.template_id;
    const redirect = $location.$$search.redirect;

    const response = await fetch(`${GUDHUB_SETTINGS.node_services_api_path}/app-install/get-preview-user-auth-key`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        template_id
      })
    });

    const json = await response.json();

    const auth_key = json.auth_key;

    localStorage.setItem('auth_key', auth_key);

    if(redirect) {
      window.location.pathname = redirect;
    } else {
      window.location.pathname = '/apps';
    }

  }])