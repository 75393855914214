require('./home.scss');
angular.module('homePageMod', [
])



/*=======================================================================================================*/
/*==============================   APPLICATIONS MANAGER CONTROLLER   ====================================*/
/*=======================================================================================================*/
/*--------------------- APPS LIST CONTROLLER --------------------                                */
.controller('HomePageCtrl', ['$location', '$scope', 'cnfg', '$templateCache', function($location, $scope, cnfg, $templateCache) {
	$scope.changePath = function(path){
		$location.path(path);
	}
	$scope.fieldModel = {
		value: `
	//returns information about app
	import {GudHub} from '@gudhub/core';
	const gudhub = new GudHub(auth_key);
	gudhub.getAppInfo(appId);`
	};
}])

