import * as settings from './gudhub_settings.js';

export const GUDHUB_SETTINGS = settings.GUDHUB_SETTINGS;

angular.module('config', [])

.run(['cnfg', function (cnfg) {

  Object.defineProperty(window.gudhub, 'development_mode', {
    set: function (value = false) {
      cnfg.development_mode = value;
    },
    get: function () {
      return cnfg.development_mode;
    }
  });

}])


/*==========================================================================================================*/
/*================================     CONVIGURATION CONTROLLER       ======================================*/
/*==========================================================================================================*/
.controller('configCtrl', ['$scope', 'cnfg', function($scope, cnfg) {

  $scope.version = {
    client: '1.0.1',
    server: servercnfg.getAppVersionApi()
  };

  /*-- Show/Hide Log Messages*/
  cnfg.initLogMessages();

}])

/*==========================================================================================================*/
/*=======================================     CONVIGURATIONS      ==========================================*/
/*==========================================================================================================*/
.service('cnfg', [ '$http', function($http) {

    /*------------------- GETTING SERVER VERSION --------------------*/
  this.getAppVersionApi = function() {
      var apiUrl = this.server_url + "/";
      return $http({
        method: 'GET',
        url: apiUrl + 'version',
      }).then(function(result) {
        scope.version.server = result.data;
        return result.data;
      });
    };

    /*------------------- INIT LOG MESSAGES --------------------*/
  this.initLogMessages = function() {
    var self = this;

    angular.forEach(this.log, function(module, key) {
      self.log[key] = module && self.development_mode;
    });
  };

  Object.assign(this, GUDHUB_SETTINGS);
  return this;

}]);
