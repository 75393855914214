require('./loader_file.scss');

angular.module('cssLoaderModule', [
  'fileManagerModule'
])


.directive('loaderFile', ['$q', '$compile', 'fileManager', 'PipeService', function($q, $compile, fileManager, PipeService) {

  return {
    restrict: 'A',
    link: async function(scope, element, attrs){
      let loader_svg = '<svg xmlns="http://www.w3.org/2000/svg" class="circular_loader" viewBox="25 25 50 50"><circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10"/></svg>';
      let element_type = element[0].tagName;
      switch (element_type) {
        case 'DIV':
          element.prepend(loader_svg);
          break;

        case 'IMG':
          element.empty().append("<div>" + loader_svg + "</div>");
          break;

        default:
          break;
      }

      PipeService.on("gh_file_update", {file_id: attrs.fileId}, generateImages)
      
      function generateImages() {
        
        fileManager.getFile(attrs.appId, attrs.fileId).then(function (file) {
          return file
        }, (err) => {
          return null
        }).then((file) => {
          if (file) {
            return new Promise((resolve, reject) => {
              let img = new Image();
              img.setAttribute('crossOrigin', 'anonymous');
              img.onload = function () {
                resolve(img);
              };

              img.onerror = function () {
                reject(angular.element('<div class="no-image" gh-icon="photo edf2f7 100% normal"></div>'));
              };
              img.src = `${file.url}?timestamp=${file.last_update}`;
            });
          } else {
            return angular.element('<div class="no-image" gh-icon="photo edf2f7 100% normal"></div>');
          }
        }).then((htmlElement) => {
          if (htmlElement.tagName === 'IMG' && attrs.loaderOuputElement === 'canvas') {
            let img = htmlElement;
            htmlElement = document.createElement('canvas');
            [htmlElement.width, htmlElement.height] = [img.width, img.height];
            htmlElement.getContext('2d').drawImage(img, 0, 0);
          }
          element.empty().append(htmlElement);
        });
      }
      generateImages();
      scope.$on('$destroy', () => PipeService.destroy('gh_file_update',{file_id: attrs.file_id}, generateImages))
    }
  };

}]);
