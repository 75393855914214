import Rete from "rete";

export class MainControl extends Rete.Control {
  constructor(emitter, key, readonly, node, settings, template, promise) {
    super(key);
    this.render = "js";
    this.key = key;
    this.node = node;
    this.settings = settings;
    this.template = template;
    this.promise = promise;
  }

  handler(el, editor, $scope, $compile) {
    let dataLink = "ghModel.nodes['" + this.node.id + "'].data";
    let settingsLink = "settingsModels['" + this.node.id + "']";
    //-- Creating Settings Options In a Scope --//
    //-- Each Node have their own settings options model.
    $scope.settingsModels[this.node.id] = this.settings;
    $scope.debugInfo.debuggers = $scope.debugInfo.debuggers || {};
    $scope.debugInfo.currentNodes = [];
    // for debugging
    if (this.promise) {
      const promise = this.promise;
      const resolve = this.promise.resolve;
      const nodeId = this.node.id
      $scope.debugInfo.debuggers[nodeId] = {
        resolve() {
          resolve();
          $scope.debugInfo.currentNodes = $scope.debugInfo.currentNodes.filter((id) => id != nodeId)
          this.promiseResolved = true;
        },
        promiseResolved: false,
        code: {},
      };
   
      this.promise.updater = (data = "No DATA") => {
        $scope.debugInfo.debuggers[nodeId].code = data;
        $scope.debugInfo.currentNodes.push(nodeId);
        // HOTFIX to disable active class in quiz settings
        if(!el.parentElement.classList.contains('quiz-form')) {
          el.parentElement.classList.add('active')
        }
        $scope.$digest();
      };

      if (!$scope.trigger || !$scope.trigger.debug) {
        $scope.debugInfo.debuggers[nodeId].resolve()
      }
    }
    $scope.nodeSettings = [
      {
        title: "Debugger",
        type: "general_setting",
        icon: "",
        columns_list: [
          [
            {
              type: "header",
              title: "Inputs data",
            },

            {
              type: "ghElement",
              property: "inputsData",
              data_model: function (fieldModel) {
                return {
                  data_model: {
                    documentData: fieldModel.inputsData,
                    interpretation: [
                      {
                        src: "form",
                        id: "json",
                        settings: {
                          editable: 1,
                          show_field_name: 0,
                          show_field: 1,
                        },
                      },
                    ],
                  },
                  field_name: "Inputs data",
                  name_space: "inputs_data",
                  data_type: "json_viewer",
                };
              },
            },
          ],
          [
            {
              type: "header",
              title: "Outputs data",
            },
            {
              type: "ghElement",
              property: "outputsData",
              data_model: function (fieldModel) {
                return {
                  data_model: {
                    documentData: fieldModel.outputsData,
                    interpretation: [
                      {
                        src: "form",
                        id: "json",
                        settings: {
                          editable: 1,
                          show_field_name: 0,
                          show_field: 1,
                        },
                      },
                    ],
                  },
                  field_name: "Outputs data",
                  name_space: "outputs_data",
                  data_type: "json_viewer",
                };
              },
            },
          ],
        ],
      },
    ];

    const defaultSettings = {
      title: 'Help',
      type: 'general_setting',
      icon: 'question',
      columns_list:[
        [
          {
            type: 'html',
            class: 'option-column_full_size',
            data_model: function () {
              return {};
            },
            control: '<gh-tool-tip model="field_model" mode="help"></gh-tool-tip>'
          }
        ]
      ]
    }
    $scope.settingsModels[this.node.id].push(defaultSettings);

    //-- Container for controls
    const container = document.createElement("div");
    container.className = "gh-node-content";
    container.setAttribute('data-node-id', this.node.id);
    el.appendChild(container);
    //-- Controls (Icon with edit button )
    let elem = angular.element(`        <gh-element ng-if="!trigger.debug"  elem-src="form"
                                                    decorator="{data_model: {appId: appId, elementId: elementId, settings: ${settingsLink}, color: 'fff', callback: init, constructorType: 'node', elementType: '${this.template.data_model.data_type}' }, data_type: 'additional_settings'}"
                                                    value="${dataLink}"/>
                                        <gh-element ng-if="trigger.debug" elem-src="form"
                                                    decorator="{data_model: {settings: nodeSettings, color: 'fff', icon: 'table' }, data_type: 'additional_settings'}"
                                                    value="debugInfo.debuggers[${this.node.id}].code"/>
                                        <span class="gh-delete-node" ng-if="!trigger.debug" ng-click="deleteNode(${this.node.id})" gh-icon="rubbish ffffff 24px normal"></span>
                                        <span class="gh-delete-node"
                                                                     ng-if="trigger.debug"
                                                                     ng-click="debugInfo.debuggers[${this.node.id}].resolve()"
                                                                     gh-icon="wmv ffffff 24px normal"></span>
                                        <span class="automation-settings-icon" gh-icon="${this.template.icon} ffffff 84px normal"></span>
                                        <div class="node-notes" >${this.node.data.notes || ''}</div>`
                              );

    //-- appending angular element
    setTimeout(() => {
      // using timeout because datalink no available in time
      const node = angular.element(container)[0].parentElement.parentElement;
      $scope.$watch('debugInfo.currentNodes', (new_value, old_value) => {
        if(old_value.includes(this.node.id)) {
          node.classList.remove('active');
        }
      });

      const compiled = $compile(elem);
      angular.element(container).append(elem);
      compiled($scope);
    }, 0);
  }
}
