/******************************************************************************************************/
/***************************************    GH-BIND-HTML    *******************************************/
/******************************************************************************************************/

/*  This directive bind html with compile scope:*/

/*   <element gh-bind-html="item.html"/>*/

/****************************************************************************************************/

angular.module('ghBindHtml', [])

.directive('ghBindHtml', [ '$compile', function($compile) {
    return {
        restrict: 'A',
        link: function (scope, element, attrs) {
                scope.$watch(function () {
                    return scope.$eval(attrs.ghBindHtml);
                }, function (value) {
                    element.html(value);
                    $compile(element.contents())(scope);
                });
            }
    };
}]);
