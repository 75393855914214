import { GUDHUB_SETTINGS } from '../../config.js';
import './integrations.scss';
angular.module('integrationsModule', [])

  .directive('integrationsList', ['GhDialog', function (GhDialog) {
    return {
      restrict: 'E',
      scope: {
        integrations: '='
      },

      controller: ['$scope', '$element', async function ($scope, $element) {
        $scope.showIntegrationScopes = function (e, service, index) {

          const deleteIcon = document.querySelectorAll('.delete_icon')[index];
          const deleteIconSvg = document.querySelectorAll('.delete_icon svg')[index];
          const deleteIconPath = document.querySelectorAll('.delete_icon svg path')[index];
          if(e.target == deleteIcon || e.target == deleteIconPath || e.target == deleteIconSvg) return;

          GhDialog.show({
            position: 'full',
            toolbar: false,
            template: {
              toolbar: '',
              resizable: false,
              content: /*html*/`
              <div class="integration-popup">
                <div class="cancel-container">
                  <span gh-icon="cross 0893d2 48px normal" ng-click="cancel()"></span>
                </div>
                <div class="integration-popup__name">
                  <img src="/user/integrations/icons/${service.service_id}.svg" />
                  <span>${service.service_id}</span>
                </div>
                <scopes-list scopes="service.scopes"></scopes-list>
              </div>`,
            },
            controller: ['$scope', function ($scope) {
              $scope.service = service;
            }]
          })
        }

        $scope.showDeleteIntegrationPopup = (integration) => {
          GhDialog.show({
            position: 'center',
            toolbar: false,
            template: {
              toolbar: '',
              resizable: false,
              content: `
              <div class="delete_group-popup">
                <div class="dialog-share-group">
                  <span gh-icon="rubbish FF0D0D 65px"></span>
                  <p class="dialog-share-group__title">Deleting Integration!</p>
                </div>
                <div class="dialog-share-group__content">
                  <p class="info">Do you really want to delete {{integration.service_id}} integration?</p>
                  <div class="dialog-share-group__content__buttons">
                    <button class="dialog-share-group__content__buttons__add btn btn-blue-reverse" ng-click="deleteIntegration()">Delete</button>
                    <button class="dialog-share-group__content__buttons__cancel btn btn-blue" ng-click="cancel()">Cancel</button>
                  </div>
                </div>
              </div>`,
            },
            locals: {
              integration,
              integrations: $scope.integrations
            },
              controller: ['$scope', 'integrations', function ($scope, integrations) {

                $scope.integration = integration;

                $scope.deleteIntegration = async function() {
                  try {
                    const token = await gudhub.getToken();

                    const data = { 
                      app_id: integration.app_id,
                      field_id: integration.field_id,
                      token,
                      service_user_id: integration.service_user_id 
                    };

	                  await fetch(`${GUDHUB_SETTINGS.node_services_api_path}/integrations/delete`, {
                      method: "POST",
                      headers: {
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify(data)
                    });

                    integrations.splice(integrations.indexOf(integration), 1);
                    $scope.$apply();

                    $scope.cancel();

                  } catch(error) {
                    console.log(error);
                  }
                }
              }]
              
          })
        }

      }],

      template: /*html*/
      
      `
      <div class="listing_table">
        <div class="listing_table_head_wrapper">
          <div class="listing_table_column bottom_line w-85">
            <div class="tab">Name</div>
          </div>
          <div class="listing_table_column w-15">
            
          </div>
        </div>
        <div class="listing_table_body_wrapper" ng-style="overflow">
          <div class="listing_table_row" ng-click="showIntegrationScopes($event, integration, $index)" ng-repeat="integration in integrations">
            <div class="listing_table_column w-85">
              <span class="number">{{$index + 1}}</span>
              <span class="icon"><img src="/user/integrations/icons/{{integration.service_id}}.svg" /></span>
              <div class="name">{{integration.service_id}}</div>
            </div>
            <div class="listing_table_column w-15">
              <span ng-click="showDeleteIntegrationPopup(integration)" class="delete_icon" gh-icon="rubbish 0d99ff 20px"></span>
            </div>

          </div>
        </div>
      </div>`
      
    }
  }])

  .directive('scopesList', [function () {

    return {
      restrict: 'E',
      scope: {
        scopes: '='
      },

      controller: ['$scope', '$element', async function ($scope, $element) {

      }],

      template: /*html*/`
      <div class="listing_table">
      <div class="listing_table_head_wrapper">
        <div class="listing_table_column scope-name bottom_line">
          <div class="tab">Name</div>
        </div>
      </div>
      <div class="listing_table_body_wrapper" ng-style="overflow">
        <div class="listing_table_row" ng-repeat="scopeItem in scopes">
          <div class="listing_table_column scope-name">
            <span class="number">{{$index + 1}}</span>
            <span class="scope-id__item">
              <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_4255_6552)">
                  <path d="M6.25 3H6V2C6 0.896973 5.10303 0 4 0C2.89697 0 2 0.896973 2 2V3H1.75C1.33667 3 1 3.3363 1 3.75V7.25C1 7.6637 1.33667 8 1.75 8H6.25C6.66333 8 7 7.6637 7 7.25V3.75C7 3.3363 6.66333 3 6.25 3ZM2.66669 2C2.66669 1.26465 3.26465 0.666687 4 0.666687C4.73535 0.666687 5.33331 1.26465 5.33331 2V3H2.66669V2Z" fill="#877230"/>
                </g>
                <defs>
                  <clipPath id="clip0_4255_6552">
                    <rect width="8" height="8" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
              <span class="id">{{scopeItem}}</span>
            </span>
          </div>

        </div>
      </div>
    </div>
      `
    }

  }]);
