export const GUDHUB_SETTINGS = {
  /*------------------------- SERVER's URL -----------------------*/
  server_url: GUDHUB_SERVER_API_SCHEME + '://' + GUDHUB_SERVER_API_HOST + '/' + GUDHUB_SERVER_API_PATH,
  ws_url: 'wss://' + GUDHUB_SERVER_API_HOST + '/' + GUDHUB_SERVER_API_PATH,
  node_services_api_path: GUDHUB_NODE_SERVICES_SCHEME + '://' + GUDHUB_NODE_SERVICES_HOST + GUDHUB_NODE_SERVICES_API_PATH,
  node_services_host: GUDHUB_NODE_SERVICES_SCHEME + '://' + GUDHUB_NODE_SERVICES_HOST,
  file_server_url: GUDHUB_FILE_SERVER_SCHEME + '://' + GUDHUB_FILE_SERVER_HOST + ':' + GUDHUB_FILE_SERVER_PORT,
  async_modules_path: 'build/' + GUDHUB_VERSION + '/async_modules/',
  automation_modules_path: 'build/' + GUDHUB_VERSION + '/automation_modules/',
  /*------------------------- DEVELOPMENT MODE -----------------------*/
  /*-- In dev mode we don't remove directives tag*/
  app_help: {
      app_id: 26750,
      field_id_map: 702554,
      field_id_link: 603747,
      field_id_parent: 603748,
      field_id_slug: 603745
  },
  maxDuplicatedFiles: 10,
  development_mode: GUDHUB_SETTINGS_DEVELOPMENT_MODE,
  show_context_menu: GUDHUB_SETTINGS_SHOW_CONTEXT_MENU,
  enable_websockets: ENABLE_WEBSOCKETS == WEBPACK_DEFINE_PLUGIN_TRUE,
  log: {
    /*-- main.js*/
    MainCtrl: true,
    /*-- app/app_controller.js*/
    ApplicationCtrl: true,
    /*-- app/data_interpreter.js*/
    interpritate: true,
    /*-- apps_manager/app_processor.js*/
    appsStorage: true,
    /*-- apps_manager/applications_contrl.js*/
    HomePageCtrl: true,
    /*-- web/web_pages_contrl.js*/
    WebPageCtrl: true,
    /*-- apps_manager/app_processor.js*/
    appDataProcesingService: true,
    applicationApi: true,
    /*-- apps_manager/file_manager.js*/
    fileStorage: true,
    fileManager: true,
    /*-- app/form/form_controller.js*/
    FormCtrl: true,
    formService: true,
    FormDirectives: true,
    /*-- app/form/item_processor.js*/
    itemDataProcessing: true,
    itemScope: true,
    itemApi: true,
    /*-- user/authorization_contrl.js*/
    AuthorizationCtrl: true,
    authService: true,
    authApi: true,
    /*-- storage/main_storage.js*/
    storage: true,
    /*-- gui/gh_itemUpdate.js*/
    ghItemUpdate: true
  },
  //sentry related
  sentry_dsn: SENTRY_DSN,
};