import './task_automation.scss';
import '../api/api_automation.scss';
import "/gh_elements/trigger/gh_trigger_switch.scss";
angular.module('taskElementsTableModule', [])

  .directive('taskElementsTable', ['GhDialog', 'cnfg', function (GhDialog, cnfg) {
    return {
      restrict: 'E',
      scope: {
        taskList: '='
      },

      controller: ['$scope', '$element', async function ($scope, $element) {

        $scope.taskListWithApp = [];
        for(let i = 0; i < $scope.taskList.length; i++) {
          const app = await gudhub.getAppInfo($scope.taskList[i].app_id);
          $scope.taskListWithApp.push($scope.taskList[i]);
          $scope.taskListWithApp[i].app = app;
          $scope.$digest();
        }


        $scope.updateTask = async (task) => {
          try {
            const taskClone = JSON.parse(angular.toJson( task ));//-- here we removing $$hashKey properties from json;
            delete taskClone.app;

            await fetch( cnfg.node_services_api_path + '/tasks/save', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json;charset=utf-8'
              },
              body: JSON.stringify(taskClone)
            });

          } catch(e) {
            console.log(e);
          }

        };

        $scope.toggleTask = (task) => {
          if(task.running) {
            $scope.stopTask(task);
          }
          else {
            $scope.runTask(task);
          }
        }

        $scope.runTask = async (task) => {
          const address = {
            app_id: task.app_id,
            item_id: task.item_id,
            field_id: task.field_id
          }

          console.log('Task is running...');
          const response = await fetch(`${cnfg.node_services_api_path}/tasks/run`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(address)
          });

          task.running = true;
          $scope.$apply();
        }
  
        $scope.stopTask = async (task) => {
          const address = {
            app_id: task.app_id,
            item_id: task.item_id,
            field_id: task.field_id
          }
          
          console.log('Task is stopping...');
          const response = await fetch(`${cnfg.node_services_api_path}/tasks/stop`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(address)
          });

          task.running = false;
          $scope.$apply();
        }

        $scope.toggleSchedule = async (task) => {
          if(task.status == 'scheduled') {
            $scope.unscheduleTask(task);
          } else {
            $scope.scheduleTask(task);
          }
        }

        $scope.scheduleTask = async (task) => {
          const address = {
            app_id: task.app_id,
            item_id: task.item_id,
            field_id: task.field_id
          };

          const response = await fetch(`${cnfg.node_services_api_path}/tasks/schedule`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ ...address, schedule: task.schedule })
          });
          
          const data = await response.json();
          task.active = true;
  
          if(data.message === 'success') {
            $scope.status = 'scheduled';
            $scope.$apply();
          }
        }
  
        $scope.unscheduleTask = async (task) => {
          const address = {
            app_id: task.app_id,
            item_id: task.item_id,
            field_id: task.field_id
          };

          const response = await fetch(`${cnfg.node_services_api_path}/tasks/unschedule`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(address)
          });
          
          const data = await response.json();
          task.active = false;
  
          if(data.message === 'success') {
            $scope.status = 'created';
            $scope.$apply();
          }
        }

        $scope.openDeleteDialogPopup = function (task) {
          GhDialog.show({
            position: 'center',
            toolbar: false,
            template: {
              toolbar: '',
              resizable: false,
              content: `
              <div class="delete_group-popup">
                <div class="dialog-share-group">
                  <span gh-icon="rubbish FF0D0D 65px"></span>
                  <p class="dialog-share-group__title">Deleting Task!</p>
                </div>
                <div class="dialog-share-group__content">
                  <p class="info">Do you really want to delete task? You will not be able to restore it.</p>
                  <div class="dialog-share-group__content__buttons">
                    <button class="dialog-share-group__content__buttons__add btn btn-blue-reverse" ng-click="deleteTask()">Delete</button>
                    <button class="dialog-share-group__content__buttons__cancel btn btn-blue" ng-click="cancel()">Cancel</button>
                  </div>
                </div>
              </div>`,
            },
            locals: {
              task,
              tasks: $scope.taskListWithApp,
              tasksScope: $scope
            },
              controller: ['$scope', 'tasks', 'tasksScope', function ($scope, tasks, tasksScope) {

                $scope.deleteTask = async function() {
                  try {
                    await fetch(`${cnfg.node_services_api_path}/tasks/delete`, {
                      method: 'POST',
                      headers: {
                        'Content-Type': 'application/json'
                      },
                      body: JSON.stringify({app_id: task.app_id, item_id: task.item_id, field_id: task.field_id })
                    });
                    tasks.splice(tasks.indexOf(task), 1)
                    tasksScope.$apply();
                    $scope.cancel();
                  } catch(error) {
                    console.log(error);
                  }
                }
              }]
              
          })
        }
        
        $scope.showAutomationDialog = (task) => {

          GhDialog.show({
            position: 'bottom',
            toolbar: true,
            template: {
              toolbar: `
              <div class="task-settings__toolbar">
                <div ng-click="apply()" class="gh-automation-apply-button action gh-btn inline btn-blue gh-btn-bg"><p>Apply</p></div>
                <div class="name">
                  <gh-input class="name" placeholder="Task name" ng-model="task.name"/>
                </div>
                <div gh-element value="task.schedule" decorator="{data_model:{ show_seconds: false, interpretation:[{src: 'form', id: 'name', settings: {show_field_name:false}}]},data_type: 'cron_picker'}"></div>
              </div>
              `,
              resizable: false,
              content: `
                <div class="gh-element-automation">
                
                  <gh-automation gh-model="task.model" debug-info="debugInfo" nodes-model="nodesModel"></gh-automation>
                
                </div>
              `,
            },

            locals: {
              task,
              $parentScope: $scope
            },

            controller: ['$scope', 'task', '$parentScope', function ($scope, task, $parentScope) {

              $scope.task = task;

              $scope.debugInfo = {
                debuggers: {
  
                },
                currentNodes: []
              }

              $scope.nodesModel = {
                include_nodes: [
                  ...gudhub.storage.getModulesList('automation', false),
                  {
                    name: "Task",
                    data_type: "Task",
                    icon: 'automation_calculator',
                  }
                ]
              }

              $scope.apply = async function() {
                await $parentScope.updateTask($scope.task);
                $scope.hide();
              }

            }]
          })
        };
      }],

      template: /*html*/
      
      `
      <div class="automation_table api_table">
      <div class="listing_table">
        <div class="listing_table_head_wrapper">
          <div class="listing_table_column bottom_line w-25">
            <div class="tab">Name</div>
          </div>
          <div class="listing_table_column bottom_line w-25">
            <div class="tab">Application</div>
          </div>
          <div class="listing_table_column w-15">
            <div class="tab">Scheduling</div>
          </div>
          <div class="listing_table_column w-15">
            <div class="tab">Status</div>
          </div>
          <div class="listing_table_column w-15">
            
          </div>
          <div class="listing_table_column w-5">
            
          </div>
        </div>
        <div class="listing_table_body_wrapper">
        
          <div class="listing_table_row" ng-repeat="task in taskListWithApp">
          <div class="wrapper">
            <div class="listing_table_column w-25 task_name">
              <div class="name">{{task.name}}</div>
            </div>
            <div class="listing_table_column w-25 app_name">
              <span class="app_icon" gh-icon="{{task.app.icon.icon_id}} {{task.app.icon.icon_color}} 40px normal {{task.app.icon.gradient_up}} {{task.app.icon.gradient_down}}"></span>
              <div class="name">{{task.app.app_name}}</div>
            </div>
            <div class="listing_table_column w-15">
                <span>{{task.schedule}}</span>
            </div>
            <div class="listing_table_column w-15">
              <div class="status">
                <gh-boolean class="gh-api-btn-switch" ng-click="toggleSchedule(task)" gh-model="task.active" gh-mode="switch" editable="true"></gh-boolean>
              </div>
            </div>
            <div class="listing_table_column w-5">
                <span ng-click="showAutomationDialog(task)" class="edit_icon" gh-icon="pencil 0d99ff 20px"></span>
            </div>
            <div class="listing_table_column w-15">
              <span class="gh-btn gh-btn-bg button" ng-class="{'run': !task.running, 'stop': task.running}" ng-click="toggleTask(task)">{{task.running ? 'STOP' : 'RUN'}}</span>
            </div>
            <div class="listing_table_column w-5">
              <span class="delete" gh-icon="rubbish 0d99ff 20px" ng-click="openDeleteDialogPopup(task)">Delete</span>
            </div>
        </div>
        
      </div>
      </div>`
      
    }
  }]);
