export default {
  private: false,
  template_name: 'Dropdown',
  allowed_types: [
    'gh_element'
  ],
  model: {
    template_id: 'gh_dropdown',
    name: 'Dropdown',
    icon_id: 'dropdown',
    class: 'gh_col_height_0 gh_height_percent gh_width_percent vertical_scroll gh_col_width_0',
    priority: 0,
    container_id: 0,
    content: [
      {allowed_types: ['gh_element'], template_id: 'gh_float', name: '', icon_id: '', class: '', priority: 0, container_id: 0, content: []}
    ]
  },
  container_settings: [{
    group: 'Width',
    name: 'Width +',
    icon: 'plus',
    function: 'sizeChange',
    arg: 'width plus'
  }, {
    group: 'Width',
    name: 'Width -',
    icon: 'minus',
    function: 'sizeChange',
    arg: 'width minus'
  }, {
    group: 'Width',
    name: 'Width Clear',
    icon: 'rubbish',
    function: 'sizeChange',
    arg: 'width clear'
  },{
    name: 'Delete Dropdown',
    icon: 'rubbish',
    function: 'deleteContainer'
  }],
  html_editor:
    /*html */`
    <div class="gh_dropdown" ng-init="lists = item.content;">
      <div class="edit_template_container_form">
        <pencil-container-settings parent="$parent.lists" lists="item.content" item="item"></pencil-container-settings>
      </div>
      <div class="dropdown_wrapper" ng-click="opened = !opened;" ng-class="{opened: opened}">

      <span ng-click="setIcon(item)" class="gh_tabs_block_nav_btn_icon" gh-icon="{{item.icon_id ? item.icon_id : 'box'}} 0D99FF 30px normal"></span>
      <input class="gh_dropdown_name" type="text" ng-model="item.name"/>
      <span class="caret" gh-icon="bot_arrow 7f7f7f 15px normal"></span>
      
      <div class="dropdown_elements">
        <div class="drag_placeholder" ng-show="!item.content[0].content.length">
          <span> Drag Element </span>
        </div>
        <div ng-class="item.class" class="container_wrapper" ng-repeat="item in lists" ng-include="item.template_id + '_editor'"></div>
      </div>
      </div>
    </div>
    `,
  html_form: function (value, func) {

    //set default icon
    if (value.class.indexOf('gh_icon') > 0) {
      if(value.icon_id.length == 0) {
        value.icon_id = 'dropdown';
      }
    }
    return `
      <div class="dropdown_element ${ value.class }">
        ${ value.icon_id || value.name ?
          `<div class="template_icon_title_block_form">
            ${ value.icon_id ? `<span class="template_icon_form" gh-icon="${ value.icon_id } 86BEF6 25px normal"></span>` : `` }
            ${ value.name ? `<span class="template_title_form">${ value.name }</span>` : `` }
            <span class="caret" gh-icon="bot_arrow bbbcc5 15px normal"></span>
            </div>` : ``
        }
        <div class="dropdown_element_childs">${ func(value.content) }</div>
        </div>`;
  },
  icon: 'dropdown'
};
