angular.module('editTemplateService', [])

//***********************************************************************************//
//******************************* SETTING GROUP *************************************//
//***********************************************************************************//
.directive("settingGroup",[function() {
    return {
      
        controller: [ '$scope', '$element', function($scope, $element) {
            $scope.settingsClone = $scope.settings.filter((element, index, array) => {
                if(element.group) {
                    element.icon =  element.icon.indexOf(" app ") > 0 ? element.icon : element.icon + " 0fb5ff 20px none";
                    return element;
                }
            })
              function groupBy(arr) {
                let groupedArr = [];
                  arr.forEach(item => {
                     if(groupedArr.findIndex(el => el.group === item.group) < 0) {
                       groupedArr.push({
                         group: item.group,
                         settings: []
                       })
                     }
                    groupedArr[groupedArr.findIndex(el => el.group === item.group)].settings.push(item);
                  });
                return groupedArr;
              }
              
              $scope.settingsClone = groupBy($scope.settingsClone);
            
        }],
        
        template: `
        <ul class="setting_group">
            <li ng-if="group.group" ng-repeat="group in settingsClone">
            <span class="group_name">{{group.group}}</span>
                <ul class="setting_group-item">
                    <li>
                        <span title="{{setting.name}}" ng-click="functionRun(setting.function, setting.arg)" ng-show="showItem(setting)" ng-class="{'active': activeItem(setting)}" ng-repeat="setting in group.settings" gh-icon="{{setting.icon}}"></span>
                    </li>
                </ul>
            </li>
        </ul>`
    }
}])





/** ------------------------------------------------------------------------------------- */
/** ----------------------------- PENCIL CONTAINER SETTINGS ----------------------------- */
/** ------------------------------------------------------------------------------------- */
/** DIRECTIVE FOR EDITING CONTAINERS
 *  Parameters:
 *  "parent" - Parent array containers
 *  "item" - Current container in parent array
 *  "lists" - Children of current container
 **/
/** ------------------------------------------------------------------------------------- */
.directive("pencilContainerSettings", ['$animate', function($animate){
    let directive = {
        template:
        `<div ng-mouseenter="mouseEnterTarget($event)" class="icon_pencil_container">
            <span gh-icon="pencil 86BEF6 23px normal"></span>
            <ul ng-style="position" class="icon_container">
                <setting-group></setting-group>
                <li class="icon_container-item" ng-if="!setting.group" ng-repeat="setting in settings" ng-show="showItem(setting)">
                    <div ng-click="functionRun(setting.function, setting.arg)"><span gh-icon="{{setting.icon}} 0fb5ff 20px none"></span>{{setting.name}}</div>
                </li>
            </ul>
        </div>`,
        replace: false,
        restrict: 'E',
        controller: controller,
        link: link,
        scope: {
            parent: '=',
            item: '=',
            lists: '='
        }
    };

    controller.$inject = ['$scope', 'viewTemplatesStorage','formEditorService', '$element'];

    function controller($scope, viewTemplatesStorage, formEditorService, $element){
        $animate.enabled(false, $element); /** Disable animation */

        /** Get settings for current container */
        angular.forEach(viewTemplatesStorage.getContainersTemplates(), function(template){
            if(template.default.model.template_id == $scope.item.template_id){
                $scope.settings = angular.copy(template.default.container_settings);
            }
        });

        /** Check if the button should be displayed */
        $scope.showItem = function (setting) {
            let show = false;
            let classes = $scope.item.class.split(" ");

            if(setting.hasOwnProperty('hide_if')){
                show = classes.indexOf(setting.hide_if) < 0;
            } else if(setting.hasOwnProperty('show_if')){
                show = classes.indexOf(setting.show_if) >= 0;
            } else {
                show = true;
            }

            return show;
        };

        /** Check if the button should be active */
        $scope.activeItem = function (setting) {
            let active = false;
            let classes = $scope.item.class.split(" ");

            if(setting.hasOwnProperty('active_if')){
                active = classes.indexOf(setting.active_if) > 0 ? true : false;
            }

            return active;
        };

        /** Running function for the button */
        $scope.functionRun = function (functionName, arg) {
            try {
                $scope[functionName](arg);
            } catch (err) {}
        };

        /** Add css_class to container */
        $scope.addClass = function (arg) {
            let classes = $scope.item.class.split(" ");

            classes.push(arg);
            $scope.item.class = classes.join(" ");
        };

        /** Remove css_class to container */
        $scope.removeClass = function (arg) {
            let classes = $scope.item.class.split(" ");
            let index = classes.indexOf(arg);

            classes.splice(index, 1);
            $scope.item.class = classes.join(" ");
        };

        /** Delete current container */
        $scope.deleteContainer = function () {
            $scope.parent.splice($scope.parent.indexOf($scope.item), 1);
        };

        /** Add container in children list current container */
        $scope.addContainer = function (arg) {
            let obj = JSON.parse(arg);
            $scope.lists.unshift(obj);
            formEditorService.AddAllowedTypes($scope.item)
        };

        /** Replace CSS class from current container */
        $scope.replaceClass = function (arg) {
            let obj = JSON.parse(arg);
            let classes = $scope.item.class.split(" ");

            angular.forEach(obj.deleteClass, function (value) {
                angular.forEach(classes, function (valueFind, key) {
                    if(valueFind == value){
                        classes.splice(key, 1);
                    }
                });
            });

            if(obj.addClass) classes.push(obj.addClass);

            $scope.item.class = classes.join(" ");
        };

        /** Size Change */
        $scope.sizeChange = function (arg) {
            let settings = arg.split(" ");
            let classes = $scope.item.class.split(" ");

            angular.forEach(classes, function (value, key) {
              let class_container = value.split("_");

              if(class_container[2] == settings[0]){
                switch (settings[1]){
                  case "plus":
                    class_container[3] = Number(class_container[3]) + 1 > 12 ? 12 : Number(class_container[3]) + 1;
                    break;

                  case "minus":
                    class_container[3] = Number(class_container[3]) - 1 < 0 ? 0 : Number(class_container[3]) - 1;
                    break;

                  case "clear":
                    class_container[3] = 0;
                    break;
                }

                classes[key] = class_container.join("_");
              }
            });

            $scope.item.class = classes.join(" ");
        };

         /** Add Vertical scroll*/
         $scope.verticalScroll = function (arg){
            
            let classes = $scope.item.class.split(" ");
             
            if(classes[classes.length - 1] == arg){
                // console.log("classes[classes.length - 1]",classes[classes.length - 1]);
                classes.pop();
                $scope.item.class = classes.join(" ");
            }
         }

        /** Add Horizontal scroll*/
        $scope.horizontScroll = function (arg){
            let classes = $scope.item.class.split(" ");

            if(classes[classes.length - 1] == arg){

                let classes = $scope.item.class.split(" ");
                classes.push(arg);
                $scope.item.class = classes.join(" ");
                console.log("truuuuuu",classes);
            }
         }
    }

    link.$inject = ['scope', 'element'];

    function link(scope, element) {
        scope.position = {
            'top': '0px',
            'left': '0px'
        };

        scope.mouseEnterTarget = function (event) {
            let height_element = element[0].querySelector(".icon_container").clientHeight;
            let height_window = window.innerHeight;

            if(event.clientY + height_element < height_window){
                scope.position.top = event.clientY + "px";
                scope.position.left = event.clientX + "px";
            } else {
                scope.position.top = height_window - height_element + "px";
                scope.position.left = event.clientX + "px";
            }
        };
    }

    return directive;
}])






//******************************************************//
//*************** FORM EDITOR SERVICE ******************//
//******************************************************//
.service('formEditorService', ['viewTemplatesStorage', '$q', 'PipeService', '$filter', function(viewTemplatesStorage, $q, PipeService, $filter){
    let elements_existing = [];
    let check_element = false;

    this.initDataFromWindowScope = function(data) {
        this.windowScope = data;
    }

    this.addElementToExisting = function (element) {
        this.windowScope.elements_existing.push(element)
    }


    this.setPriority = function (views) {
        this.crawling(views, function (prop, value, obj) {
          if(prop == 'content'){
              angular.forEach(value, function (val, key) {
                val['priority'] = key;
              });
          }
        });

        return views;
    };


    this.sortPriority = function (views) {
      (function element_sort(views) {
        angular.forEach(views, function(view){
          if(view.content.length > 0){
            view.content = $filter('orderBy')(view.content, 'priority');
            element_sort(view.content);
          }
        });
      })(views);

      return views;
    };


    /** Delete element from view, when delete element from existing fields */
    this.deleteElementFromView = function (views, element_id) {
        let elementsForDel = [];
        let self = this;

        this.crawling(views, function (prop1, value1, parentObj) {
            let parentObjForDel = {
                parent: [],
                children: {}
            };

            if (prop1 == "element_id" && value1 == element_id) {
                parentObjForDel.children = parentObj;

                //-- Crawling through object to find parent of parentObj
                self.crawling(views, function (prop2, value2, obj) {
                    if (parentObj == value2) {
                        parentObjForDel.parent = obj;
                        elementsForDel.push(parentObjForDel);
                    }
                });
            }
        });

        angular.forEach(elementsForDel, function(value) {
            angular.forEach(value.parent, function(children) {
                if(value.children == children){
                    let index = value.parent.indexOf(children);
                    value.parent.splice(index, 1);
                }
            });
        });
    };


    this.deleteElementFromExistingAndViews = function (model) {
        return new Promise(resolve => {
            let address = {app_id: model.app_id, field_id: model.element_for_delete.field_id};

            PipeService.on('gh_model_delete', address, function deleteField(event, status) {
                PipeService.destroy('gh_model_delete', address, deleteField);

                if(status){
                    let current_element = model.elements_existing.find(function (element) {
                        return element.element_id == model.element_for_delete.element_id;
                    });

                    let index = model.elements_existing.indexOf(current_element);

                    model.elements_existing.splice(index, 1);

                    resolve();
                }
            }).emit('gh_model_delete', {}, address);

        })
        
    };


    /** Delete container from view */
    this.deleteContainerFromView = function (views, container_id) {
        let elementsForDel = [];
        let self = this;

        this.crawling(views, function (prop1, value1, parentObj) {
          let parentObjForDel = {
            parent: [],
            children: {}
          };

          if (prop1 == "container_id" && value1 == container_id) {
            parentObjForDel.children = parentObj;

            //-- Crawling through object to find parent of parentObj
            self.crawling(views, function (prop2, value2, obj) {
              if (parentObj == value2) {
                parentObjForDel.parent = obj;
                elementsForDel.push(parentObjForDel);
              }
            });
          }
        });

        angular.forEach(elementsForDel, function(value) {
          angular.forEach(value.parent, function(children) {
            if(value.children == children){
              let index = value.parent.indexOf(children);
              value.parent.splice(index, 1);
            }
          });
        });
    };


    /** ------------------------------------------------------------------------------ */
    /** Checking for the addition of an element in existing for exceptions duplication */
    /** ------------------------------------------------------------------------------ */

    /** Use when start drag element from new_field */
    this.dragStart = function () {
        check_element = true;
    };


    /** Use when element drop on "views_list" */
    this.dropElement = function () {
        check_element = false;
    };


    /** Look on 'check_element' and delete new_field from existing fields if drop NOT "views_list" */
    this.elementCheck = function (id) {
        if(check_element){
            angular.forEach(elements_existing, function(element, key){
                if(element.create_field == id){
                    del(key);
                }
            });
        }

        function del(counter) {
            elements_existing.splice(counter, 1);
        }
    };


    /** Save all elements_existing */
    this.saveElementsModel = function(elementsArr){
        elements_existing = elementsArr;
    };


    /** Find by 'element_id' in elements_existing */
    this.getElementModel = function(element_id){
        let element_model = {};

        angular.forEach(elements_existing, function(element){
            if(element.field_id == element_id){
                element_model = element;
            }
        });

        return element_model;
    };


    /** Clear views for update */
    this.clearViews = function (views_old) {
        let views_new = angular.copy(views_old);
        let props = ['allowed_types'];
        let self = this;

        angular.forEach(props, function(prop){
            self.crawling(views_new, function(prop1, value1, parent1){
                if (prop1 == prop) {
                    delete parent1[prop1];
                }
            });
        });

        return views_new;
    };



    /** ------------------------------ ADD ALLOWED TYPES FOR DND ------------------------------ */
    /** Here we add "allowed_types" parameter to containers for all views in "views_list" array */
    this.AddAllowedTypes = function(views_list){
        this.crawling(views_list, function(prop, value, parent){
            if (prop == "template_id") {
                let allowedTypes = viewTemplatesStorage.getAllowedTypes(value, parent);
                parent.allowed_types = allowedTypes;
            }
        });
    };



    /** ---------------------------------- CRAWLER ------------------------------ */
    /** Crawler is used to walk through Json tree and do some changes there
     *  to make some changes in the Json we use action() function that passed as argument to crawler
     */
    this.crawling = function(object, action) {
        let properties = Object.keys(object); /** <--- Array of object properties */
        let self = this;

        /** We start crawling in case if object has properties */
        if (properties.length > 0) {
            angular.forEach(properties, function(prop) {
                let subObject = object[prop];

                action(prop, subObject, object); /** <--- Action sends as argument to crawler */

                /** We won't go deeper into object if it's a string
                 *  because string will be defined by 'Object.keys' as an array of letters
                 */
                if (angular.isDefined(subObject) && typeof object != 'string') {
                    self.crawling(subObject, action);
                }
            });
        }
    };
}])