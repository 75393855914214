angular.module('ghBooleanModule', [])

.directive('ghBoolean', [function() {
  var directive = {};

  directive.scope = {
    ghModel: '=',
    ghMode: '@',
    editable: '='
  };

  directive.controller = ['$scope', function($scope) {

    // This need, because boolean value save in server database in string format
    Object.defineProperty($scope, 'boolValue', {
      get: function () {
        return Number($scope.ghModel);
      },
      set: function (newValue) {
        if(newValue != $scope.ghModel){
          $scope.ghModel = newValue;
        }
      }
    });

    $scope.options = [
      {
        name: 'Yes',
        value: 1,
        color:'green'
      },{
        name:'No',
        value: 0,
        color:'red'
      }
    ];

    $scope.changeValue = function(newVal) {
      if($scope.editable && newVal != $scope.ghModel){
        $scope.ghModel = newVal;
      }
    };

    $scope.isSelected = function(val) {
      return $scope.ghModel == val;
    };
  }];

  directive.template =
    '<div ng-switch="ghMode">'+
    '   <div ng-switch-when="current_value">'+
    '     <ul>' +
    '       <li ng-repeat="option in options | booleanValue : ghModel : ghMode" ng-click="changeValue(option.value)" class="boolean-button-item"  style="padding: 10px 15px; color: #626262">' +
    '         <span>{{option.name}}</span>' +
    '       </li>' +
    '     </ul>'+
    '   </div>'+
    '   <div ng-switch-when="switch" class="gh_switch_padd">'+
    '     <md-switch ng-disabled="{{!!!editable}}" ng-model="boolValue" aria-label="boolSwitch" ng-true-value="1" ng-false-value="0"></md-switch>'+
    '   </div>'+
    '   <div ng-switch-when="checkbox">'+
    '     <md-checkbox ng-disabled="{{!!!editable}}" ng-model="boolValue" aria-label="boolCheckbox"  ng-true-value="1" ng-false-value="0"></md-checkbox>'+
    '   </div>'+
    '</div>'
    ;

  return directive;
}])

  .filter('booleanValue', function() {
    return function (options, currentValue, interpreterType) {
      if(options.length && interpreterType == 'current_value'){
        return options.filter(function (opt) {
          return Number(opt.value) == currentValue;
        });
      } else {
        return options;
      }
    };
  });
