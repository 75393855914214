export default {
  private: false,
  template_name: 'Tabs',
  allowed_types: [
    'gh_float'
  ],
  model: {
    template_id: 'gh_tabs',
    name: '',
    icon_id: '',
    class: 'gh_tabs gh_title gh_nav_tabs_top',
    priority: 0,
    container_id: 0,
    content: []
  },
  container_settings: [{
    name: 'Add Tab',
    icon: 'plus',
    function: 'addContainer',
    arg: '{"allowed_types": ["gh_float", "gh_element", "gh_tabs"], "template_id": "gh_float", "name": "", "icon_id": "", "class": "gh_float gh_col_width_12 gh_col_height_0 gh_height_percent gh_width_percent vertical_scroll", "priority": 99, "container_id": 0, "content": []}'
  }, {
    name: 'Delete Container',
    icon: 'rubbish',
    function: 'deleteContainer'
  }, {
    name: 'Show Title',
    icon: 'plus',
    hide_if: 'gh_title',
    function: 'addClass',
    arg: 'gh_title'
  }, {
    name: 'Hide Title',
    icon: 'rubbish',
    show_if: 'gh_title',
    function: 'removeClass',
    arg: 'gh_title'
  }, {
    name: 'Show Icon',
    icon: 'plus',
    hide_if: 'gh_icon',
    function: 'addClass',
    arg: 'gh_icon'
  }, {
    name: 'Hide Icon',
    icon: 'rubbish',
    show_if: 'gh_icon',
    function: 'removeClass',
    arg: 'gh_icon'
  }, {
    name: 'Align Top',
    icon: 'top_arrow',
    function: 'replaceClass',
    arg: '{"addClass" : "gh_nav_tabs_top", "deleteClass" : ["gh_nav_tabs_bottom", "gh_nav_tabs_left", "gh_nav_tabs_right"]}'
  }, {
    name: 'Align Bottom',
    icon: 'bot_arrow',
    function: 'replaceClass',
    arg: '{"addClass" : "gh_nav_tabs_bottom", "deleteClass" : ["gh_nav_tabs_top", "gh_nav_tabs_left", "gh_nav_tabs_right"]}'
  }, {
    name: 'Align Left',
    icon: 'arrow_left',
    function: 'replaceClass',
    arg: '{"addClass" : "gh_nav_tabs_left", "deleteClass" : ["gh_nav_tabs_bottom", "gh_nav_tabs_top", "gh_nav_tabs_right"]}'
  }, {
    name: 'Align Right',
    icon: 'arrow_right',
    function: 'replaceClass',
    arg: '{"addClass" : "gh_nav_tabs_right", "deleteClass" : ["gh_nav_tabs_top", "gh_nav_tabs_left", "gh_nav_tabs_bottom"]}'
  }],
  html_editor: `
    <div class="gh_tabs_block">
      <div class="edit_template_container_form">
        <pencil-container-settings parent="$parent.lists" lists="item.content" item="item"></pencil-container-settings>
      </div>

      <div class="gh_tabs_align">
        <div class="gh_tabs_block_nav" ng-init="lists = item.content; $parent.tab_id = 0">

          <span class="gh_tabs_block_nav_btn" ng-class="{'--active_tab' : $parent.tab_id == $index}" ng-click="$parent.tab_id = $index" ng-repeat="item in lists">
            <span ng-click="setIcon(item)" class="gh_tabs_block_nav_btn_icon" gh-icon="{{item.icon_id ? item.icon_id : 'box'}} a7a9b3 30px normal"></span>
            <input class="gh_tabs_block_nav_btn_title" ng-model="item.name" placeholder="New Tab"/>
            <span ng-click="lists.splice($index, 1)" class="gh_tabs_block_nav_btn_icon --delete_tab" gh-icon="cancel a7a9b3 20px normal"></span>
          </span>

          <span class="gh_tabs_block_nav_btn" ng-click="lists.push({allowed_types: ['gh_float', 'gh_element', 'gh_tabs'], template_id: 'gh_float', name: '', icon_id: '', class: 'gh_float gh_col_width_12 gh_col_height_0 gh_height_percent gh_width_percent vertical_scroll', priority: lists.length, container_id: 0, content: []})">
            <span class="gh_tabs_block_nav_btn_icon --add_tab" gh-icon="plus_in_circle a7a9b3 30px normal"></span>
          </span>

          <div style="clear:both"></div>
        </div>

        <div class="gh_tabs_block_list" ng-init="lists = item.content">
          <div ng-if="$parent.tab_id == $index" class="gh_tabs_block_list_item" ng-class="item.class" ng-repeat="item in lists" ng-include="item.template_id + '_editor'"></div>
        </div>

        <div style="clear:both"></div>
      </div>
    </div>`,
  html_form: function (value, func) {
    let tab_menu = '';
    let tabs = '';

    angular.forEach(value.content, function (item, key) {
      tab_menu += `
        <span class="gh_tabs_block_nav_btn"
            ng-click="this['tab_id' + ${ value.container_id }] = ${ key }"
            ng-class="{'--active_tab' : this['tab_id' + ${ value.container_id }] == ${ key }}">
          <span class="gh_tabs_block_nav_btn_icon" gh-icon="${ item.icon_id ? item.icon_id : 'box' } a7a9b3 30px normal"></span>
          <span class="gh_tabs_block_nav_btn_title">${ item.name }</span>
        </span>`;

      tabs += `<div ng-if="this['tab_id' + ${ value.container_id }] == ${ key }" class="gh_tabs_block_list_item">${ func(item.content) }</div>`;
    });

    return `
      <div ng-init="this['tab_id' + ${ value.container_id }] = 0" class="${ value.class }">
        <div class="gh_tabs_block_nav">
          ${ tab_menu }
        </div>

        <div class="gh_tabs_block_list">
          ${ tabs }
        </div>
      </div>`;
  },
  icon: 'tabs'
};
