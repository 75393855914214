import './gh_automation.scss';
import 'regenerator-runtime/runtime';//rete uses globals from here
import Rete from "rete";
import { AutomationInstanceCreator  } from './AutomationInstanceCreator.js';
import ConnectionPlugin from 'rete-connection-plugin';
import VueRenderPlugin from 'rete-vue-render-plugin';

angular.module('ghAutomationModule', [
])

  .directive('ghAutomation', [function() {

    return{
      restrict: 'E',

      scope: {
        ghModel: '=', // Checked value
        nodesModel: '=',//!!!!!!!!!!! DO NOT FORGER TO ADD COMPONETS IN DIRERECTIVE ATRIBUTE !!!!!!!!!!//
        appId: '=',
        elementId: '=',
        trigger: '=',
        init: '=?',
        hideCatalog: '=?',
        debugInfo: "=?"
      },

      template: /*html*/`
        <div class="gh-automation-settings">
          <automation-container ng-if="!hideCatalog" class="automation-container" elements-catalog="elementsCatalog" components="components"></automation-container>
          <div ng-if="!hideCatalog" class="gh-automation-controls">
            <div class="gh-automation-controls__item">
              <span ng-click="copy()" gh-icon="double_plus 0D99FF 40px normal"></span>
              <span class="text">{{ copyText }}</span>
            </div>
            <div class="gh-automation-controls__item">
              <span ng-click="paste()" gh-icon="file 0D99FF 40px normal"></span>
              <span class="text">{{ pasteText }}</span>
            </div>
          </div>
          <div id="gh-automation-render"></div>
        </div>`,

      controller: [ '$scope', '$element', '$attrs', 'GhDialog', '$compile', '$timeout',  function($scope, $element, $attrs, GhDialog, $compile, $timeout) {
        $scope.settingsModels = {};//Here we seting models for rendering modules
        $scope.filter_ars = {'constructor':'node'}
        console.log('work');
        $scope.copyText = 'Copy';
        $scope.pasteText = 'Paste';

        $scope.init = async function(){
          //-- we use timeout to make querySelector work, link function doesn't work here becouse it requires aditional hacks with scope update
          $scope.components = []; // List of availabe components  
          const container = $element[0].querySelector('#gh-automation-render');
          const editor = new Rete.NodeEditor('trigger@0.1.0', container);
          const engine = new Rete.Engine('trigger@0.1.0');
          //---- Components Initialization ---//

          $scope.editor = editor;

          //---- Ading Plugins ---//
          const JsRenderPlugin = {
            install(editor, params = {}) {
              editor.on("rendercontrol", ({ el, control }) => {
                if (control.render && control.render !== "js") return;
                control.handler(el, editor, $scope, $compile);
              });
            }
          };

          $scope.registerModule = async (name) => {
            const instance = await AutomationInstanceCreator.createInstance(name);
            
            editor.register(instance);

            $scope.components.push({
              name: instance.getTemplate().name,
              icon: instance.getTemplate().icon,
              constructor: instance.getTemplate().constructor,
              data_model: instance.getTemplate().data_model,
              instance
            });

            $scope.$apply();

          }

          const promises = [];

          const activeNodes = [];

          for(let index in $scope.ghModel.nodes) {
            const id = $scope.ghModel.nodes[index].name.replace(/ /g, '');
            if(!activeNodes.includes(id)) {
              activeNodes.push(id);
            }
          }

          for(let node = 0; node < activeNodes.length; node ++) {
            promises.push($scope.registerModule(activeNodes[node]));
          }

          await Promise.all(promises);

          $scope.elementsCatalog = $scope.nodesModel.include_nodes;
          $scope.$apply();

          editor.use(ConnectionPlugin);
          editor.use(VueRenderPlugin);
          editor.use(JsRenderPlugin);//-- For rendering gh-elements inside nodes

          //----- Adding new Node  -----//
          $scope.addNode = async (component, coords) => {
            const newNode = await component.instance.createNode( component.instance.getTemplate().data_model )

            coords.x = coords.x / editor.view.area.transform.k;
            coords.y = coords.y / editor.view.area.transform.k;

            coords.x -= editor.view.area.transform.x / editor.view.area.transform.k;
            coords.y -= editor.view.area.transform.y / editor.view.area.transform.k;

            newNode.position = [coords.x, coords.y];
            // find a node with the highest id and add 1 to it
            const highestId = editor.nodes.reduce((acc, curr) => {
              if(curr.id > acc) {
                return curr.id;
              }
              return acc;
            }, 0);

            newNode.id = highestId + 1;

            editor.addNode(newNode);

            await engine.process(editor.toJSON());
          };

          $scope.init = () => {
              editor.fromJSON($scope.ghModel).then(() => {
                  editor.view.resize();
              });
          }


          //----- Delete Node  -----//
          $scope.deleteNode = async (nodeId) => {
            let nodeToRemove = editor.nodes.find(node => node.id == nodeId);
            editor.removeNode( nodeToRemove );
            engine.process(editor.toJSON());
          };

            const dropzone = $element[0].querySelector('#gh-automation-render');

            dropzone.addEventListener('dragover', e => {
              e.preventDefault();
            }); 

            dropzone.addEventListener('drop', e => {
              const name = e.dataTransfer.getData('text/plain');
              const component = $scope.components.find(component => component.name === name);
              $scope.addNode(component, { x: e.offsetX, y: e.offsetY });
            });

          //----- Init Editor -----//
            $timeout($scope.init, 0)

            editor.on('nodeselected', (node) => {
              if($scope.debugInfo.currentNodes && !$scope.debugInfo.currentNodes.includes(node.id)) {
                const container = document.querySelector('#gh-automation-render');

                let nodeElement = container.querySelector(`[data-node-id="${$scope.debugInfo.currentNodes[0]}"]`);

                if(!nodeElement) return;
                
                nodeElement = nodeElement.parentElement.parentElement;
                setTimeout(() => {
                  nodeElement.classList.remove('selected-active');
                  nodeElement.classList.add('active');
                }, 100);
              }
              if($scope.debugInfo.currentNodes && $scope.debugInfo.currentNodes.includes(node.id)) {
                const container = document.querySelector('#gh-automation-render');

                let nodeElement = container.querySelector(`[data-node-id="${$scope.debugInfo.currentNodes[0]}"]`);

                if(!nodeElement) return;
                
                nodeElement = nodeElement.parentElement.parentElement;
                setTimeout(() => {
                  nodeElement.classList.add('selected-active');
                  if(!$scope.debugInfo.currentNodes.includes(node.id)) {
                    nodeElement.classList.remove('selected-active');
                  }
                }, 100);
              }
            });
            
          //----- Listening for Events to update the tree -----//
          editor.on('process nodecreated noderemoved connectioncreated connectionremoved', async () => {
              await engine.abort();
              await engine.process(editor.toJSON());
              $scope.ghModel = editor.toJSON();
              $scope.$apply();
          });
        }

        $scope.init();

        // Reinit method in debug info need to call it when automation run in debugger to rerender automation
        if($scope.debugInfo) {
          $scope.debugInfo.reinit = () => {
            for(const debuggerIndex in $scope.debugInfo.debuggers) {
              const debug = $scope.debugInfo.debuggers[debuggerIndex];

              debug.promiseResolved = false;
              debug.code = {};
            }
            $scope.init();
          }
        }

        $scope.copy = () => {
          const model = JSON.stringify($scope.ghModel);
          navigator.clipboard.writeText(model);
          $scope.copyText = 'Copied!';
          setTimeout(() => {
            $scope.copyText = 'Copy'
            $scope.$apply();
          }, 750);
        }

        $scope.paste = async () => {
          try {
            const model = await navigator.clipboard.readText();
            const pastedNodes = JSON.parse(model).nodes;
            const missingNodes = [];
            for(const node in pastedNodes) {
              const nodeName = pastedNodes[node].name;
              if(!$scope.editor.components.get(nodeName) && !missingNodes.find(node => node == nodeName)) {
                missingNodes.push(nodeName.replace(/ /g, ''));
              }
            }
            const promises = [];
            missingNodes.forEach(node => {
              promises.push($scope.registerModule(node));
            });
            await Promise.all(promises);
            await $scope.editor.fromJSON(JSON.parse(model));
            $scope.editor.view.resize();
            $scope.pasteText = 'Pasted!';
            setTimeout(() => {
              $scope.pasteText = 'Paste';
              $scope.$apply();
            }, 750);
          } catch(err) {
            console.log(err);
            $scope.pasteText = 'Error!';
            setTimeout(() => {
              $scope.pasteText = 'Paste';
              $scope.$apply();
            }, 750);
          }
        }

      }]


    };

  }]);
