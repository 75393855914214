import Rete from "rete";
export class NoEditControl extends Rete.Control {
  constructor(emitter, key, readonly, template) {
    super(key);
    this.render = "js";
    this.key = key;
    this.template = template;
  }

  handler(el, editor, $scope, $compile) {
    //-- Container for controls
    var conteiner = document.createElement("div");
    conteiner.className = "gh-node-content";
    el.appendChild(conteiner);



    //-- Controls (Icon with edit button )
    let elem = angular.element('<span class="automation-settings-icon" gh-icon="' + this.template.icon + ' ffffff 84px normal"></span>');


    //-- appending angular element
    let compiled = $compile(elem);
    angular.element(conteiner).append(elem);
    compiled($scope);
  }
}