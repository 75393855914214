export default class GradientsGenerator {
  constructor() {}

  
  static getGradientsList() {
    
    const  gradientsList = [{
      up: 'FFFFFF00',
      down: 'FFFFFF00'
    }, {
      up: '2DD3FC',
      down: '1F5AF0'
    }, {
      up: '00CCF8',
      down: '00F8B2'
    }, {
      up: 'ffb900',
      down: 'ff8900'
    }, {
      up: '00ffff',
      down: 'ff00ff'
    }, {
      up: 'ffff00',
      down: '00cccc'
    }, {
      up: 'fbfcfd',
      down: 'fbfcfd'
    }, {
      up: 'ee4eb9',
      down: 'c843fb'
    }, {
      up: 'ff2c65',
      down: 'ff5d3d'
    }, {
      up: '8657c9',
      down: '5e3987'
    }, {
      up: '6287ce',
      down: '2d4a84'
    }, {
      up: '0b73b2',
      down: '0b73b0'
    }, {
      up: '18a9e8',
      down: '08aeea'
    }, {
      up: 'e52b1c',
      down: 'bf1515'
    }, {
      up: '67f98a',
      down: '05b52c'
    }, {
      up: '1d65ed',
      down: '1dd3fb'
    }, {
      up: 'a5d379',
      down: '529625'
    }, {
      up: 'd8d9de',
      down: '8a8c99'
    }, {
      up: '2063ef',
      down: '24cffa'
    }, {
      up: '62ffdf',
      down: '5dcdff'
    }, {
      up: 'ffde63',
      down: 'ffcd00'
    }, {
      up: 'dd51b2',
      down: 'ca47ff'
    }, {
      up: '27deff',
      down: '2163d4'
    }, {
      up: 'ff9a9e',
      down: 'fad0c4'
    }, {
      up: 'a18cd1',
      down: 'fbc2eb'
    }, {
      up: 'fad0c4',
      down: 'fad0c4'
    }, {
      up: 'ffecd2',
      down: 'fcb69f'
    }, {
      up: 'ff9a9e',
      down: 'fecfef'
    }, {
      up: 'f6d365',
      down: 'fda085'
    }, {
      up: 'fbc2eb',
      down: 'a6c1ee'
    }, {
      up: 'fdcbf1',
      down: 'e6dee9'
    }, {
      up: 'a1c4fd',
      down: 'c2e9fb'
    }, {
      up: 'd4fc79',
      down: '96e6a1'
    }, {
      up: '84fab0',
      down: '8fd3f4'
    }, {
      up: 'cfd9df',
      down: 'e2ebf0'
    }, {
      up: 'a6c0fe',
      down: 'f68084'
    }, {
      up: 'fccb90',
      down: 'd57eeb'
    }, {
      up: 'e0c3fc',
      down: '8ec5fc'
    }, {
      up: 'f093fb',
      down: 'f5576c'
    }, {
      up: 'fdfbfb',
      down: 'ebedee'
    }, {
      up: '4facfe',
      down: '00f2fe'
    }, {
      up: '43e97b',
      down: '38f9d7'
    }, {
      up: 'fa709a',
      down: 'fee140'
    }, {
      up: '30cfd0',
      down: '330867'
    }, {
      up: 'a8edea',
      down: 'fed6e3'
    }, {
      up: '5ee7df',
      down: 'b490ca'
    }, {
      up: 'd299c2',
      down: 'fef9d7'
    }, {
      up: 'f5f7fa',
      down: 'c3cfe2'
    }, {
      up: '667eea',
      down: '764ba2'
    }, {
      up: 'fdfcfb',
      down: 'e2d1c3'
    }, {
      up: '89f7fe',
      down: '66a6ff'
    }, {
      up: 'fddb92',
      down: 'd1fdff'
    }, {
      up: '9890e3',
      down: 'b1f4cf'
    }, {
      up: 'ebc0fd',
      down: 'd9ded8'
    }, {
      up: '96fbc4',
      down: 'f9f586'
    }, {
      up: '2af598',
      down: '009efd'
    }, {
      up: 'cd9cf2',
      down: 'f6f3ff'
    }, {
      up: '6a11cb',
      down: '2575fc'
    }, {
      up: '37ecba',
      down: '72afd3'
    }, {
      up: 'ebbba7',
      down: 'cfc7f8'
    }, {
      up: 'fff1eb',
      down: 'ace0f9'
    }, {
      up: 'c471f5',
      down: 'fa71cd'
    }, {
      up: '48c6ef',
      down: '6f86d6'
    }, {
      up: 'feada6',
      down: 'f5efef'
    }, {
      up: 'e6e9f0',
      down: 'eef1f5'
    }, {
      up: 'accbee',
      down: 'e7f0fd'
    }, {
      up: 'e9defa',
      down: 'fbfcdb'
    }, {
      up: 'c1dfc4',
      down: 'deecdd'
    }, {
      up: '0ba360',
      down: '3cba92'
    }, {
      up: '00c6fb',
      down: '005bea'
    }, {
      up: '74ebd5',
      down: '9face6'
    }, {
      up: '6a85b6',
      down: 'bac8e0'
    }, {
      up: 'a3bded',
      down: '6991c7'
    }, {
      up: '9795f0',
      down: 'fbc8d4'
    }, {
      up: 'a7a6cb',
      down: '8989ba'
    }, {
      up: 'f43b47',
      down: '453a94'
    }, {
      up: '0250c5',
      down: 'd43f8d'
    }, {
      up: '88d3ce',
      down: '6e45e2'
    }, {
      up: 'd9afd9',
      down: '97d9e1'
    }, {
      up: '7028ee',
      down: 'e5b2ca'
    }, {
      up: '13547a',
      down: '80d0c7'
    }, {
      up: 'ff0844',
      down: 'ffb199'
    }, {
      up: '93a5cf',
      down: 'e4efe9'
    }, {
      up: '434343',
      down: '000000'
    }, {
      up: '93a5cf',
      down: 'e4efe9'
    }, {
      up: '92fe9d',
      down: '00c9ff'
    }, {
      up: 'ff758c',
      down: 'ff7eb3'
    }, {
      up: '868f96',
      down: '596164'
    }, {
      up: 'c79081',
      down: 'dfa579'
    }, {
      up: '8baaaa',
      down: 'ae8b9c'
    }, {
      up: 'f83600',
      down: 'f9d423'
    }, {
      up: 'b721ff',
      down: '21d4fd'
    }, {
      up: '6e45e2',
      down: '88d3ce'
    }, {
      up: 'd558c8',
      down: '24d292'
    }, {
      up: 'abecd6',
      down: 'fbed96'
    }, {
      up: '5f72bd',
      down: '9b23ea'
    }, {
      up: '09203f',
      down: '537895'
    }, {
      up: 'ddd6f3',
      down: 'faaca8'
    }, {
      up: 'dcb0ed',
      down: '99c99c'
    }, {
      up: 'f3e7e9',
      down: 'e3eeff'
    }, {
      up: 'c71d6f',
      down: 'd09693'
    }, {
      up: '96deda',
      down: '50c9c3'
    }, {
      up: 'f77062',
      down: 'fe5196'
    }, {
      up: 'a8caba',
      down: '5d4157'
    }, {
      up: '29323c',
      down: '485563'
    }, {
      up: '16a085',
      down: 'f4d03f'
    }, {
      up: 'ff5858',
      down: 'f09819'
    }, {
      up: '2b5876',
      down: '4e4376'
    }, {
      up: '00cdac',
      down: '8ddad5'
    }, {
      up: '4481eb',
      down: '04befe'
    }, {
      up: 'dad4ec',
      down: 'f3e7e9'
    }, {
      up: '874da2',
      down: 'c43a30'
    }, {
      up: 'f794a4',
      down: 'fdd6bd'
    }, {
      up: 'e8198b',
      down: 'c7eafd'
    }, {
      up: '64b3f4',
      down: 'c2e59c'
    }, {
      up: '0fd850',
      down: 'f9f047'
    }, {
      up: 'ee9ca7',
      down: 'ffdde1'
    }, {
      up: '209cff',
      down: '68e0cf'
    }, {
      up: 'bdc2e8',
      down: 'e6dee9'
    }, {
      up: 'e6b980',
      down: 'eacda3'
    }, {
      up: '1e3c72',
      down: '2a5298'
    }, {
      up: '9be15d',
      down: '00e3ae'
    }, {
      up: 'ed6ea0',
      down: 'ec8c69'
    }, {
      up: 'ffc3a0',
      down: 'ffafbd'
    }, {
      up: 'cc208e',
      down: '6713d2'
    }, {
      up: 'b3ffab',
      down: '12fff7'
    }, {
      up: '243949',
      down: '517fa4'
    }, {
      up: 'fc6076',
      down: 'ff9a44'
    }, {
      up: 'dfe9f3',
      down: 'ffffff'
    }, {
      up: '00dbde',
      down: 'fc00ff'
    }, {
      up: 'f9d423',
      down: 'ff4e50'
    }, {
      up: '50cc7f',
      down: 'f5d100'
    }, {
      up: '0acffe',
      down: '495aff'
    }, {
      up: '616161',
      down: '9bc5c3'
    }, {
      up: 'df89b5',
      down: 'bfd9fe'
    }, {
      up: 'ed6ea0',
      down: 'ec8c69'
    }, {
      up: 'd7d2cc',
      down: '304352'
    }, {
      up: 'e14fad',
      down: 'f9d423'
    }, {
      up: 'b224ef',
      down: '7579ff'
    }, {
      up: 'c1c161',
      down: 'd4d4b1'
    }, {
      up: 'ec77ab',
      down: '7873f5'
    }, {
      up: '007adf',
      down: '00ecbc'
    }, {
      up: '20E2D7',
      down: 'F9FEA5'
    }, {
      up: 'A8BFFF',
      down: '884D80'
    }, {
      up: 'B6CEE8',
      down: 'F578DC'
    }, {
      up: 'FFFEFF',
      down: 'D7FFFE'
    }, {
      up: 'E3FDF5',
      down: 'FFE6FA'
    }, {
      up: '7DE2FC',
      down: 'B9B6E5'
    }, {
      up: 'CBBACC',
      down: '2580B3'
    }, {
      up: 'B7F8DB',
      down: '50A7C2'
    }, {
      up: '007adf',
      down: '00ecbc'
    }]

    return gradientsList;
  }
  
}