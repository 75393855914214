import '../../../gui/gh_radio_button.js'
import './groupsTable.scss';
import '../groupUsers.directive.js';
import '../members_table/membersTable.directive.js'
import '../apps_table/appsTable.directive.js'
angular.module('groupsTableModule', [])

  .directive('groupsTable', ['GhDialog', function (GhDialog) {
    return {
      restrict: 'E',
      scope: {
        groups: '='
      },

      controller: ['$scope', function ($scope) {
        $scope.options = [{
          name: 'Blocked',
          value: 0,
          color: '#000000'
        }, {
          name: 'Member',
          value: 1,
          color: '#00FF00'
        }, {
          name: 'Admin',
          value: 2,
          color: '#FF0000'
        },{
          name: 'Owner',
          value: 3,
          color: '#0FB5FF'
        }]

        $scope.showDeleteGroupPopup = function (group) {
          GhDialog.show({
            position: 'center',
            toolbar: false,
            template: {
              toolbar: '',
              resizable: false,
              content: `
              <div class="delete_group-popup">
                <div class="dialog-share-group">
                  <span gh-icon="rubbish FF0D0D 65px"></span>
                  <p class="dialog-share-group__title">Deleting Group!</p>
                </div>
                <div class="dialog-share-group__content">
                  <p class="info">Do you really want to delete group? You will not be able to restore it.</p>
                  <div class="dialog-share-group__content__buttons">
                    <button class="dialog-share-group__content__buttons__add btn btn-blue-reverse" ng-click="deleteGroup()">Delete</button>
                    <button class="dialog-share-group__content__buttons__cancel btn btn-blue" ng-click="cancel()">Cancel</button>
                  </div>
                </div>
              </div>`,
            },
            locals: {
              group,
              groups: $scope.groups
            },
              controller: ['$scope', 'groups', function ($scope, groups) {

                $scope.deleteGroup = function() {
                  try {
                    gudhub.groupSharing.deleteGroup(group.group_id);
                    groups.splice(groups.indexOf(group), 1)
                    $scope.cancel();
                  } catch(error) {
                    console.log(error);
                  }
                }
              }]
              
          })
        }

        $scope.openGroup = function (e, group, index) {
          $scope.groupName = group.group_name;
          
          const deleteIcon = document.querySelectorAll('.delete_icon')[index];
          const deleteIconSvg = document.querySelectorAll('.delete_icon svg')[index];
          const deleteIconPath = document.querySelectorAll('.delete_icon svg path')[index];
          if(e.target == deleteIcon || e.target == deleteIconPath || e.target == deleteIconSvg) return;

          const options = {
              position: 'full',
              toolbar: false,
              template: {
              toolbar: '',
                content: /*html*/
                `
                <div class="group-popup">
                  <div class="group-popup__header">
                    <div class="group_name-wrapper">
                      <input type="text" ng-model="group.group_name" ng-style="{width: this.group.group_name.length + 'ch'}" ng-change="changeValue()" class="group_name_input" readonly>
                      <span class="edit_icon" ng-show="!showChangeName && group.group_permission == 3 || group.group_permission == 2" gh-icon="pencil 0D99FF 25px normal" ng-click="editGroupName(group)"></span>
                      <div class="group_name_controls" ng-show="showChangeName">
                        <span class="save_icon" gh-icon="checked 05B52C 25px normal" ng-click="updateGroupName(group)"></span>
                        <span class="cancel_icon" gh-icon="cross FF0000 25px normal" ng-click="cancelEdit()"></span>
                      </div>
                    </div>
                    <div class="group_admins">
                      <p class="group_admins__title">Administrators:</p>
                      <group-users group="group" mode="admins"></group-users>
                    </div>
                  </div>
                  <span class="close_dialog" ng-click="cancel()" gh-icon="cross #0D99FF 30px"></span>
                  <div class="group-popup__content">
                    <div class="group_members">
                      <p class="group_users__title">Members</p>
                      <members-table group="group"></members-table>
                    </div>
                    <div class="apps">
                      <p class="group_users__title">Applications</p>
                      <apps-table group="group"></apps-table>
                    </div>
                  </div>
                </div>
                `
              },
              locals: {
                group,
                groupName: $scope.groupName
              },
              
              controller: ['$scope', 'group', 'groupName', async function($scope, group, groupName) {
                  $scope.group = group;
                  
                  $scope.editGroupName = () => {
                    const input = document.querySelector('.group_name_input');
                    input.removeAttribute('readonly');
                    $scope.showChangeName = true;
                    input.focus();
                  }

                  $scope.changeValue = () => {
                    const input = document.querySelector('.group_name_input');
                    input.style.width = input.value.length + 1 + "ch";
                  }

                  $scope.cancelEdit = () => {
                    $scope.showChangeName = false;
                    $scope.group.group_name = groupName;
                    const input = document.querySelector('.group_name_input');
                    input.setAttribute('readonly', true);
                  }

                  $scope.updateGroupName = async (group) => {
                    if (group.group_name.length > 0) {
                      try {
                        const input = document.querySelector('.group_name_input');
                        input.setAttribute('readonly', true);
                        await gudhub.groupSharing.updateGroup(group.group_id, group.group_name);
                        groupName = group.group_name;
                        $scope.showChangeName = false;
                        $scope.$apply();
                      } catch(error) {
                        console.log(error);
                      }
                    } else {
                      return false;
                    }
                  }
                
              }]
          }
            GhDialog.show(options);
        }
      }],

      template: /*html*/
      `<div class="listing_table">
        <div class="listing_table_head_wrapper">
          <div class="listing_table_column bottom_line w-50">
            <div class="tab">Name</div>
          </div>
          <div class="listing_table_column w-35">
            <div class="tab">Permission</div>
          </div>
          <div class="listing_table_column w-15">
            
          </div>
        </div>
        <div class="listing_table_body_wrapper">
          <div class="listing_table_row" ng-click="openGroup($event,group, $index)" ng-repeat="group in groups">
            <div class="listing_table_column w-50">
              <span class="number">{{$index + 1}}</span>
              <div class="name">{{group.group_name}}</div>
            </div>
            <div class="listing_table_column w-35">
              <gh-radio-button class="gh-radio-button" gh-options="options" gh-model="group.group_permission" gh-mode="current_value" editable="false"/> +
            </div>
            <div class="listing_table_column w-15">
              <span ng-click="showDeleteGroupPopup(group)" ng-show="group.group_permission === 2 || group.group_permission === 3" class="delete_icon" gh-icon="rubbish 0d99ff 20px"></span>
            </div>

          </div>
          <div ng-if="!groups.length" class="listing_table_nodata">
            <p>No Groups</p>
          </div> 
        </div>

      </div>`
      
    }
  }]);
