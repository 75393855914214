import "./../../automation/gh_automation.js";
import Rete from "rete";
import { AutomationInstanceCreator } from './../../automation/AutomationInstanceCreator.js';

angular.module("ghElementAutomationDebuggerModule", [])

.directive("ghElementAutomationDebugger", [function() {
  return {
    scope: {
      appId: '=',
      elementId: '=',
      model: '=',
      debugInfo: '='
    },
    controller: ['$scope', async function($scope) {
      $scope.isDebugging = false;
      $scope.trigger = $scope.model.data_model.trigger;
      $scope.trigger.options = [];

      $scope.$watch(
        "trigger.mocks",
        () => {
          if ($scope.trigger.mocks) {
            $scope.trigger.options = $scope.trigger.mocks.map(
              (option, index) => ({
                name: option.name,
                value: index,
              })
              );
            }
        },
        true
      );

      $scope.runDebug = async () => {
        if(!$scope.currentMock) return;
        $scope.debugInfo.reinit();
        const items = await gudhub.getItems($scope.appId);
        const item_id = $scope.trigger.mocks[$scope.currentMock].item.split('.')[1];
        const item = items.find(item => item.item_id == item_id);
        const mockData = { updated_item: [item], new_value: [$scope.trigger.mocks[$scope.currentMock].new_value], old_value: [$scope.trigger.mocks[$scope.currentMock].old_value] };
        if (mockData && !$scope.isDebugging) {
          $scope.isDebugging = true;
          // await $scope.init();
          const triggerData = mockData;
          console.log($scope);
          await automations($scope.model.data_model.automation.model, triggerData);
          $scope.isDebugging = false;
        }
      };

      const automations = (model, obj) => {
        return new Promise(async (resolve, reject) => {
          const engine = new Rete.Engine("trigger@0.1.0");
          console.log("DEBUG START");

          const automationModulesList = gudhub.storage.getModulesList('automation');

          const promises = [];

          for(let module of automationModulesList) {
            promises.push(new Promise(async (resolve) => {
              let instance = await AutomationInstanceCreator.createInstance(module.data_type);
              engine.register(instance);
              resolve();
            }))
          }

          await Promise.all(promises);

          engine.on("error", ({ message, data }) => {});
          engine.process(model, null, obj, resolve, gudhub);
        });
      }

      $scope.settingsDecorator = {
        data_model: {
          color: "0893d2",
          settings: [
            {
              type: "general_setting",
              icon: "menu",
              title: "Options",
              columns_list: [
                [
                  { title: "Mocks", type: "header" },
                  {
                    type: "html",
                    class: "option-column_750px",
                    data_model: async (fieldModel) => {
                      return {
                        patterns: [
                          {
                            property: "name",
                            type: "text",
                            prop_name: "Name",
                            data_model: function (option) {
                              return {};
                            },
                            display: true,
                          },
                          {
                            property: "old_value",
                            type: "text",
                            prop_name: "Old Value",
                            data_model: (option) => {
                              return {};
                            },
                            display: true
                          },
                          {
                            property: "new_value",
                            type: "text",
                            prop_name: "New Value",
                            data_model: (option) => {
                              return {};
                            },
                            display: true
                          },
                          {
                            property: 'field',
                            type: 'text_opt',
                            prop_name: 'Field',
                            data_model: (option) => {
                              return {
                                options: $scope.fieldsOptionsToPick
                              }
                            },
                            display: true
                          },
                          {
                            property: 'item',
                            type: 'item_ref',
                            prop_name: "Item",
                            data_model: (option) => {
                              console.log(option);
                              return {
                                app_id: $scope.appId,
                                refs: [
                                  {
                                    app_id: $scope.appId,
                                    field_id: $scope.fieldsToPick[Number(option.field)].field_id
                                  }
                                ],
                              };
                            },
                            display: true
                          },
                        ],
                      };
                    },
                    control:
                      '<gh-option-table items="fieldModel.mocks" pattern="field_model.patterns"></gh-option-table>',
                  },
                ],
              ],
            },
          ],
        },
        data_type: "additional_settings"
      }
      $scope.fieldsToPick = await gudhub.getFieldModels($scope.appId);
      $scope.fieldsOptionsToPick = $scope.fieldsToPick.map((field, index) => {
        return {
          name: field.field_name,
          value: index
        }
      });
      
    }],
    template: `
      <span class="gh-element-automation-debugger__title">Debug Mode</span>
      <gh-boolean class="gh-element-automation-debugger__switch gh-api-btn-switch" gh-model="trigger.debug" gh-mode="switch" editable="true"></gh-boolean>
      <div ng-show="trigger.debug" class="gh-element-automation-debugger__mocks">
          <gh-input class="gh-element-automation-debugger__dropdown" ng-model="currentMock" gh-dropdown="trigger.options"></gh-input>
          <gh-element elem-src="form"
                      decorator="settingsDecorator"
                      value="trigger"></gh-element>
          <span class="gh-element-automation-debugger__run" gh-icon="wmv 0893d2 24px normal" ng-click="runDebug()"></span>         
      </div>
    `
  }
}]);