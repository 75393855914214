export default {
  private: true,
  template_name: 'Element',
  allowed_types: [],
  model: {
    template_id: 'gh_element',
    name: '',
    icon_id: '',
    class: '',
    priority: 0,
    container_id: 0,
    content: []
  },
  container_settings: [],
  html_editor: '<gh-element decorator="getElementModelT(item.element_id)" field-id="{{item.element_id}}" elem-src="{{srcForElement}}"></gh-element>',
  html_form: '<gh-element app-id="{{appId}}" item-id="{{itemId}}" field-id="{{item.element_id}}" value="fields[item.element_id]" elem-src="{{srcForElement}}"></gh-element>',
  icon: ''
};
