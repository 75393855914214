angular.module('sliderModule', [])
    .service('sliderService', ['$q', 'PipeService', 'storage', '$window', function ($q, PipeService, storage, $window) {

        this.getApplist = function (showApp) {
            return new Promise(async (resolve) => {

                let apps = await gudhub.getAppsList();

                const savedApps = localStorage.getItem('apps') ? JSON.parse(localStorage.getItem('apps')) : [];

                apps = apps.map(app => {
                    const savedApp = savedApps.find(a => a.app_id == app.app_id);
                    if (savedApp) {
                        return {
                            ...app,
                            ...savedApp,
                            // Can cause error with "splice" after localstorage delete
                            // Becase there is no saved app
                            children: []
                        }
                    }
                    return { ...app, children: [] };
                });

                apps = apps.sort((a, b) => a.priority - b.priority);

                apps = apps.flatMap(app => {
                    if (app.parent_id) {
                        const parent = apps.find(a => a.app_id == app.parent_id);
                        if (parent) {
                            parent.children.push(app);
                        }
                        return [];
                    }
                    return app;
                });

                let tabs = [];
                // Temporary fix for deleting default application (app to init) from the app_list
                var user_app_init = storage.getUser().app_init;

                angular.forEach(apps, function (value, key) {
                    if (value.app_id == user_app_init) {
                        apps.splice(key, 1);
                    }
                });
                // -----------------------------

                let appsList = apps.filter(app => app.from_apps_list);
                if (showApp) {
                    tabs = this.createTabsList(appsList);
                } else {
                    tabs = this.createHideTabsList(appsList);
                }

                if (typeof tabs == "undefined") {
                    tabs = []
                }
                resolve(tabs);
            })
        }

        this.createHideTabsList = (appsList) => {
            appsList = appsList.filter(app => app.show != false)
            return this.createTabsList(appsList);
        }

        this.createTabsList = (appsList) => {
            var tabs = [];
            // counts the number of items for one slide
            var itemsInTab = 18;
            if ($window.innerWidth > 1280) {
                itemsInTab = 18;
            } else if ($window.innerWidth > 1000) {
                itemsInTab = 15;
            } else if ($window.innerWidth > 750) {
                itemsInTab = 12;
            } else if ($window.innerWidth > 500) {
                itemsInTab = 9;
            } else {
                itemsInTab = 6;
            }
            var tabCount = Math.ceil((appsList.length + 1) / itemsInTab);


            // formation of arrey for repeat
            if (tabCount && appsList.length) {
                for (var i = 0; i < tabCount; i++) {
                    let firstIndex = i == 0 ? 0 : i * itemsInTab - 1;
                    let lastIndex = itemsInTab - 1 + i * itemsInTab;
                    tabs[i] = appsList.slice(firstIndex, lastIndex);

                    firstIndex = lastIndex;
                    lastIndex = lastIndex + i * itemsInTab;
                }
                return tabs;
            }
        }

        return this;
    }]);