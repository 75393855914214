require("../config.js");

angular
  .module("authorizationMod", ["mainStorage", "ngCookies", "config"])

  .controller("UserSettingsCtrl", [
    "$scope",
    function ($scope) {
      $scope.updateAccount = function () {};
    },
  ])

  /*============================================================================================================*/
  /*======================================     AUTHORIZATION SERVICE   =========================================*/
  /*============================================================================================================*/
  .service("authService", [
    function () {
      return gudhub;
    },
  ])

  /*============================================================================================================*/
  /*========================================     AUTHORIZATION API   ===========================================*/
  /*============================================================================================================*/
  /*-- here we send requests on server for authorization*/

  .factory("authApi", [
    function () {
      return gudhub;
    },
  ]);
