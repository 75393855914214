/******************************************************************************************************/
/**********************************    GH-BUTTON CONTROL    *******************************************/
/******************************************************************************************************/

/*  This is main control for entering data, also it used as a part for other controls like:*/

/*   <gh-button autocomplete="fieldsList 23" gh-dropdown="" callback="filter.addFilter(value)" size="large"/>*/
/*   - autocomplete*/
/*   - gh-dropdown=""*/
/*   - callback*/

/****************************************************************************************************/

angular.module('ghButtonModule', [])

.directive('ghButton', ['$compile', function($compile) {
	var directive = {};

	directive.scope = {
    ghModel: '=',
		icon: '@',
    callback: '&'
  };

	directive.controller = [ '$scope', '$element', '$attrs', function($scope, $element, $attrs) {
		$scope.decoratedFieldModel = {
      data_model: {
        options:  $scope.options
      },
      data_type: 'text_opt'
    };

		if (angular.isUndefined($scope.options)) {
      $scope.options = [];
    }
		
		$scope.colorStyleBtn = {
			"background-color": $scope.ghModel.data_model.color,
			"border-color": $scope.ghModel.data_model.color,
			"border-style": 'solid',
			"border-width": '1px', 
			"color": "#fff"
		};
    $scope.iconModel = $scope.ghModel.data_model.icon + ' #fff 20px normal';
		$scope.hoverMouse = function () {
			$scope.colorStyleBtn["background-color"] = "#fff";
			$scope.colorStyleBtn["color"] = $scope.ghModel.data_model.color;
			if ($element[0].querySelector('.button_icon svg path'))
					$element[0].querySelector('.button_icon svg path').setAttribute('fill', $scope.ghModel.data_model.color) 
		};

		$scope.leaveMouse = function () {
			$scope.colorStyleBtn["background-color"] = $scope.ghModel.data_model.color;
			$scope.colorStyleBtn["color"] = "#fff";
			if ($element[0].querySelector('.button_icon svg path'))
				$element[0].querySelector('.button_icon svg path').setAttribute('fill', '#fff') 
		};
	}];

	directive.template = function(tElement, tAttrs) {

		var template =
		`
			<div class="btn-group gh-dropdown">
				<div class="gh-btn inline gh-btn-bg" ng-style="colorStyleBtn" ng-mouseleave="leaveMouse()" ng-mouseenter="hoverMouse()" >
					<span ng-if="ghModel.data_model.icon" class="button_icon" gh-icon="{{iconModel}}"></span>
					<p class="button_name">{{ghModel.field_name}}</p>
			</div>`;
		return template;
	};

	directive.link = function(scope, iElement, iAttrs) {
		if (angular.isDefined(iAttrs.autocomplete)) {
      iAttrs.$observe('autocomplete', function() {
        $compile('<gh-input ng-model="temporary" gh-dropdown="options" autocomplete="' + iAttrs.autocomplete +'" gh-data-type="text_opt"></gh-input>')(scope);
			});
    }
	};
	return directive;
}]);
