require('./investors.scss');

angular.module('investorsModul', [])

  .controller('investorsCtrl', ['$scope','$routeParams', '$location', 'sendEmailService', function($scope, $routeParams, $location, sendEmailService) {
    let emailToSend,name,id;
    // SEND EMAIL AFTER LOAD PAGE
    for(let key in $routeParams){
      if(key == "mid"){
        name = "Mike";
        emailToSend = "michael.f@gudhub.com"
      }
      if(key == "eid"){
        name = "Ed";
        emailToSend = "ed.v@gudhub.com"
      }
      if(key == "tid"){
        name = "Tanya";
        emailToSend = "tanya@gudhub.com"
      }
      if(key == "hid"){
        name = "Eugene";
        emailToSend = "eugene@gudhub.com"
      }
      id = $routeParams[key];
    }

    let textHtmlContent = 'Hey ' + name + ', Investor #'+ id +' has opened your pitch-deck'

    let content = {
      emails: emailToSend,
      senderName: 'Atlasiko',
      theme: "Investors GudHub",
      body: '<!DOCTYPE html>' +
      '<html>' +
      ' <head>' +
      '   <title>Gudhub</title>' +
      ' </head>' +
      ' <body style="color: #000000;">' + textHtmlContent +
      '</body>' +
      '</html>'
    }
    if(emailToSend){
      sendEmailService.sendEmail(content).then(function(){
        console.log("EMAIL SEND");
      });
    }
  }]);