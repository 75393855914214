angular.module('fileHelperModule', [
  'authorizationMod',
  'appDataProcessor',
  'mainStorage',
  'config'
])

.service('fileHelper', [ '$q','cnfg', 'DownloadFileService',
function($q, cnfg, DownloadFileService) {

  this.fileInstallerHelper = function (appId, items, elementId) {
    const files = [[]];
    const deferred = $q.defer();
    let chainPromises = Promise.resolve();
    let results = [];

    angular.forEach(items, item => {
      const itemId = item.item_id;
      const field = item.fields.find(field => field.element_id === elementId);

      if (field && field.field_value) {
        const fileIds = field.field_value.split(',');

        angular.forEach(fileIds, fileId => {
          const newFile = {
            source: fileId,
            destination: {
              app_id: appId,
              item_id: itemId,
              element_id: elementId
            }
          };

          if (files[files.length - 1].length !== cnfg.maxDuplicatedFiles) {
            files[files.length - 1].push(newFile)
          } else {
            files.push([newFile]);
          }
        });
      }
    });

    files.forEach(filePack => {
      chainPromises = chainPromises
        .then(() => {
          return gudhub.fileManager.duplicateFile(filePack);
        })
        .then(result => {
          console.log(result);
          results = [
            ...results,
            ...result
          ];
        });
    });

    chainPromises.then(() => {
      // UPDATE FILE VALUE
      angular.forEach(items, item => {
        const itemId = item.item_id;
        const field = item.fields.find(field => field.element_id === elementId);

        if (field && field.field_value) {
          field.field_value = '';
          results.forEach(file => {
            if (file.item_id === itemId) {
              field.field_value = field.field_value.split(',').filter(fileId => fileId).concat(file.file_id).join(',');
            }
          });
        }
      });

      deferred.resolve(items);
    });

    return deferred.promise;
  };

  this.downloadFile = function(file){
    DownloadFileService.save(file);
  };


}])


.service('DownloadFileService', ['$timeout', function($timeout){

  var showSave;
  var DownloadAttributeSupport = 'download' in document.createElement('a');

  // BlobBuilder/URL implementation
  var URL = window.URL || window.webkitURL || window.mozURL || window.msURL;
  // IE 10 has a handy navigator.msSaveBlob method
  navigator.saveBlob = navigator.saveBlob || navigator.msSaveBlob || navigator.mozSaveBlob || navigator.webkitSaveBlob;
  //Anyway, HMTL5 defines a very similar but more powerful window.saveAs function
  window.saveAs = window.saveAs || window.webkitSaveAs || window.mozSaveAs || window.msSaveAs;

  // Mime types that (potentially) don't trigger a download when opened in a browser
  var BrowserSupportedMimeTypes = {
   "image/jpeg": true,
   "image/png": true,
   "image/gif": true,
   "image/svg+xml": true,
   "image/bmp": true,
   "image/x-windows-bmp": true,
   "image/webp": true,
   "audio/wav": true,
   "audio/mpeg": true,
   "audio/webm": true,
   "audio/ogg": true,
   "video/mpeg": true,
   "video/webm": true,
   "video/ogg": true,
   "text/plain": true,
   "text/html": true,
   "text/xml": true,
   "application/xhtml+xml": true,
   "application/json": true
  };

  // List of mime types by extension
  var extensionMimeTypes = {
    'html': 'text/html',
    'htm': 'text/html',
    'zip': 'application/zip',
    'gz': 'application/x-gzip',
    'xls': 'application/vnd.ms-excel',
    'xlm': 'application/vnd.ms-excel',
    'xlc': 'application/vnd.ms-excel',
    'xla': 'application/vnd.ms-excel',
    'xlsx': 'application/vnd.ms-excel',
    'txt': 'text/plain',
    'tiff': 'image/tiff',
    'tif': 'image/tiff',
    'svg': 'image/svg+xml',
    'rtf': 'application/rtf',
    'ppt': 'application/vnd.ms-powerpoint',
    'pps': 'application/vnd.ms-powerpoint',
    'pot': 'application/vnd.ms-powerpoint',
    'ppm': 'image/x-portable-pixmap',
    'pdf': 'application/pdf',
    'mp2': 'video/mpeg',
    'mp3': 'video/mpeg',
    'mpa': 'video/mpeg',
    'mpeg': 'video/mpeg',
    'mpg': 'video/mpeg',
    'jpg': 'image/jpeg',
    'jpeg': 'image/jpeg',
    'jpe': 'image/jpeg',
    'doc': 'application/msword',
    'docx': 'application/msword',
    'dot': 'application/msword',
    'bmp': 'image/bmp',
    'gif': 'image/gif',
    'json': 'application/json',
    'xml': 'application/xhtml+xml'
  };

  // Create new blob object
  function NewBlob(data, datatype) {
    var out;

    try {
      console.log(datatype);
      out = new Blob([data], {
        type: datatype
      });
    } catch (e) {
      window.BlobBuilder = window.BlobBuilder ||
        window.WebKitBlobBuilder ||
        window.MozBlobBuilder ||
        window.MSBlobBuilder;

      if (e.name == 'TypeError' && window.BlobBuilder) {
        var builder = new BlobBuilder();
        builder.append(data);
        out = builder.getBlob(datatype || "application/octet-stream");
      } else if (e.name == "InvalidStateError") {
        // InvalidStateError (tested on FF13 WinXP)
        out = new Blob([data], {
          type: datatype
        });
      } else {
        // We're screwed, blob constructor unsupported entirely
        console.debug("Error downloading file!");
      }
    }
    return out;
  }

  (function(){
    if (navigator.saveBlob) {

     showSave = function (data, name, mimetype) {
       var blob = NewBlob(data, mimetype);
       if(blob){
         if (!name) name = "Download.bin";
         if (window.saveAs) {
           window.saveAs(blob, name);
         } else {
           navigator.saveBlob(blob, name);
         }
       }
     };

    } else if(URL){

      showSave = function (data, name, mimetype) {

        if (!mimetype) mimetype = "application/octet-stream";
        var blob = NewBlob(data, mimetype), url;

        // Currently only Chrome (since 14-dot-something) supports the download attribute for anchor elements.
        if (DownloadAttributeSupport) {

          url = URL.createObjectURL(blob);
          var link = document.createElement("a");
          link.setAttribute("href",url);
          link.setAttribute("download", name || "Download.bin");

          var event = document.createEvent('MouseEvents');
          event.initMouseEvent('click', true, true, window, 1, 0, 0, 0, 0, false, false, false, false, 0, null);
          link.dispatchEvent(event);

        } else {

          if (BrowserSupportedMimeTypes[mimetype.split(";")[0]] === true) {
            mimetype = "application/octet-stream";
          }

          url = URL.createObjectURL(blob);
          window.open(url, '_blank', '');
        }

        $timeout(function () {
         URL.revokeObjectURL(url);
        }, 250);
      };

    } else if (!/\bMSIE\b/.test(navigator.userAgent)) {

      showSave = function (data, name, mimetype) {

        if (!mimetype) mimetype = "application/octet-stream";
        // Again I need to filter the mime type so a download is forced.
        if (BrowserSupportedMimeTypes[mimetype.split(";")[0]] === true) {
          mimetype = "application/octet-stream";
        }
        window.open("data:"+mimetype+","+encodeURIComponent(data), '_blank', '');
     };

    }

  }());


  this.save = function(file){
    var xhr = new XMLHttpRequest();
    xhr.open('GET', `${file.url}?timestamp=${file.last_update}`, true);
    xhr.responseType = 'arraybuffer';

    xhr.onload = function(e) {
      if (this.status == 200) {
        showSave(xhr.response, file.file_name + '.' + file.extension, extensionMimeTypes[file.extension]);
      }
      xhr = null;
    };
    xhr.send();

  };

  return this;
}])
