angular.module('ghCloseModule', [])

.directive('ghCloseAction', ['ghWindowsService', function(ghWindowsService) {
  return {
    controller: ['$scope', function($scope) {
      $scope.closeWindow = function() {
        (function findWindow(main_scope) {
          if(!main_scope.window){
            findWindow(main_scope.$parent)
          } else {
            ghWindowsService.windowDestroy(main_scope.window.window_id);
          }
      })($scope);
      }
    }]
  }
}]);