/*============================================================================*/
/*========================     PIPE MODULE     ===============================*/
/*============================================================================*/

/*
|    ======================== PIPE EVENTS TYPES =================================
|
|
|    ---------------------------- APP EVENTS -----------------------------------
|    'gh_app' - send event when element initialised
|    'gh_app_info_get' - Return app_name, app_id, app_icon
|    'gh_app_get' -
|    'gh_app_update' -
|    'gh_apps_list_get' -
|    'gh_delete_app' -
|    'gh_apps_list_update' -
|
|    ---------------------------- VIEW EVENTS -----------------------------------
|    'gh_app_views_update' -
|    'gh_app_views_get' -
|    'gh_app_view_get' -
|    -------------------------- ITEM EVENTS ------------------------------------
|
|    'gh_items_get' - get items_list
|    'gh_items_chunks_get' - get items splitted to chunks with specified chunk size
|    'gh_items_update' - update items_list
|
|    'gh_item_update' - item's any field value update
|    'gh_item_get' - item fields value get
|
|    -------------------------- FIELD EVENTS -----------------------------------
|
|    'gh_model_get' - get field model in field_list.
|    'gh_model_update' - update field model in field_list
|    'gh_model_delete' - delete field model from field_list and from items
|
|
|
|    'gh_models_edit' - use in 'edit_template','edit_field' and 'edit_interpretation'
|                      actions
|    'gh_models_get' - get field_model list.
|
|
|
|    'gh_value_update' - update field value in item
|    'gh_value_get' - get field value
|    'gh_interpreted_value_get' - get field interpretation
|    'gh_value_set' - setter of field value
|
*/

angular.module('pipeModule', []).
service('PipeService', [ '$rootScope', function($rootScope){
  return gudhub.pipeService
}])

.factory('Message', ['$rootScope', function ($rootScope) {

  function Message(id, scope) {

    if(angular.isUndefined(scope) || typeof scope.$on !== 'function'){
      console.log('Scope is "undefined" or not has method "$on" for subscribe with id: ' + createId(id));
      return this;
    }

    /* Subjects array
     *
     * {
     *   id: '...',
     *   func: '....'
     * }
     *
     * */
    this.subjects = [];
    this.destination_id = createId(id);
    this.scope = scope;
    var self = this;

    // Main listener of message object
    scope.$on('message', function (event, data) {

      var listenerId = createId(data.to);

      if(listenerId == self.destination_id){
        angular.forEach(self.subjects, function (subject) {
          if(subject.id == data.subject){
            subject.func(data);
          }
        });
      }
    });

  }

  Message.prototype.on = function (subjects, callback) {

    var self = this;

    subjects.split(' ').forEach(function(subject){
      self.subjects.push({
        id: subject,
        func: callback
      });

    });

    return this;
  };

  Message.prototype.destroy = function (subjectId) {

    angular.forEach(this.subjects,  (subject, i, subjects) => {
      if(subject.id == subjectId){
        this.subjects.splice(i, 1);
      }
    });

    return this;
  };


  Message.prototype.emit = function (subjects, data) {

    subjects.split(' ').forEach(function(subject){
      data.subject = subject;
      $rootScope.$broadcast('message', data);
    });

    return this;
  };

  // Create id of listener from Object to String
  function createId(obj){
    var stringId = '';
    for (var key in obj) {
      if (obj.hasOwnProperty(key) && obj[key]) {
        stringId += '.' + obj[key];
      }
    }
    return stringId ? stringId.substring(1) : 'any';
  }


  return Message;
}]);



angular.module('pipeModuleOld', []).
  service('PipeService', [ '$rootScope', function($rootScope){


    //---------------  CHECK PARAMS  --------------------//
    // Create id of listener from Object to String
    function createId(obj){
      var stringId = '';
      for (var key in obj) {
        if (obj.hasOwnProperty(key) && obj[key]) {
          stringId += '.' + obj[key];
        }
      }
      return stringId ? stringId.substring(1) : 'any';
    }

    // Chache for emits, which not have listener in that moment
    //  {
    //    'type' + ':' + 'destination': 'value'
    //  }
    // var emitChache = {};


    //---------------  CHECK PARAMS  --------------------//
    function checkParams(type, destination, fn){

      if(angular.isUndefined(type) || typeof type !== 'string'){
        console.log('Listener type is "undefined" or not have actual \'type\' for subscribe');
        return false;
      }

      if(angular.isUndefined(destination) || typeof destination !== 'object'){
        console.log('Listener destination is "undefined" or not have actual \'type\' for subscribe');
        return false;
      }

      if(typeof fn !== 'function'){
        console.log('Listener is not a function for subscribe!');
        return false;
      }
      return true;
    }


    //============================== ON PIPE ====================================//
    /*----------------------------------------------------------------------------------------------------
    |  Params:
    |    'type' - String (require). type of event ('gh_field_update', ........)
    |          from event list or maybe 'gh_field_update gh_field_value_update'
    |    'destination' - Object (require).
    |      {
    |        app_id: '',
    |        item_id: ''
    |        field_id: ''
    |      }
    |    'fn' - Function (require). callback function
    |    'scope' - Scope
    |----------------------------------------------------------------------------------------------------*/

    this.on = function (type, destination, fn, scope) {

      if(checkParams(type, destination, fn)){
        if(angular.isUndefined(scope) || typeof scope.$on !== 'function'){
          console.log('Scope is "undefined" or not has method "$on" for subscribe with id: ' + type + ':' + createId(destination));
          return this;
        }

        type.split(' ').map(function(_type){
          return _type + ':' + createId(destination);
        }).forEach(function(typeWithId){
          scope.$on(typeWithId, fn);
        });

      }
      return this;
		};



  //============================== ON ROOT PIPE ====================================//
  /*----------------------------------------------------------------------------------------------------
   |  Params:
   |    'type' - String (require). type of event ('gh_field_update', ........)
   |          from event list or maybe 'gh_field_update gh_field_value_update'
   |    'destination' - Object (require).
   |      {
   |        app_id: '',
   |        item_id: ''
   |        field_id: ''
   |      }
   |    'fn' - Function (require). callback function
   |----------------------------------------------------------------------------------------------------*/

    this.onRoot = function (type, destination, fn) {
      return this.on(type, destination, fn, $rootScope);
    };



    //============================== EMIT PIPE ====================================//
    /*----------------------------------------------------------------------------------------------------
    |  Params:
    |    'type' - String (require). type of event ('gh_field_update', ........)
    |            from eventlist
    |    'destination' - Object (require).
    |        {
    |          app_id: '',
    |          item_id: ''
    |          field_id: ''
    |        }
    |    'value' - Any (require). Emiter value to  subscribers
    |----------------------------------------------------------------------------------------------------*/

    this.emit = function (type, destination, value) {
      type.split(' ').forEach(function(_type){
        var listenerName = _type + ':' + createId(destination);
        $rootScope.$broadcast(listenerName, value);
      });
      return this;
		};

    //=========================== DELETE LISTENER =================================//
    /*----------------------------------------------------------------------------------------------------
    |  Params:
    |    'type' - String (require). type of event ('gh_field_update', ........)
    |            from eventlist
    |    'destination' - Object (require).
    |        {
    |          app_id: '',
    |          item_id: ''
    |          field_id: ''
    |        }
    |    'scope' - Scope
    |----------------------------------------------------------------------------------------------------*/

    this.destroy = function (type, destination, scope) {

      type.split(' ').forEach(function(_type){
        var listenerName = _type + ':' + createId(destination);
        delete scope.$$listeners[listenerName];
      });

      return this;
		};

  }]);
