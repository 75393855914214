require('./../config.js');
require('./../gui/gh_toast.js');

angular.module('appDataProcessor', [
'authorizationMod',
'mainStorage',
'config',
'oc.lazyLoad',
'ghToastModule'
])



.service('appDataProcesingService', [  function () {
	return gudhub.appProcessor;
}])