require('./gh_embed.scss');

angular.module('ghEmbedModule', [
  'mainStorage'
])

  .directive('ghEmbed', ['storage', '$compile', function(storage, $compile) {

    return{
      restrict: 'E',
      scope:{
        appId: '@',
        itemId: '@',
        fieldId: '@',
        fieldDataType: '@'
      },
      template: '<div class="gh-embed-container" style="font-size: 12px"></div>',
      link: function (scope, element) {
        var embedScript = '<script id="gudhubloader" src="https://gudhub.com/embedding/gudhub.js" \n async auth-key="'+ storage.getUser().auth_key +'" \n bundle-version=""></script>';

        var embedElement = '<gh-element-container>\n'+
          '<gh-element ng-if="isTokenReady" '+
              'app-id="'+ scope.appId +'" '+
              ( scope.itemId ? 'item-id="'+ scope.itemId +'" ' : '') +
              'field-id="'+ scope.fieldId +'" '+
              '\n elem-src="form" data-position="" '+
              'element-type="'+ scope.fieldDataType +'"></gh-element>\n'+
          '</gh-element-container>';

        scope.fieldModel = {
          value: '<!-- ========== HEAD =========== -->\n\n' +
          '<base href="/" />\n'+
          '<meta charset="UTF-8">\n\n'+
          '<!-- ======== END HEAD ======== -->\n\n\n' +
          '<!-- ========== BODY ========== -->\n\n' +
          embedElement +
          '\n\n' +
          '<!-- ========================== -->\n\n\n'+
          '<!-- ======== RIGHT BEFORE </BODY> TAG ======== -->\n\n' +
              embedScript +
          '\n\n' +
          '<!-- ========================================= -->'
        };
                
        let templateString = '<div gh-element decorator="{data_model:{save_file: false,code_mode: \'htmlmixed\',interpretation:[{src: \'form\', id: \'name\', settings: {show_field_name:false}}]},data_type: \'code_editor\'}" elem-src="form" value="fieldModel.value" class="slider_svg"></div>'
        let linkFn = $compile(templateString);
        let newScope = scope.$new(false);
        angular.element(element[0].querySelector(".gh-embed-container")).prepend(linkFn(newScope));
      }
    };
  }]);
