angular.module('documentManagerModule', [])

/*========================================================================================================*/
/*===========================================  DOCUMENT MANAGER  =============================================*/
/*========================================================================================================*/
/*this is the main method where we initiate data exchanging between server and client*/

.service('documentManager', [ function () {
  return gudhub.documentManager;
  // this.createDocument = function (documentObject) {
  //   return documentApi.createDocument(documentObject).then(function (response) {
  //     console.log('Create Document:', response);

  //     return response.data;
  //   });
  // };

  // this.getDocument = function (documentAddress) {
  //   return documentApi.getDocument(documentAddress).then(function (response) {
  //     console.log('Get Document:', response);

  //     return response.data;
  //   });
  // };

  // this.getDocuments = function (documentsAddresses) {
  //   return documentApi.getDocuments(documentsAddresses).then(function (response) {
  //     console.log('Get Documents:', response);

  //     return response.data;
  //   });
  // };

  // this.deleteDocument = function (documentAddress) {
  //   return documentApi.deleteDocument(documentAddress).then(function (response) {
  //     console.log('Delete Document:', response);

  //     return response.data;
  //   });
  // }

}])

/*========================================================================================================*/
/*======================================== DOCUMENT API  =================================================*/
/*========================================================================================================*/
/*.factory('documentApi', ['cnfg', 'authService', '$httpParamSerializerJQLike', '$http', function (cnfg, authService, $httpParamSerializerJQLike, $http) {
  return {

    createDocument : function (documentObject) {
      return authService.getToken().then(function (accessToken) {
        let requestConfig = {
          method: 'POST',
          url: cnfg.server_url + '/api/new' + '/document' + '/insert-one',
          headers: {'Content-Type': 'application/x-www-form-urlencoded'},
          data: $httpParamSerializerJQLike({
            token: accessToken,
            document: JSON.stringify(documentObject)
          })
        };

        return $http(requestConfig);
      });
    },

    getDocument: function (documentAddress) {
      return authService.getToken().then(function (accessToken) {
        let requestConfig = {
          method: 'POST',
          url: cnfg.server_url + '/api/new' + '/document' + '/find-one',
          headers: {'Content-Type': 'application/x-www-form-urlencoded'},
          data: $httpParamSerializerJQLike({
            token: accessToken,
            document: JSON.stringify(documentAddress)
          })
        };

        return $http(requestConfig);
      });
    },

    getDocuments: function (documentsAddresses) {
      return authService.getToken().then(function (accessToken) {
        let requestConfig = {
          method: 'POST',
          url: cnfg.server_url + '/api/new' + '/document' + '/find',
          headers: {'Content-Type': 'application/x-www-form-urlencoded'},
          data: $httpParamSerializerJQLike({
            token: accessToken,
            documents: JSON.stringify(documentsAddresses)
          })
        };

        return $http(requestConfig);
      });
    },

    deleteDocument: function (documentAddress) {
      return authService.getToken().then(function (accessToken) {
        let requestConfig = {
          method: 'POST',
          url: cnfg.server_url + '/api/new' + '/document' + '/remove-one',
          headers: {'Content-Type': 'application/x-www-form-urlencoded'},
          data: $httpParamSerializerJQLike({
            token: accessToken,
            document: JSON.stringify(documentAddress)
          })
        };

        return $http(requestConfig);
      });
    }

  }
}]);
*/