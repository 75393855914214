angular.module('imageRotaionModule', [])
    .factory('imageRotaionFactory', function (){
        return {
        // function get original loaded file and return this file rotation (rotation number)
        getOrientation : function(file, callback) {
            return new Promise(function(resolve, reject) {
              var reader = new FileReader();
              reader.onload = function(e) {
          
                  var view = new DataView(e.target.result);
                  if (view.getUint16(0, false) != 0xFFD8)
                  {
                      resolve(-2);
                  }
                  var length = view.byteLength, offset = 2;
                  while (offset < length) 
                  {
                      if (view.getUint16(offset+2, false) <= 8) resolve(-1);
                      var marker = view.getUint16(offset, false);
                      offset += 2;
                      if (marker == 0xFFE1) 
                      {
                          if (view.getUint32(offset += 2, false) != 0x45786966) 
                          {
                              resolve(-1);
                          }
          
                          var little = view.getUint16(offset += 6, false) == 0x4949;
                          offset += view.getUint32(offset + 4, little);
                          var tags = view.getUint16(offset, little);
                          offset += 2;
                          for (var i = 0; i < tags; i++)
                          {
                              if (view.getUint16(offset + (i * 12), little) == 0x0112)
                              { 
                                  resolve(view.getUint16(offset + (i * 12) + 8, little));
                              }
                          }
                      }
                      else if ((marker & 0xFF00) != 0xFF00)
                      {
                          break;
                      }
                      else
                      { 
                          offset += view.getUint16(offset, false);
                      }
                  }
                resolve(-1);
            };
            reader.readAsArrayBuffer(file);
            })
            
        },
    
        // function get onload file, orientation and original file extension and return rotated file
        resetOrientation : function(srcBase64, srcOrientation, extension, compress, quality = 1) {
            return new Promise(function(resolve, reject){
              var img = new Image();
              
              img.onload = function() {
                var width = img.width,
                    height = img.height,
                    canvas = document.createElement('canvas'),
                    ctx = canvas.getContext("2d");
                
                // set proper canvas dimensions before transform & export
                if (4 < srcOrientation && srcOrientation < 9 && srcOrientation != 8 && srcOrientation != 6) {
                  canvas.width = height;
                  canvas.height = width;
                } else {
                  canvas.width = width;
                  canvas.height = height;
                }
                // transform context before drawing image
                switch (srcOrientation) {
                  case 2: ctx.transform(-1, 0, 0, 1, width, 0); break;
                  // case 3: ctx.transform(-1, 0, 0, -1, width, height ); break;
                  case 4: ctx.transform(1, 0, 0, -1, 0, height ); break;
                  case 5: ctx.transform(0, 1, 1, 0, 0, 0); break;
                  // case 6: ctx.transform(0, 1, -1, 0, height , 0); break;
                  case 7: ctx.transform(0, -1, -1, 0, height , width); break;
                  // case 8: ctx.transform(0, -1, 1, 0, 0, width); break;
                  default: break;
                }
            
                // draw image
                ctx.drawImage(img, 0, 0);
            
                // export base64
                const image = compress ? canvas.toDataURL('image/jpeg', Number(quality)) : canvas.toDataURL(extension) 

                resolve(image);
            };
          
            img.src = srcBase64;
            })
            
        }
}})