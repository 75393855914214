export default {
  private: true,
  template_name: 'Float',
  allowed_types: [
    'gh_float',
    'gh_element',
    'gh_tabs',
    'gh_dropdown'
  ],
  model: {
    template_id: 'gh_float',
    name: '',
    icon_id: '',
    class: 'gh_float gh_col_width_12 gh_col_height_0 gh_height_percent gh_width_percent vertical_scroll',
    priority: 0,
    container_id: 0,
    content: []
  },
  container_settings: [{
    group: 'Title',
    name: 'Title without Underline',
    active_if: 'gh_title_without_underline',
    icon: 'gh_title',
    function: 'replaceClass',
    arg: '{"addClass" : "gh_title_without_underline", "deleteClass" : ["gh_icon", "gh_title_with_icon","gh_title","gh_title_left_icon_right","gh_icon_left_center","gh_icon_right_bottom_corner"]}'
  },{
    group: 'Title',
    name: 'Show Icon',
    active_if: 'gh_icon',
    icon: 'gh_icon',
    function: 'replaceClass',
    arg: '{"addClass" : "gh_icon", "deleteClass" : ["gh_title_without_underline","gh_title","gh_title_left_icon_right","gh_icon_left_center", "gh_title_with_icon","gh_icon_right_bottom_corner"]}'
  },{
    group: 'Title',
    name: 'Icon Right Bottom Corner',
    active_if: 'gh_icon_right_bottom_corner',
    icon: 'gh_icon_right_bottom_corner',
    function: 'replaceClass',
    arg: '{"addClass" : "gh_icon_right_bottom_corner", "deleteClass" : ["gh_title_without_underline", "gh_icon", "gh_title", "gh_title_with_icon","gh_title_left_icon_right","gh_icon_left_center"]}'
  },
  {
    group: 'Title',
    name: 'Icon Left',
    active_if: 'gh_icon_left_center',
    icon: 'gh_icon_left_center',
    function: 'replaceClass',
    arg: '{"addClass" : "gh_icon_left_center", "deleteClass" : ["gh_title_without_underline", "gh_icon", "gh_title","gh_title_with_icon","gh_title_left_icon_right","gh_icon_right_bottom_corner"]}'
  },
  {
    group: 'Title',
    name: 'Icon Right',
    active_if: 'gh_title_left_icon_right',
    icon: 'gh_title_left_icon_right',
    function: 'replaceClass',
    arg: '{"addClass" : ["gh_title_left_icon_right"], "deleteClass" : ["gh_icon", "gh_title_with_icon", "gh_title","gh_icon_left_center","gh_icon_right_bottom_corner", "gh_title_without_underline"]}'
  },
  {
    group: 'Title',
    name: 'Icon & Title',
    active_if: 'gh_title_with_icon',
    icon: 'gh_title_with_icon',
    function: 'replaceClass',
    arg: '{"addClass" : "gh_title_with_icon", "deleteClass" : ["gh_title_without_underline", "gh_icon", "gh_title","gh_title_left_icon_right","gh_icon_left_center","gh_icon_right_bottom_corner"]}'
  },{
    group: 'Title',
    name: 'Underline Title',
    active_if: 'gh_title',
    icon: 'gh_title_underline',
    function: 'replaceClass',
    arg: '{"addClass" : "gh_title", "deleteClass" : ["gh_title_without_underline", "gh_icon", "gh_title_with_icon", "gh_title_left_icon_right", "gh_icon_left_center", "gh_icon_right_bottom_corner"]}'
  },{
    group: 'Title',
    name: 'Remove All',
    icon: 'rubbish',
    function: 'replaceClass',
    arg: '{"deleteClass" : ["gh_title_without_underline", "gh_icon", "gh_title_with_icon", "gh_title", "gh_title_left_icon_right", "gh_icon_right_bottom_corner", "gh_icon_left_center"]}'
  },{
    group: 'Color',
    name: 'White',
    active_if: 'gh_container_color_white',
    icon: 'none f9f9fe 20px app f9f9fe f9f9fe',
    function: 'replaceClass',
    arg: '{"addClass" : "gh_container_color_white", "deleteClass" : ["gh_container_color_strawberry", "gh_container_color_pink", "gh_container_color_black", "gh_container_color_violet", "gh_container_color_red", "gh_container_color_yelow", "gh_container_color_green", "gh_container_color_blue", "gh_container_color_indigo", "gh_container_color_sky"]}'
  },{
    group: 'Color',
    name: 'Strawberry',
    active_if: 'gh_container_color_strawberry',
    icon: 'none 0093E9 20px app EA4492 EA4492',
    function: 'replaceClass',
    arg: '{"addClass" : "gh_container_color_strawberry", "deleteClass" : ["gh_container_color_white", "gh_container_color_pink", "gh_container_color_black", "gh_container_color_violet", "gh_container_color_red", "gh_container_color_yelow", "gh_container_color_green", "gh_container_color_blue", "gh_container_color_indigo", "gh_container_color_sky"]}'
  },{
    group: 'Color',
    name: 'Pink',
    active_if: 'gh_container_color_pink',
    icon: 'none FF9A8B 20px app FF9CDA FF9CDA',
    function: 'replaceClass',
    arg: '{"addClass" : "gh_container_color_pink", "deleteClass" : ["gh_container_color_white", "gh_container_color_strawberry", "gh_container_color_black", "gh_container_color_violet", "gh_container_color_red", "gh_container_color_yelow", "gh_container_color_green", "gh_container_color_blue", "gh_container_color_indigo", "gh_container_color_sky"]}'
  },{
    group: 'Color',
    name: 'Black',
    active_if: 'gh_container_color_black',
    icon: 'none 000000 20px app 000000 000000',
    function: 'replaceClass',
    arg: '{"addClass" : "gh_container_color_black", "deleteClass" : ["gh_container_color_white", "gh_container_color_strawberry", "gh_container_color_pink", "gh_container_color_violet", "gh_container_color_red", "gh_container_color_yelow", "gh_container_color_green", "gh_container_color_blue", "gh_container_color_indigo", "gh_container_color_sky"]}'
  },{
    group: 'Color',
    name: 'Violet',
    active_if: 'gh_container_color_violet',
    icon: 'none 000000 20px app A358DF A358DF',
    function: 'replaceClass',
    arg: '{"addClass" : "gh_container_color_violet", "deleteClass" : ["gh_container_color_white", "gh_container_color_strawberry", "gh_container_color_pink", "gh_container_color_black", "gh_container_color_red", "gh_container_color_yelow", "gh_container_color_green", "gh_container_color_blue", "gh_container_color_indigo", "gh_container_color_sky"]}'
  },{
    group: 'Color',
    name: 'Red',
    active_if: 'gh_container_color_red',
    icon: 'none 000000 20px app FB275D FB275D',
    function: 'replaceClass',
    arg: '{"addClass" : "gh_container_color_red", "deleteClass" : ["gh_container_color_white", "gh_container_color_strawberry", "gh_container_color_pink", "gh_container_color_black", "gh_container_color_violet", "gh_container_color_yelow", "gh_container_color_green", "gh_container_color_blue", "gh_container_color_indigo", "gh_container_color_sky"]}'
  },{
    group: 'Color',
    name: 'Yelow',
    active_if: 'gh_container_color_yelow',
    icon: 'none 000000 20px app FFCC00 FFCC00',
    function: 'replaceClass',
    arg: '{"addClass" : "gh_container_color_yelow", "deleteClass" : ["gh_container_color_white", "gh_container_color_strawberry", "gh_container_color_pink", "gh_container_color_black", "gh_container_color_violet", "gh_container_color_red", "gh_container_color_green", "gh_container_color_blue", "gh_container_color_indigo", "gh_container_color_sky"]}'
  },{
    group: 'Color',
    name: 'Green',
    active_if: 'gh_container_color_green',
    icon: 'none 000000 20px app 00CC6F 00CC6F',
    function: 'replaceClass',
    arg: '{"addClass" : "gh_container_color_green", "deleteClass" : ["gh_container_color_white", "gh_container_color_strawberry", "gh_container_color_pink", "gh_container_color_black", "gh_container_color_violet", "gh_container_color_red", "gh_container_color_yelow", "gh_container_color_blue", "gh_container_color_indigo", "gh_container_color_sky"]}'
  },{
    group: 'Color',
    name: 'Blue',
    active_if: 'gh_container_color_blue',
    icon: 'none 000000 20px app 009AFF 009AFF',
    function: 'replaceClass',
    arg: '{"addClass" : "gh_container_color_blue", "deleteClass" : ["gh_container_color_white","gh_container_color_strawberry", "gh_container_color_pink", "gh_container_color_black", "gh_container_color_violet", "gh_container_color_red", "gh_container_color_yelow", "gh_container_color_green", "gh_container_color_indigo", "gh_container_color_sky"]}'
  },{
    group: 'Color',
    name: 'indigo',
    active_if: 'gh_container_color_indigo',
    icon: 'none 000000 20px app 595ad4 595ad4',
    function: 'replaceClass',
    arg: '{"addClass" : "gh_container_color_indigo", "deleteClass" : ["gh_container_color_white","gh_container_color_strawberry", "gh_container_color_pink", "gh_container_color_black", "gh_container_color_violet", "gh_container_color_red", "gh_container_color_yelow", "gh_container_color_green", "gh_container_color_blue", "gh_container_color_sky"]}'
  },{
    group: 'Color',
    name: 'Sky',
    active_if: 'gh_container_color_sky',
    icon: 'none 000000 20px app 00CFF4 00CFF4',
    function: 'replaceClass',
    arg: '{"addClass" : "gh_container_color_sky", "deleteClass" : ["gh_container_color_white","gh_container_color_strawberry", "gh_container_color_pink", "gh_container_color_black", "gh_container_color_violet", "gh_container_color_red", "gh_container_color_yelow", "gh_container_color_green", "gh_container_color_blue",  "gh_container_color_indigo"]}'
  },{
    group: 'Color',
    name: 'None',
    icon: 'rubbish',
    function: 'replaceClass',
    arg: '{"deleteClass" : ["gh_container_color_white", "gh_container_color_strawberry", "gh_container_color_pink", "gh_container_color_black", "gh_container_color_violet", "gh_container_color_red", "gh_container_color_yelow", "gh_container_color_green", "gh_container_color_blue", "gh_container_color_indigo", "gh_container_color_sky"]}'
  },{
    name: 'Shadow On',
    icon: 'shadow',
    hide_if: 'shadow',
    function: 'replaceClass',
    arg: '{"addClass" : "shadow"}'
  },{
    name: 'Shadow Off',
    icon: 'shadow',
    show_if: 'shadow',
    function: 'replaceClass',
    arg: '{"deleteClass" : ["shadow"]}'
  },{
    name: 'Add Container',
    icon: 'plus',
    function: 'addContainer',
    arg: '{"allowed_types": ["gh_float", "gh_element", "gh_tabs", "gh_dropdown"], "template_id": "gh_float", "name": "", "icon_id": "", "class": "gh_float gh_col_width_12 gh_col_height_0 gh_height_percent gh_width_percent vertical_scroll", "container_id": 0, "priority": 0, "content": []}'
  },{
    name: 'Delete Container',
    icon: 'rubbish',
    function: 'deleteContainer'
  },{
    group: 'Width',
    name: 'Width PX',
    icon: 'pixel',
    hide_if: 'gh_width_px',
    function: 'replaceClass',
    arg: '{"addClass" : "gh_width_px", "deleteClass" : ["gh_width_percent"]}'
  },{
    group: 'Width',
    name: 'Width %',
    icon: 'percent',
    hide_if: 'gh_width_percent',
    function: 'replaceClass',
    arg: '{"addClass" : "gh_width_percent", "deleteClass" : ["gh_width_px"]}'
  }, {
    group: 'Width',
    name: 'Width +',
    icon: 'plus',
    function: 'sizeChange',
    arg: 'width plus'
  }, {
    group: 'Width',
    name: 'Width -',
    icon: 'minus',
    function: 'sizeChange',
    arg: 'width minus'
  }, {
    group: 'Width',
    name: 'Width Clear',
    icon: 'rubbish',
    function: 'sizeChange',
    arg: 'width clear'
  }, {
    group: 'Height',
    name: 'Height PX',
    icon: 'pixel',
    hide_if: 'gh_height_px',
    function: 'replaceClass',
    arg: '{"addClass" : "gh_height_px", "deleteClass" : ["gh_height_percent"]}'
  }, {
    group: 'Height',
    name: 'Height %',
    icon: 'percent',
    hide_if: 'gh_height_percent',
    function: 'replaceClass',
    arg: '{"addClass" : "gh_height_percent", "deleteClass" : ["gh_height_px"]}'
  }, {
    group: 'Height',
    name: 'Height +',
    icon: 'plus',
    function: 'sizeChange',
    arg: 'height plus'
  }, {
    group: 'Height',
    name: 'Height -',
    icon: 'minus',
    function: 'sizeChange',
    arg: 'height minus'
  }, {
    group: 'Height',
    name: 'Height Clear',
    icon: 'rubbish',
    function: 'sizeChange',
    arg: 'height clear'
  },{
    name: 'Margin Top',
    group: 'Margin',
    hide_if: 'margin_top',
    icon: 'margin_top',
    function: 'replaceClass',
    arg: '{"addClass" : "margin_top"}'
  },{
    name: 'Delete Margin Top',
    group: 'Margin',
    show_if: 'margin_top',
    icon: 'no_margin_top',
    function: 'replaceClass',
    arg: '{"deleteClass" : ["margin_top"]}'
  },{
    name: 'Margin Bottom',
    group: 'Margin',
    icon: 'margin_bottom',
    hide_if: 'margin_bottom',
    function: 'replaceClass',
    arg: '{"addClass" : "margin_bottom"}'
  },{
    name: 'Delete Margin Bottom',
    group: 'Margin',
    show_if: 'margin_bottom',
    icon: 'no_margin_bottom',
    function: 'replaceClass',
    arg: '{"deleteClass" : ["margin_bottom"]}'
  },{
    name: 'Margin Left',
    group: 'Margin',
    hide_if:'padding_left',
    icon: 'margin_left',
    function: 'replaceClass',
    arg: '{"addClass" : "padding_left"}'
  },{
    name: 'Delete Margin Left',
    group: 'Margin',
    icon: 'no_margin_left',
    show_if: 'padding_left',
    function: 'replaceClass',
    arg: '{"deleteClass" : ["padding_left"]}'
  },{
    name: 'Margin Right',
    group: 'Margin',
    hide_if: 'padding_right',
    icon: 'margin_right',
    function: 'replaceClass',
    arg: '{"addClass" : "padding_right"}'
  },
  {
    name: 'Delete Margin Right',
    group: 'Margin',
    icon: 'no_margin_right',
    show_if: 'padding_right',
    function: 'replaceClass',
    arg: '{"deleteClass" : ["padding_right"]}'
  },{
    name: 'Align Left',
    group: 'Align',
    icon: 'align_left',
    active_if: 'align_left',
    function: 'replaceClass',
    arg: '{"addClass" : "align_left", "deleteClass" : ["align_center", "align_right"]}'
  },{
    name: 'Align Center',
    group: 'Align',
    icon: 'align_center',
    active_if: 'align_center',
    function: 'replaceClass',
    arg: '{"addClass" : "align_center", "deleteClass" : ["align_left", "align_right"]}'
  },{
    name: 'Align Right',
    group: 'Align',
    icon: 'align_right',
    active_if: 'align_right',
    function: 'replaceClass',
    arg: '{"addClass" : "align_right", "deleteClass" : ["align_left", "align_center"]}'
  },{
    name: 'Horizontal scroll',
    icon: 'range',
    hide_if: 'horizont_scroll',
    function: 'replaceClass',
    arg: '{"addClass" : "horizont_scroll"}'
  },{
    name: 'Vertical scroll',
    icon: 'zip',
    show_if: 'horizont_scroll',
    function: 'replaceClass',
    arg: '{ "deleteClass" : ["horizont_scroll"]}'
  }],
  html_editor:
    `<div class="gh_float_block gh_float continer_decorator inherit_color">
      
      <div class="edit_template_container_form">
        <div class="edit_template_container_form_icon_title">
          <span ng-click="setIcon(item)" class="icon_block" gh-icon="{{item.icon_id ? item.icon_id : 'box'}} 86BEF6 100% normal"></span>
          <input class="title_block" ng-model="item.name" placeholder="Title"/>
        </div>
        <pencil-container-settings parent="$parent.lists" lists="item.content" item="item"></pencil-container-settings>
      </div>
      
      <div class="gh_float_block_list" ng-init="lists = item.content"
      dnd-list="lists" dnd-allowed-types="item.allowed_types" dnd-drop="dropElement(index, item, external, type, lists)" dnd-inserted="insertedElement()">
        <div ng-if="item.template_id != 'gh_element'" class="gh_float_block_list_item" ng-class="item.class" ng-repeat-start="item in lists"
        dnd-draggable="item"
        dnd-effect-allowed="move"
        dnd-moved="$parent.lists.splice($index, 1)"
        dnd-type="item.template_id"
        ng-include="item.template_id + '_editor'"></div>
        
        <gh-element ng-if="item.template_id == 'gh_element'" ng-class="item.class" ng-repeat-end container-id="{{item.container_id}}" decorator="getElementModelT(item.element_id)" field-id="{{item.element_id}}" elem-src="{{srcForElement}}"
        dnd-draggable="item"
        dnd-effect-allowed="move"
        dnd-moved="$parent.lists.splice($index, 1)"
        dnd-type="item.template_id"></gh-element>
      </div>
      
      <div style="clear:both"></div>
    </div>`,
  html_form: function (value, func) {

    //set default icon
    if (value.class.indexOf('gh_icon') > 0) {
      if(value.icon_id.length == 0) {
        value.icon_id = 'box';
      }
    }
    return `
      <div class="${ value.class }">
      <div class="continer_decorator gh_float">
        ${ value.icon_id || value.name ?
          `<div class="template_icon_title_block_form">
            ${ value.icon_id ? `<span class="template_icon_form" gh-icon="${ value.icon_id } 86BEF6 100% normal"></span>` : `` }
            ${ value.name ? `<span class="template_title_form">${ value.name }</span>` : `` }
            </div>` : ``
        }
        <div class="float_block_template_repeat_form gh_block_title_name">${ func(value.content) }</div>
        </div>
      </div>`;
  },
  icon: ''
};
