import './groupUsers.scss'
angular.module('groupUserModule', [])

  .directive('groupUsers', [function () {
    return {
      restrict: 'E',
      scope: {
        group: '=',
        mode: '@'
      },

      controller: ['$scope', async function ($scope) {
        let promises = [];
        $scope.groupUsers = await gudhub.groupSharing.getUsersByGroup($scope.group.group_id);
          
          $scope.groupUsers.forEach((userGroup) => {
            if($scope.mode == 'admins' && (userGroup.group_permission == '2' || userGroup.group_permission == '3')) {
              let user = gudhub.getUserById(userGroup.user_id);
              promises.push(user)
            }
          })
          Promise.all(promises).then((users) => {
            $scope.users = users;
            $scope.groupUsers.forEach(groupUser => {
              $scope.users.forEach(user => {
                if(user.user_id == groupUser.user_id) {
                  user.group_permission = groupUser.group_permission;
                }
              })
            })
            
            $scope.$digest();
          });

          async function groupMembersUpdateCb (e, member) {
            if(member.group_permission == '2') {
              let user = await gudhub.getUserById(member.user_id);
              user.group_permission = member.group_permission;
              $scope.users.push(user);
              $scope.$apply();
            } else {
              let findedUser = $scope.users.find(user => {
                return user.user_id == member.user_id;
              })
              $scope.users.splice($scope.users.indexOf(findedUser), 1);
              $scope.$apply();
            }
          }

          function groupMembersDeleteCb(e, member) {
            let findedUser = $scope.users.find(user => {
              return user.user_id == member.user_id;
            });

            $scope.users.splice($scope.users.indexOf(findedUser), 1);
            $scope.$digest();
          }

          gudhub.on('group_members_update', {app_id: 'group_sharing'}, groupMembersUpdateCb);

          gudhub.on('group_members_delete', {app_id: 'group_sharing'}, groupMembersDeleteCb);

          $scope.$on('$destroy', () => {
            gudhub.destroy('group_members_update', {app_id: 'group_sharing'}, groupMembersUpdateCb);
            gudhub.destroy('group_members_delete', {app_id: 'group_sharing'}, groupMembersDeleteCb);
          });
        
      }],

      template: /*html*/
      `
      <div class="group_users">
        <div class="group_users__item" ng-repeat="user in users">
          <div class="img_wrapper">
            <img src="{{user.avatar_128}}" alt="User {{user.fullname}}">
            <span class="permission" ng-style="{\'background\': user.group_permission == 2 ? '#0FB5FF' : '#E70061'}">{{user.group_permission == 2 ? 'Admin' : 'Owner'}}</span>
          </div>
          <span class="group_users__name">{{user.fullname}}</span>
        </div>
      </div>
      `
      
    }
  }]);
