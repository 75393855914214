import { Calculation } from './calculation.js';


angular.module('ghCalculatorModule', [])

.directive('ghCalculatorOutput', [ function() {

  return {

  controller: ['$scope', '$timeout', function($scope, $timeout) {

    $scope.calculatorOutput = [];

    $scope.$watch('modifiedExpression', () => {
      $scope.prepareOutput();
    });

    $scope.prepareOutput = function() {
      $scope.calculatorOutput = [];
      if($scope.modifiedExpression) {
        let expressionArray = $scope.modifiedExpression.match(/[^+\-\/*]+|[+\-\/*]/g);
        let functionCounter = 0;
        expressionArray.forEach(item => {
          if(item.match(/[A-Z_]+/)) {
            $scope.calculatorOutput.push({
              type: 'function',
              function: item.match(/[A-Z_]+/)[0],
              value: item,
              args: $scope.arguments[functionCounter],
              index: functionCounter
            });
            functionCounter++;
          } else {
            $scope.calculatorOutput.push({
              type: 'simple',
              value: item
            });
          }
        });
      }
    }

    $scope.editFunction = function(fnObj) {
      switch(fnObj.function) {
        case 'FIELD':
          $scope.showFieldDialog(fnObj);
          break;
        case 'SUM':
          $scope.showSumDialog(fnObj);
          break;
        case 'ITEMSCOUNT':
          $scope.insertItemsCount(fnObj);
          break;
        case 'IF':
          $scope.showConditionDialog(fnObj);
          break;
      }
    }

    $scope.findAndReplaceFunction = function(expression, fnObj, editedFunction) {
      let expressionArray = expression.match(/[^+\-\/*]+|[+\-\/*]/g);
      let functionCounter = 0;
      let editedExpression = '';
      expressionArray.forEach(item => {
        if(item.match(/[A-Z_]+/)) {
          if(fnObj.index === functionCounter) {
            editedExpression += editedFunction;
          } else {
            editedExpression += item.trim();
          }
          functionCounter++;
        } else {
          editedExpression += item.trim();
        }
      });
      return editedExpression;
    }
  }],

  template: `<div class="calculator-output">
              <span ng-repeat="item in calculatorOutput">
                <span ng-if="item.type === 'simple'">{{item.value}}</span>
                <span ng-click="editFunction(item)" ng-if="item.type === 'function'" class="function">{{item.value}}</span>
              </span>
            </div>`

}
  
}])

.directive('ghCalculator', ['calculatorService', '$mdDialog', function(calculatorService, $mdDialog) {
  var directive = {};

  directive.scope = {
    expression: '=',
    arguments: '=',
    ghAppId: '@'
  };

  directive.controller = ['$scope', function($scope) {
    
    Object.defineProperty($scope, 'expressionProxy', {
      get: function() {
        return calculatorService.insertArgs(this.expression, this.arguments);
      },
      set: function(newValue) {
        this.arguments = calculatorService.getFnArgs(newValue);
        this.expression = calculatorService.removeArgs(newValue);
        calculatorService.convertToUI(calculatorService.insertArgs(this.expression, this.arguments)).then(function(res) {
          $scope.modifiedExpression = res;
        });
      }
    });

    calculatorService.convertToUI(calculatorService.insertArgs($scope.expression, $scope.arguments)).then(function(res) {
      //let splitted = res.split(/[+*\/-]/g);
      $scope.modifiedExpression = res;
    });

    $scope.deleteLastSymbol = function() {
      if (/[A-Z_]+\([^+\-\/\*]*\)$/.test(calculatorService.insertArgs($scope.expression, $scope.arguments))) {
        $scope.expressionProxy = $scope.expressionProxy.replace(/[A-Z_]+\([^+\-\/\*]*\)$/, '');
      } else {
        $scope.expressionProxy = $scope.expressionProxy.slice(0, $scope.expressionProxy.length - 1);
      }
    };

    $scope.clear = function() {
      $scope.expressionProxy = '';
    };

    $scope.showFieldDialog = function(fnObj) {
      $mdDialog.show({
        controller: ['$scope', '$mdDialog', 'app', 'excludeField', 'fnObj', function($scope, $mdDialog, app, excludeField, fnObj) {
          $scope.app_id = app;
          $scope.excludeField = excludeField;
          if(fnObj) {
            $scope.fieldToInsert = fnObj.args.field_id;
            $scope.interpretationType = fnObj.args.interpretation_type;
          }
          $scope.addField = function() {
            if ($scope.fieldToInsert) {
              $mdDialog.hide('FIELD(' + angular.toJson({field_id: $scope.fieldToInsert, app_id: app, interpretation_type: $scope.interpretationType}) + ')');
            }
          };

          $scope.hide = function() {
              $mdDialog.hide();
          };
        }],
        templateUrl: 'gui/calculator_templates/field_calc.html',
        clickOutsideToClose:true,
        fullscreen: false,
        locals: {app: $scope.$parent.appId, excludeField: $scope.$parent.fieldModel.field_id, fnObj: fnObj}
      })
      .then(function(response) {
        if(response) {
          if(fnObj) {
            $scope.expressionProxy = $scope.findAndReplaceFunction($scope.expressionProxy, fnObj, response);
          } else {
            $scope.expressionProxy += response || '';
          }
        }
      });
    };
    $scope.showConditionDialog = function(fnObj){
      $mdDialog.show({
        controller: ['$scope', '$mdDialog', 'app', 'excludeField', 'fnObj', function($scope, $mdDialog, app, excludeField, fnObj) {
          $scope.condition = {};
          $scope.modeCondition = fnObj ? fnObj.args.mode : 1;
          $scope.app_id = app;
          
          $scope.condition.items = fnObj ? fnObj.args.items : [];
          $scope.decorator = {
            field_value: fnObj ? fnObj.args.mode : 1,
            data_model:
            {
              options: [
                {
                  name: 'Field If Value',
                  value: 0
                }, {
                  name: 'Value',
                  value: 1
                }
              ],
              interpretation: [{
                src: 'form',
                id: 'default',
                settings:{
                  editable: 1,
                  show_field_name: 0,
                  show_field: 1
                }
              }]

            },
            data_type: 'text_opt'
          }
          $scope.defaultValue = fnObj ? fnObj.args.defaultValue : 1;
          $scope.$watch('modeCondition', function(n){
            if(n == 1){
              $scope.condition.patterns=[{
                property: 'value',
                prop_name: 'value',
                type: 'number',
                data_model:function(option){
                  return {};
                },
                display: true,
              },{
                property: 'filters_list',
                prop_name: 'Conditions',
                type: 'filter_table',
                display: true,
                data_model:function(option, scope) {
                  scope.appId = app;
                  
                  option.filters_list ? scope.filters_list = option.filters_list : scope.filters_list = option.filters_list = [];
                  return {
                      mode: 'variable'
                  }
                },
              }]
            }
            if(n == 0){
              $scope.condition.patterns=[{
                property: 'value',
                prop_name: 'Field value',
                type: 'field',
                data_model:function(option){
                  return {
                      app_id: $scope.app_id
                  };
                },
                display: true,
              },{
                property: 'filters_list',
                prop_name: 'Conditions',
                type: 'filter_table',
                display: true,
                data_model:function(option, scope) {
                  scope.appId = app;
                  
                  option.filters_list ? scope.filters_list = option.filters_list : scope.filters_list = option.filters_list = [];
                  return {
                      mode: 'variable'
                  }
                },
              }]
            }
          })
        
      
          $scope.excludeField = excludeField;
          $scope.addIf = function() {
              let arg = {
                app_id: app,
                defaultValue: $scope.defaultValue,
                items: $scope.condition.items,
                mode: $scope.modeCondition
              }
              $mdDialog.hide('IF(' + angular.toJson(arg) + ')');
          };

          $scope.hide = function() {
            $mdDialog.hide();
          };
        }],
        templateUrl: 'gui/calculator_templates/condition_calc.html',
        clickOutsideToClose:true,
        fullscreen: false,
        locals: {app: $scope.$parent.appId, excludeField: $scope.$parent.fieldModel.field_id, fnObj: fnObj}
      })
      .then(function(response) {
        if(response) {
          if(fnObj) {
            $scope.expressionProxy = $scope.findAndReplaceFunction($scope.expressionProxy, fnObj, response);
          } else {
            $scope.expressionProxy += response || '';
          }
        }
      });
    };

    $scope.insertItemsCount = function(fnObj) {
      $mdDialog.show({
        controller: ['$scope', '$mdDialog', 'excludeField', 'fnObj', function($scope, $mdDialog, excludeField, fnObj) {
          $scope.excludeField = excludeField;
          $scope.sumFilters = fnObj ? fnObj.args.sum_filters : [];
          $scope.itselfFilter = fnObj ? fnObj.args.itself_filter : {active: false, field_id: []};

          $scope.filterDataModel = {
            recipient: {
              app_id: fnObj ? fnObj.args.app_id : null
            }
          };

          $scope.field = {data_model: {app_id: fnObj ? fnObj.args.app_id : null}};

          $scope.$watch('appToInsert', function(n, o) {
            if (n != o) {
              $scope.filterDataModel.recipient.app_id = n;
              $scope.field.data_model.app_id = n;
            }
          });

          if(fnObj) {
            $scope.appToInsert = fnObj.args.app_id;
          }

          $scope.addCount = function() {
            if ($scope.appToInsert) {
              var arg = {
                app_id: $scope.appToInsert,
                sum_filters: $scope.sumFilters,
                itself_filter: $scope.itselfFilter
              };
              $mdDialog.hide('ITEMSCOUNT(' + angular.toJson(arg) + ')');
            }
          };

          $scope.hide = function() {
            $mdDialog.hide();
          };
        }],
        templateUrl: 'gui/calculator_templates/count_calc.html',
        clickOutsideToClose:true,
        fullscreen: false,
        locals: {excludeField: $scope.$parent.fieldModel.field_id, fnObj: fnObj}
      })
        .then(function(response) {
          if(response) {
            if(fnObj) {
              $scope.expressionProxy = $scope.findAndReplaceFunction($scope.expressionProxy, fnObj, response);
            } else {
              $scope.expressionProxy += response || '';
            }
          }
        });
    };
      
    $scope.showSumDialog = function(fnObj) {
      $mdDialog.show({
        controller: ['$scope', '$mdDialog', 'excludeField', 'fnObj', function($scope, $mdDialog, excludeField, fnObj) {
          $scope.excludeField = excludeField;
          $scope.sumFilters = fnObj ? fnObj.args.sum_filters : [];
          $scope.itselfFilter = fnObj ? fnObj.args.itself_filter : {active: false, field_id: []};

          $scope.filterDataModel = {
            recipient: {
              app_id: fnObj ? fnObj.args.app_id : null
            }
          };

          $scope.field = {data_model: {app_id: fnObj ? fnObj.args.app_id : null}};

          $scope.$watch('appToInsert', function(n, o) {
            if (n != o) {
              $scope.filterDataModel.recipient.app_id = n;
              $scope.field.data_model.app_id = n;
            }
          });

          if(fnObj) {
            $scope.fieldToInsert = fnObj.args.field_id;
            $scope.appToInsert = fnObj.args.app_id;
          }

          $scope.addSum = function() {
            if ($scope.fieldToInsert) {
              var arg = {
                field_id: $scope.fieldToInsert,
                app_id: $scope.appToInsert,
                sum_filters: $scope.sumFilters,
                itself_filter: $scope.itselfFilter
              };
              $mdDialog.hide('SUM(' + angular.toJson(arg) + ')');
            }
          };

          $scope.hide = function() {
            $mdDialog.hide();
          };
        }],
        templateUrl: 'gui/calculator_templates/sum_calc.html',
        clickOutsideToClose:true,
        fullscreen: false,
        locals: {excludeField: $scope.$parent.fieldModel.field_id, fnObj: fnObj}
      })
      .then(function(response) {
        if(response) {
          if(fnObj) {
            $scope.expressionProxy = $scope.findAndReplaceFunction($scope.expressionProxy, fnObj, response);
          } else {
            $scope.expressionProxy += response || '';
          }
        }
      });
    };

  }];

  directive.templateUrl = 'gui/calculator_templates/calculator.html';


  directive.link = function(scope, element, attrs) {
    element.on('click', function(e) {
      e.preventDefault();
      if (e.target.getAttribute('value')) {
        var sym = e.target.getAttribute('value');
        scope.$apply(function() {
          scope.expressionProxy = angular.isDefined(scope.expressionProxy) ? scope.expressionProxy + sym : sym;
        });
      }
    });

  };
  return directive;
}])


.directive('ghStringjoiner', [ 'calculatorService', '$mdDialog', function(calculatorService, $mdDialog) {
  var directive = {};

  directive.scope = {
    expression: '=',
    arguments: '='
  };

  directive.controller = ['$scope', function($scope) {
    if (angular.isUndefined($scope.expression)) $scope.expression = '';

    Object.defineProperty($scope, 'expressionProxy', {
      get: function() {
        return calculatorService.insertArgs(this.expression, this.arguments);
      },
      set: function(newValue) {
        this.arguments = calculatorService.getFnArgs(newValue);
        this.expression = calculatorService.removeArgs(newValue);
        calculatorService.convertToUI(calculatorService.insertArgs(this.expression, this.arguments)).then(function(res) {
          $scope.modifiedExpression = res;
        });
      }
    });

    calculatorService.convertToUI(calculatorService.insertArgs($scope.expression, $scope.arguments)).then(function(res) {
      $scope.modifiedExpression = res;
    });

    $scope.showFieldStringDialog = function() {
      $mdDialog.show({
        controller: ['$scope', '$mdDialog', 'app', function($scope, $mdDialog, app) {

          $scope.settings = [{display: 'first_characters', count: 2, name: 'first characters'}, {display: 'each_word_first_character', name: 'first character of each word'}, {display: 'full_value', name: 'full value'}];
          $scope.fieldSettings = $scope.settings[0];
          $scope.app_id = app;

          $scope.addField = function() {
            if ($scope.fieldToInsert) {
              var arg = {
                field_settings: $scope.fieldSettings,
                field_id: $scope.fieldToInsert,
                app_id: $scope.app_id
              };
              $mdDialog.hide('FIELD_STRING(' + angular.toJson(arg) + ')');
            }
          };

          $scope.hide = function() {
            $mdDialog.hide();
          };
        }],
        templateUrl: 'gui/calculator_templates/field_string_joiner.html',
        clickOutsideToClose:true,
        fullscreen: false,
        locals: {app: $scope.$parent.appId}
      })
      .then(function(response) {
          $scope.expressionProxy += response || '';
      });
    };

    $scope.showSelectingIdDialog = function() {
      $mdDialog.show({
        controller: ['$scope', '$mdDialog', 'app', function($scope, $mdDialog, app) {
          $scope.settings = [{value: 'Index number of item', index: 0}, {value: 'Current year', index: 1}, {value: 'Current month', index: 2}, {value: 'Current day of month', index: 3}];

          $scope.addValue = function () {
            if ($scope.selectedType) {
              switch($scope.selectedType.value) {
                case 'Index number of item':
                  $mdDialog.hide('ITEM_INDEX(' + angular.toJson({type: $scope.selectedType.value, min_digits_count: $scope.min_digits_count || ''}) + ')');
                  break;
                default:
                  $mdDialog.hide('ITEM_INDEX(' + angular.toJson({type: $scope.selectedType.value}) + ')');
              }

            }
          };

          $scope.hide = function() {
            $mdDialog.hide();
          };
        }],
        templateUrl: 'gui/calculator_templates/id_string_joiner.html',
        clickOutsideToClose:true,
        fullscreen: false,
        locals: {app: $scope.$parent.appId}
      })
        .then(function(response) {
          $scope.expressionProxy += response || '';
        });
    };

    $scope.showWordDialog = function() {
      $mdDialog.show({
        controller: ['$scope', '$mdDialog', function($scope, $mdDialog) {

          $scope.addWord = function() {
            if ($scope.word !== '') {
              $mdDialog.hide('WORD(' + angular.toJson({word: $scope.word}) + ')');
            }
          };

          $scope.hide = function() {
            $mdDialog.hide();
          };
        }],
        templateUrl: 'gui/calculator_templates/word_string_joiner.html',
        clickOutsideToClose:true,
        fullscreen: false
      })
      .then(function(response) {
          $scope.expressionProxy += response || '';
      });
    };

    $scope.deleteLastSymbol = function() {
      if (/[A-Z_]+\([^+\-\/\*]*\)$/.test(calculatorService.insertArgs($scope.expression, $scope.arguments))) {
        $scope.expressionProxy = $scope.expressionProxy.replace(/[A-Z_]+\([^+\-\/\*]*\)$/, '');
      } else {
        $scope.expressionProxy = $scope.expressionProxy.slice(0, $scope.expressionProxy.length - 1);
      }
    };

    $scope.clear = function() {
      $scope.expressionProxy = '';
    };

    $scope.addPlusChar = function() {
      $scope.expressionProxy += '+';
    };
  }];

  directive.templateUrl = 'gui/calculator_templates/stringJoin.html';
  return directive;
}])

.service('calculatorService', ['$q', 'appDataProcesingService', function($q, appDataProcesingService) {
  var fnParseRegExp = /[A-Z_]+\(.*?\)/g, fnWithoutArgRegExp = /[A-Z_]+\(\)/g, self = this;


  this.insertArgs = function(expr, args) {
    angular.forEach(expr.match(fnWithoutArgRegExp), function(matchedFn, index) {
      expr = expr.replace(matchedFn, matchedFn.match(/[A-Z_]+/)[0] + '(' + angular.toJson(args[index]) + ')');
    });
    return expr;
  };

  this.removeArgs = function(expr) {
    angular.forEach(expr.match(fnParseRegExp), function(matchedFn, index) {
      expr = expr.replace(matchedFn, matchedFn.match(/[A-Z_]+/)[0] + '()');
    });
    return expr;
  };

  this.getFnArgs = function(expr) {
    var result = [];
    angular.forEach(expr.match(fnParseRegExp), function(matchedFn, index) {
      result.push(angular.fromJson(matchedFn.match(/\((.*)\)/)[1]));
    });
    return result;
  };


  this.convertToUI = function(expr) {
    if (angular.isUndefined(expr)) {
      return $q.when('');
    }

    var promises = [], promise = $q.defer();

    angular.forEach(expr.match(fnParseRegExp), function(matchedFn) {
      var deferred = $q.defer();
      promises.push(deferred.promise);
      self.processFn(matchedFn).then(function(res) {
        expr = expr.replace(matchedFn, res);
        deferred.resolve();
      });
    });

    $q.all(promises).then(function() {
      promise.resolve(expr);
    });

    return promise.promise;
  };

  this.processFn = function(str) {
    var fnName = str.match(/[A-Z_]+/)[0];
    var parsed =  angular.fromJson(str.match(/\((.*)\)/)[1]);

    switch (fnName) {
      case 'FIELD':
      case 'SUM':
      case 'FIELD_STRING':
        var promise = $q.defer();
        appDataProcesingService.getApp(parsed.app_id).then(function(res) {
          if(res) {
            if(!res.field_list.find(field => field.field_id == parsed.field_id)) {
              switch (fnName) {
                case 'FIELD':
                  promise.resolve('FIELD(ERROR (NO FIELD))');
                  break;
                case 'SUM':
                  promise.resolve('SUM(ERROR)');
                  break;
                case 'FIELD_STRING':
                  promise.resolve('FIELD_STRING(ERROR)');
                  break;
                case 'ITEMSCOUNT':
                  promise.resolve('ITEMSCOUNT(ERROR)');
              }
            } else {
            angular.forEach(res.field_list, function (field) {
              if (field.field_id == parsed.field_id) {
                switch (fnName) {
                  case 'FIELD':
                    promise.resolve('FIELD(' + field.name_space + ')');
                    break;
                  case 'SUM':
                    promise.resolve('SUM(' + field.name_space + ', ' + res.app_name + ')');
                    break;
                  case 'FIELD_STRING':
                    promise.resolve('FIELD_STRING(' + field.name_space + ', ' + parsed.field_settings.name + ')');
                    break;
                  case 'ITEMSCOUNT':
                    promise.resolve('ITEMSCOUNT(' + res.app_name + ')');
                }
              }
            });
          }
        }
        });
        return promise.promise;
      case 'IF': 
        return $q.when('IF(filter)');
      case 'ITEMSCOUNT':
        return appDataProcesingService.getApp(parsed.app_id).then(function (res) {
          if(res) {
            return 'ITEMSCOUNT(' + res.app_name + ')';
          }
        });
      case 'WORD':
        return $q.when(parsed.word);
      case 'ITEM_INDEX':
        return $q.when('ITEM_INDEX(' + parsed.type + ')');
    }
  };

}])

.directive('ghCalculation', ['PipeService', '$timeout', function(PipeService, $timeout) {
  var directive = {};

  directive.scope = {
    fieldModel: '=?',
    isGenerateOnce: '=?',
    isPersistentValue: '=?',
    expression: '=',
    arguments: '=',
    precision: '=?',
    updateButton: '=?',
    callbacks: '=?',
    ghAppId: '@',
    itemId: '@?'
  };
  
  directive.require = '';

  directive.controller = ['$scope', '$element', '$attrs', function ($scope, $element, $attrs) {
    if (angular.isUndefined($scope.$parent.itemId)) {
      return;
    }
    $scope.editCalcValue = $scope.isGenerateOnce ? $scope.isPersistentValue && $scope.fieldModel.settings.editable : false;
    let lastItemId, lastCalculatedValue, firstLoaded = false;
    /*
      into 'rejectPrevCalculating' variable will be assigned function to reject promise that
      started with one itemId and wasnt fullfield before start of next promise with next itemId
    */
    let rejectPrevCalculating;
    const manualRejectMsg = 'Manual reject';
     /* 
        - If you want the calculation to be generated without saving value then turn OFF " Generate Once" and "Persistent Value" switches
        - If you want the calculation to be saved value then turn OFF " Generate Once" or "Persistent Value". It's usefull when you want to search by calculator field
        - If you want prevent changing value the turn "is Generate Once" switch on.
        - you'dlike to edit calculated value then go to Style Setting and turn "Editable" On. Also make shure that Persistent Value in the Field Setting is turned ON too.
      */

      /* UPDATE VALUE */
      // Final value to display is calculated value
      // Main function, recieves calcuation result
      const updateValue = () => {
        const itemId = $scope.$parent.itemId;

        if (rejectPrevCalculating) {
          rejectPrevCalculating(manualRejectMsg);
        }
        
        const [promise, rejectPromise] = Calculation.getEvaluatedExpression($scope.expression, $scope.arguments, itemId, $scope.$parent.appId);

        rejectPrevCalculating = rejectPromise;
        promise
          .then(function (res) {
            try {
              // isPersistentValue means that value is needs to be saved.
              // isGenerateOnce means that its value is calculated once and will be unchanged
              if ($scope.isGenerateOnce && $scope.fieldModel.field_value) {
                $scope.calculatedValue = $scope.fieldModel.field_value;
              } else {
                $scope.calculatedValue = eval(res);
              }
              
              // Add precision if needed
              if (($scope.precision !== undefined) && $scope.calculatedValue && !isNaN(Number($scope.calculatedValue))) {
                $scope.calculatedValue = Number($scope.calculatedValue).toFixed($scope.precision);
              }

              // if isPersistentValue === true value needs to be saved
              if ($scope.isPersistentValue) {
                // assign calculated value to field_value IF value is undefined OR value is defined and isGeneratedOnce is false
                if (!$scope.fieldModel.field_value || ($scope.fieldModel.field_value && !$scope.isGenerateOnce)){
                  $scope.fieldModel.field_value = $scope.calculatedValue;
                }
              }

              // If value not persistent, it's not first calculation and last calculated value not equals new calculation - send event
              if((!lastCalculatedValue && isNaN($scope.calculatedValue)) || (lastCalculatedValue != $scope.calculatedValue)) {
                if (!$scope.isPersistentValue) {
                  gudhub.emit('gh_value_update', {
                    app_id: $scope.$parent.appId,
                    item_id: $scope.$parent.itemId,
                    field_id: $scope.$parent.fieldId
                  }, $scope.calculatedValue);
                }
              }

              lastCalculatedValue = $scope.calculatedValue;

            } catch (e) {
              $scope.calculatedValue = 'Expression with errors';
            } finally {
              $scope.$apply();
            }
          })
          .catch((error) => {
            if (error !== manualRejectMsg) {
              console.log(error)
            }
          });
        }

        /* SUBSCRIBE FOR SUMM CHANGES */
        // Subscribing fot items update if items from another application
        for(const argument of $scope.arguments) {
          if(argument.app_id && (argument.app_id != $scope.$parent.appId)) {
            gudhub.on('gh_items_update', { app_id: argument.app_id }, updateValue);
          }
        }

        /* SUBSCRIBE */
        const subscribe = () => {

        // Destroying all previous listeners
        // lastItemId setting only after first listeners turning on
        if (lastItemId) {
          for(const argument of $scope.arguments) {
            gudhub.destroy('gh_value_update', {
              app_id: argument.app_id,
              item_id: $scope.$parent.itemId,
              field_id: argument.field_id
            }, updateValue, $scope);
          }
        }

        lastItemId = $scope.$parent.itemId;

        // If one time generation turned off - add listeners to value update for recalculation
        if(!$scope.isGenerateOnce) {
          for(const argument of $scope.arguments) {
            if(argument.mode) {
              for(const item of argument.items) {
                switch(argument.mode) {
                  case '0': 
                    gudhub.on('gh_value_update', {
                      app_id: argument.app_id,
                      item_id: $scope.$parent.itemId,
                      field_id: item.value
                    }, updateValue, $scope);
                    break;
                  case '1':
                    break;
                }
                for(const filter of item.filters_list) {
                  gudhub.on('gh_value_update', {
                    app_id: argument.app_id,
                    item_id: $scope.$parent.itemId,
                    field_id: filter.field_id
                  }, updateValue, $scope);
                }
              }
            } else {
              gudhub.on('gh_value_update', {
                app_id: argument.app_id,
                item_id: $scope.$parent.itemId,
                field_id: argument.field_id
              }, updateValue, $scope);
            }
          }
        }
        // If one time generation turned on - add listener to this value (to catch case when value was edited by user)
        else {
          gudhub.on('gh_value_update', {
            app_id: $scope.fieldModel.app_id,
            item_id: $scope.itemId,
            field_id: $scope.fieldModel.field_id
          }, updateValue, $scope);
        }

        if($scope.isPersistentValue && $scope.fieldModel.field_value != null) {
          $scope.calculatedValue = $scope.fieldModel.field_value;
        } else {
          gudhub.getItems($scope.$parent.appId).then(items => {
            items.forEach(item => {
              if (item.item_id == $scope.$parent.itemId) {
                lastItemId = item.item_id;
                updateValue();
              }
            });
          })
        }
      }

      // Init subscribe function after calculator start
      if($scope.isPersistentValue && $scope.fieldModel.field_value != null) {
        $scope.calculatedValue = $scope.fieldModel.field_value
        subscribe();
      } else {
        subscribe();
      }

      // Destroying all listeners on $scope destroing
      $scope.$on('$destroy', () => {
        for(const argument of $scope.arguments) {
          if(argument.app_id && (argument.app_id != $scope.$parent.appId)) {
            gudhub.destroy('gh_items_update', { app_id: argument.app_id }, updateValue);
          }
          gudhub.destroy('gh_value_update', {
            app_id: argument.app_id,
            item_id: $scope.$parent.itemId,
            field_id: argument.field_id
          }, updateValue, $scope);
        }
      });

      // Start updateValue function after model was changed
      gudhub.on('gh_model_update', {app_id: $scope.$parent.appId, field_id: $scope.$parent.fieldId}, async () => {
        const items = await gudhub.getItems($scope.$parent.appId);
        for(const item of items) {
          if(item.item_id == $scope.$parent.itemId) {
            updateValue();
          }
        }
      });

      let itemId = $scope.itemId;

      // Subscribe for attribute changes for displaying calculator in table
      $attrs.$observe('itemId', function(newItemId, o) {
        if (newItemId !== itemId) {
          itemId = newItemId;
          updateValue();
        }
      });
  }];
 
  directive.template = 
    `<div><span ng-if="!editCalcValue">{{calculatedValue == null || calculatedValue == ""  ? "" :fieldModel.data_model.prefix}}{{calculatedValue}}{{calculatedValue == null || calculatedValue == ""  ? "" :fieldModel.data_model.suffix}}</span></div>
     <gh-input ng-if="editCalcValue" 
               gh-editable="fieldModel.settings.editable" 
               ng-model="fieldModel.field_value" 
               gh-data-type="number" 
               gh-field="fieldModel"
               model-update="focus blur" 
               style="margin-top: -14px;">
     </gh-input>
     <span ng-show="(+updateButton)" ng-click="updateValue()" gh-icon="update 0893d2 25px app"></span>`;

  return directive;
}])
