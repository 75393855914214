// import '../../../gui/gh_radio_button.js'
import './membersTable.scss';
import '../groupUsers.directive.js';
angular.module('membersTableModule', [])

  .directive('membersTable', ['GhDialog', function (GhDialog) {
    return {
      restrict: 'E',
      scope: {
        group: '='
      },

      controller: ['$scope', async function ($scope) {

        // Options for user
        $scope.options = [{
          name: 'Blocked',
          value: 0,
          color: '#000000'
        }, {
          name: 'Member',
          value: 1,
          color: '#05B52C'
        }, {
          name: 'Admin',
          value: 2,
          color: '#0FB5FF'
        }]

        $scope.tempUsersFieldDataModel = {
          data_model: {
            photo_size: 40,
            display_type: 'photo_fullname'
          },
          data_type: 'user'
        };

        // Get users by group
        $scope.groupUsers = await gudhub.groupSharing.getUsersByGroup($scope.group.group_id);

        // Delete User Dialog
        $scope.showDeleteMemberPopup = function (member) {
          GhDialog.show({
            position: 'center',
            toolbar: false,
            template: {
              toolbar: '',
              resizable: false,
              content: `
              <div class="delete_group-popup">
                <div class="dialog-share-group">
                  <span gh-icon="rubbish FF0D0D 65px"></span>
                  <p class="dialog-share-group__title">Deleting Member!</p>
                </div>
                <div class="dialog-share-group__content">
                  <p class="info">Do you really want to delete this member?</p>
                  <div class="dialog-share-group__content__buttons">
                    <button class="dialog-share-group__content__buttons__add btn btn-blue-reverse" ng-click="deleteMember()">Delete</button>
                    <button class="dialog-share-group__content__buttons__cancel btn btn-blue" ng-click="cancel()">Cancel</button>
                  </div>
                </div>
              </div>`,
            },
            locals: {
              member
            },
              controller: ['$scope', 'member', function ($scope, member) {

                $scope.deleteMember = async function() {
                  try {
                    await gudhub.groupSharing.deleteUserFromGroup(member.user_id, member.group_id);
                    $scope.cancel();
                  } catch(error) {
                    console.log(error);
                  }
                }
              }]
              
          })
        };

        $scope.updateUserPermission = async (e, member) => {
          
          if(e.target.parentElement.classList.contains('gh-radio-button_dropdown-item')) {
            try {
              await gudhub.groupSharing.updateUserInGroup(member.user_id, member.group_id, member.group_permission);
            } catch(err) {
              console.log(err)
            }
          }
        };

        function groupMemberAddCb(e, member) {
          $scope.groupUsers.push(member);
          $scope.$apply();
        };

        function groupMemberDeleteCb(e, member) {
          let findedUser = $scope.groupUsers.find(user => {
            return user.user_id == member.user_id;
          })
          $scope.groupUsers.splice($scope.groupUsers.indexOf(findedUser), 1);
          $scope.$apply();
        };

        gudhub.on('group_members_add', {app_id: 'group_sharing'}, groupMemberAddCb);

        gudhub.on('group_members_delete', {app_id: 'group_sharing'}, groupMemberDeleteCb);

        $scope.$on('$destroy', () => {
          gudhub.destroy('group_members_add', {app_id: 'group_sharing'}, groupMemberAddCb);
          gudhub.destroy('group_members_delete', {app_id: 'group_sharing'}, groupMemberDeleteCb);
        });

        $scope.$watch('newUser', async function(newVal, oldVal) {
          if(newVal && oldVal != newVal) {
            try {
              
              let findedUser = $scope.groupUsers.find(user => {
                return user.user_id == newVal;
              })
              if(!findedUser) {
                await gudhub.groupSharing.addUserToGroup($scope.group.group_id, newVal, 1);
              }
            } catch (err) {
              console.log(err);
            }
            
          }
        })
       
        $scope.$apply();
      }],

      template: /*html*/
      
      `<div class="add_member_wrapper"> 
        <button class="btn btn-blue add_member" ng-show="!showInput && (group.group_permission == 2 || group.group_permission == 3)" ng-click="showInput=true;"><span gh-icon="bold_plus fff 20px"></span>Add</button>
      </div>
      <div class="user_selecting-wrapper" ng-show="showInput">
        <div class="search" gh-icon="search 0D99FF 22px"></div>
        <gh-user-selecting  class="flex-1" gh-model="newUser" gh-field="tempUsersFieldDataModel" gh-dropdown="" delete-selected editable="true"></gh-user-selecting>
        <div class="close" ng-click="showInput = false;" gh-icon="cross 0D99FF 22px"></div>
      </div>
      <div class="listing_table">
        <div class="listing_table_head_wrapper">
          <div class="listing_table_column bottom_line w-50">
            <div class="tab">Name</div>
          </div>
          <div class="listing_table_column w-35">
            <div class="tab">Permission</div>
          </div>
          <div class="listing_table_column w-15">
            
          </div>
        </div>
        <div class="listing_table_body_wrapper">
          <div class="listing_table_row" ng-repeat="member in groupUsers">
            <div class="listing_table_column w-50">
              <span class="number">{{$index + 1}}</span>
              <div gh-element decorator="tempUsersFieldDataModel" value="member.user_id" elem-src="table"></div>
            </div>
            <div class="listing_table_column w-35">
              <div ng-if="member.group_permission == 3" class="owner">Owner</div>
              <gh-radio-button class="gh-radio-button" ng-click="updateUserPermission($event, member)" gh-options="options" gh-model="member.group_permission" gh-mode="current_value" editable="group.group_permission == 2 || group.group_permission == 3  ? true : false"/> +
            </div>
            <div class="listing_table_column w-15">
              <span ng-if="(group.group_permission == 3 || group.group_permission == 2) && member.group_permission != 3" ng-click="showDeleteMemberPopup(member)" class="delete_icon" gh-icon="rubbish 0d99ff 20px"></span>
            </div>

          </div>
          <div ng-if="!groupUsers.length" class="listing_table_nodata">
            <p>No Members</p>
          </div> 
        </div>
      </div>`
      
    }
  }]);
